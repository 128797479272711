import React, { Component, useState } from 'react';
import {
    Row,
    Form,
    InputGroup,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Col,
    Card,
    CardBody,
    Collapse,
    Table,
    Button,
} from 'reactstrap';
import styles from './ReactTags.module.scss';
import _ from 'lodash';
import { WithContext as ReactTags } from 'react-tag-input';
const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const ReactTagsInput = (props) => {
    const [tags, setTags] = useState([]);
    const [rawInput, setRawInput] = useState('');
    const [state, setstate] = useState({
        error: false,
        errorMessage: '',
    });

    const handleDelete = (i) => {
        setstate({
            error: false,
            errorMessage: '',
        });
        const email = _.find(tags, (o, index) => {
            return index === i;
        });
        setTags(tags.filter((tag, index) => index !== i));
        props.deleteCustomEmail(email);
    };

    function isEmail(email) {
        return /.+@.+\.[A-Za-z]+$/.test(email);
    }

    const handleAddition = (tag) => {
        console.log('tag.text', tag.text);
        if (isEmail(tag.text)) {
            const exits = _.find(tags, (a) => {
                if (a.id === tag.id) {
                    return a;
                }
            });
            if (exits !== undefined) {
                props.handleRawInput('');
                setstate({
                    error: true,
                    errorMessage: 'Email already exits.',
                });
            } else {
                setstate({
                    error: false,
                    errorMessage: '',
                });
                setTags([...tags, tag]);
                props.handleAddCustomEmail(tag);
            }
        } else {
            props.handleRawInput('');
            setstate({
                error: true,
                errorMessage: 'Enter a valid email address',
            });
        }
    };

    const handleInputChange = (data) => {
        setRawInput(data);
        props.handleRawInput(data);
    };

    return (
        <>
            <div style={{ wordBreak: 'break-word' }} className={styles.ReactTags}>
                <ReactTags
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    placeholder="Add an email ID and press enter"
                    autofocus={false}
                    autocomplete={true}
                    readOnly={false}
                    allowUnique={false}
                    inline={true}
                    allowAdditionFromPaste={true}
                    editable={true}
                    tags={tags}
                    handleInputChange={handleInputChange}
                />
            </div>
            {state.error && (
                <div>
                    <span style={{ color: 'red' }}>{state.errorMessage}</span>
                </div>
            )}
        </>
    );
};

export default ReactTagsInput;
