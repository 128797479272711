import { servicePost } from './../helpers/api';
import moment from 'moment-timezone';
import { convertKeysToRoles } from '../helpers/common';

export function configureFakeBackend() {
    let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User', role: 'Admin' }];
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call

            // authenticate
            if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
                // get parameters from post request
                let params = JSON.parse(opts.body);

                // Pipe-drive configuration

                let pipedriveCode = localStorage.getItem('pipedriveCode');
                if (pipedriveCode) {
                    params['pipedriveCode'] = pipedriveCode;
                }

                servicePost('login', params)
                    .then((res) => {
                        let role = convertKeysToRoles(res.data.user.role)
                        let responseJson = {
                            ...res.data.user,
                            id: res.data.user.id,
                            username: res.data.user.username,
                            addedBy: res.data.user.addedBy,
                            name: res.data.user.name,
                            email: res.data.user.email,
                            role: role,
                            token: res.data.token,
                            userAgentsLimit: res.data.user.userAgentsLimit,
                            userQAlimit: res.data.user.userQAlimit,
                            companyLogo: res.data.user.companyLogo,
                            companyName: res.data.user.companyName,
                            isAgentCommon: res.data.user.isAgentCommon,
                            minimumMonologueDuration: res.data.user.minimumMonologueDuration,
                            minimumDeadAirDuration: res.data.user.minimumDeadAirDuration,
                            timezone: res.data.user.timezone ? res.data.user.timezone : moment.tz.guess(true),
                            integrationAccess: res.data.user.integrationAccess,
                            isRecordingDownloadAllowed: res.data.user.isRecordingDownloadAllowed,
                            qaCriteria: res.data.user.qaCriteria
                                ? res.data.user.qaCriteria
                                : { failure: 85, good: 90, excellent: 90 },
                            feebBackExpiryLink: res.data.user.feebBackExpiryLink,
                            coaching: res.data.user.coaching,
                            isAgentUniqueIdentifier: res.data.user.isAgentUniqueIdentifier,
                            isOnBoardingDone: res.data.user.isOnBoardingDone,
                            isOnBoardingDoneCa: res.data.user.isOnBoardingDoneCa,
                            isDummyUser: res.data.user.isDummyUser,
                            isCallCustomTextEnable : res.data.user.isCallCustomTextEnable,
                            isEnthuUser : res.data.user.isEnthuUser,
                            isCallMetadataVisible :res.data.user.isCallMetadataVisible ? res.data.user.isCallMetadataVisible : false,
                            minimumCallDuration : res.data.user.minimumCallDuration ?  res.data.user.minimumCallDuration : 180,
                            plan:res.data.user.plan,
                            isSpeakerLabel:res.data.user.isSpeakerLabel,
                            privilege:res.data.user.privilege,
                            signupInProgress: res.data.user.signupInProgress,
                            isBasePlan: res.data.user.plan?.isBasePlan,
                            isAdmin: role.includes("admin"),
                            isAgent: role.includes("agent"),
                            isQa: role.includes("QA"),
                            isQm: role.includes("QM"),
                            isTl: role.includes("TL"),
                            isClient: role.includes("client"),
                            signupInProgress:res.data.user.signupInProgress,
                            integration:res.data.user.integration,
                            speakerLabel: res.data.user.speakerLabel,
                        };

                        if (pipedriveCode) {
                            localStorage.removeItem('pipedriveCode');
                            alert('Pipedrive integrated successfully!');
                        }

                        resolve({ ok: true, json: () => responseJson });
                    })
                    .catch((err) => {
                        if (err.response && err.response.data) {
                            reject(err.response.data.error);
                        } else {
                            reject('Username or password is incorrect');
                        }
                    });

                return;
            }

            // register

            if (url.endsWith('/users/register') && opts.method === 'POST') {
                // get parameters from post request
                let params = JSON.parse(opts.body);
                // alert(JSON.stringify(params))
                // add new users
                let newUser = {
                    username: params.email,
                    password: params.password,
                    name: params.fullname,
                    phoneNumber : params.phonenumber,
                    companyName : params.companyname,
                    // role: 'user',
                    // role: 'admin',
                };

                servicePost('register', newUser)
                    .then((res) => {
                        let responseJson = {
                            id: res.userId,
                            username: res.username,
                            role: res.role,
                            token: res.token,
                        };
                        resolve({ ok: true, json: () => responseJson });
                    })
                    .catch((err) => {
                        reject(err.response.data.message);    
                    });

                return;
            }

            // forget password
            if (url.endsWith('/users/password-reset') && opts.method === 'POST') {
                // get parameters from post request
                let params = JSON.parse(opts.body);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username;
                });

                if (filteredUsers.length) {
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve({ ok: true, json: () => responseJson });
                } else {
                    // else return error
                    reject('Sorry, we could not find any registered user with entered username');
                }
                return;
            }

            // get users
            if (url.endsWith('/users') && opts.method === 'GET') {
                // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                if (
                    opts.headers &&
                    opts.headers.Authorization ===
                        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4'
                ) {
                    resolve({ ok: true, json: () => users });
                } else {
                    // return 401 not authorised if token is null or invalid
                    reject('Unauthorised');
                }

                return;
            }

            // pass through any requests not handled above
            realFetch(url, opts).then((response) => resolve(response));
        });
    };
}
