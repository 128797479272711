import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { X } from 'react-feather';
import excelIcon from '../assets/images/excelLogo.png';

class GeneralFileUploader extends Component {
    static defaultProps = {
        showPreview: true,
    };

    constructor(props) {
        super(props);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

        this.state = {
            selectedFiles: props.files?.length > 0 ? props.files : [],
        };
    }

    /**
     * Handled the accepted files and shows the preview
     */
    handleAcceptedFiles = (files) => {
        var allFiles = files;

        if (this.props.showPreview) {
            files.map((file) =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: this.formatBytes(file.size),
                })
            );

            allFiles = this.state.selectedFiles;
            allFiles.push(...files);
            this.setState({ selectedFiles: allFiles });
        }

        if (this.props.onFileUpload) this.props.onFileUpload(allFiles);
    };

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    clearFiles = () => {
        this.setState({
            selectedFiles: [],
        });
        this.props.onFileUpload([]);
    };

    render() {
        if (this.props.uploadComplete) {
            this.clearFiles();
            this.props.setUploadComplete();
        }
        return (
            <React.Fragment>
                <Dropzone
                    accept={this.props.accept}
                    minSize={0}
                    maxSize={524288000}
                    onDrop={(acceptedFiles) => {
                        if (acceptedFiles.length === 0) {
                        } else {
                            this.handleAcceptedFiles(acceptedFiles);
                        }
                    }}
                    {...this.props}>
                    {({ getRootProps, getInputProps }) => (
                        <div>
                            <div className="dropzone text-center">
                                {this.state.selectedFiles.length === 0 && (
                                    <div className="dz-message py-2 needsclick" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <i className="h1 text-muted uil-cloud-upload"></i>
                                        <h3>{this.props.title}</h3>
                                        <span className="text-muted font-13">
                                            (Please be patient while uploading the files)
                                            <br />
                                            {this.props.subHead}
                                            <br />
                                        </span>
                                    </div>
                                )}
                                {this.state.selectedFiles.length > 0 && (
                                    <div>
                                        {this.state.selectedFiles.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + '-file'}>
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            {f.preview && (
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        className="rounded bg-light"
                                                                        style={{ width: 'auto', height: '80px' }}
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                </Col>
                                                            )}
                                                            {!f.preview && f.type !== undefined && (
                                                                <Col className="col-auto">
                                                                    {this.props.type && this.props.type == 'excel' ?  <img width={50} style={{marginLeft:12}} src={excelIcon}></img> :
                                                                        <span className="avatar-title bg-primary rounded">
                                                                         {f.type.split('/')[0] }
                                                                        </span>}
                                                                </Col>
                                                            )}
                                                            <Col className="ps-0">
                                                                <Row>
                                                                    <Col md={10}>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted fw-bold">
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <X
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                this.clearFiles();
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </React.Fragment>
        );
    }
}

export default GeneralFileUploader;
