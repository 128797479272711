export const CONSOLE_URL = process.env.REACT_APP_CONSOLE_URL;
export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
export const CHARGEBEE_SITE_ENTHU = process.env.REACT_APP_CHARGEBEE_SITE_ENTHU;
export const CHARGEBEE_SITE_TRACTIONPOD = 'tractionpod';

export const SALESLOFT_CLIENT_ID = process.env.REACT_APP_SALESLOFT_CLIENT_ID

export const DEFAULT_SERVICE_VERSION = 'v1';
export const HUBSPOT_CLIENT_ID = '0aeec696-6994-4f54-97eb-0e276b744d16';
export const WEBEX_CLIENT_ID = 'Cd21ce1faca27b1e0cdd24a14e7bd4ba8e3f31f76c906798589a13e23ee40b446';
export const ZOOM_CLIENT_ID = 'yMN3KRzlQoCsWZQKgcv3Lw';
export const DIALPAD_CLIENT_ID = 'Qp2XHPdPyazwtEJehsmhKRX5E';
export const RINGCENTRAL_CLIENT_ID = process.env.REACT_APP_RINGCENTRAL_CLIENT_ID
export const RINGCENTRAL_API_URL = process.env.REACT_APP_RINGCENTRAL_API_URL

/**
 * Bullhorn Environment Parameter
*/
export const BULLHORN_AUTH_URL = "https://auth-emea.bullhornstaffing.com/oauth/authorize"

//Live Environment 
export const SALESFORCE_CLIENT_ID =
    '3MVG9n_HvETGhr3BFp_KtgUct3JlDM8HRUT.CSxp2zBN6PnOHStJw9h47P75qQclPhLwWbFpHBSyQSuOCeu7N';


