// Developers working on this file should not change these IDs until they have talked with their higher manager.
const staticKeysForRole = {
  agent: "173afbe9-dfcd-4d21-9dfb-fb6b6a2c915d",
  admin: "43775bb6-4c51-4131-9b20-76a5ad6ef6b4",
  QA: "07af2005-0787-47ce-8f15-a05b182328e6",
  QM: "32729087-18f9-40e2-b69b-4ac39874d911",
  client: "1f05f385-749e-4f18-9d24-1267ec2baf67",
  sales: "20623cae-cfa7-4ab6-b829-ff5c9634f7f0",
  jose : "ce999790-9446-4e34-8ded-2b252348ace7",
  TL : "ae502474-42a4-4433-bb18-d38b750a792f"
};
exports.staticKeysForRole = staticKeysForRole;