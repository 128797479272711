import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { toggleToast } from '../../redux/customToast/actions';
import {
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { X } from 'react-feather';

const CustomToast = () => {

    const dispatch = useDispatch();
    const { isOpen, children, autoClose, timer, success } = useSelector((state) => state.CustomToastReducer);

    const toggle = (data = {}) => {
        dispatch(toggleToast({ isOpen: !isOpen, ...data }));
    }

    useEffect(() => {
        if (autoClose) {
            setTimeout(() => {
                toggle({
                    autoClose: false,
                });
            }, timer);
        }
    }, [autoClose]);

    const closeBtn = (
        <button
            onClick={toggle}
            type="button"
            className='toat-modal-close-btn'
        >
            <X
                width={17}
                height={17}
                strokeWidth={3}
            />
        </button>
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle()}
            >
                <ModalHeader
                    toggle={() => toggle()}
                    style={{
                        padding: "10px",
                    }}
                    close={closeBtn}
                >
                </ModalHeader>
                <ModalBody
                    style={{
                        padding: "10px",
                        paddingTop: "2px",
                        marginTop: "-10px",
                        color: `${success ? "#28A745" : "#EA3739"}`
                    }}
                >
                    {children}
                </ModalBody>

            </Modal>


        </>
    );


}

export default CustomToast;