import { axios } from "./api";
import { SERVICE_URL } from "../constants/utility";

const baseUrl = SERVICE_URL;

export default class BaseCRUDService {
  constructor(base) {
    this.base = `${baseUrl}/${base}`;
  }

  getAll = async (params) => {
    return axios
      .get(`${this.base}/`, { params: params })
      .then((res) => res.data);
  };

  get = async (id) => {
    return axios.get(`${this.base}/${id}/`).then((res) => res.data);
  };

  create(data) {
    return axios.post(`${this.base}/`, data).then((res) => res.data);
  }

  update(id, data) {
    debugger
    return axios.put(`${this.base}/${id}/`, data).then((res) => res.data);
  }

  delete(id) {
    return axios.delete(`${this.base}/${id}/`).then((res) => res.data);
  }

  deleteAll() {
    return axios.delete(`${this.base}/`).then((res) => res.data);
  }
}

