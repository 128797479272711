import React from 'react';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        const history = createBrowserHistory();
        const params = useParams();
        const match  = {params: useParams()};
        return <Component match={match} history={history} location={history.location} {...props} />;
    }

    return ComponentWithRouterProp;
}