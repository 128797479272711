import React, { Component } from "react";
import { Row, Col, Alert, Button, Card, CardBody, Input } from "reactstrap";
// import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Form from "../../../components/FormControls/Form";
import FormControls from "../../../components/FormControls/FormControls";
import FormFeedback from "../../../components/FormControls/FormFeedback";
import Label from "../../../components/FormControls/Label";
import CustomForm from "../../../components/FormControls/CustomForm";
import FormGroup from "../../../components/FormControls/FormGroup";
import { getFormValidation } from "../../../components/FormControls/Validation";
import { servicePost } from "./../../../helpers/api";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import InfoIcon from "./../../../components/InfoIcon";
import { teamService } from "../../../services/network/manage/teamServices";
import { Formik } from "formik";
class AddAgent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuccess: false,
      message: "",
      agentStatus: 0,
      error: false,
      errorMessage: "",
      errorColor: "",
      listTeam: "",
      selectedTeam: "",
      assignTeam: "",
      emptyTeam: false,
      // tableRecords: this.props.tableRow
      isProcessing: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validation = [
      {
        name: "agentName",
        validations: [{ type: "required", message: "This field is required" }],
      },
      {
        name: "agentEmail",
        validations: [{ type: "required", message: "This field is required" }],
      },
    ];
    // HEADERS
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "JWT " + this.props.user.token,
    };

    if (this.props.data.team.length > 0) {
      this.state.assignTeam = this.props.data.team.map((_team) => {
        return {
          label: _team.teamName,
          value: _team._id,
        };
      });
      this.state.selectedTeam = this.props.data.team.map((_team) => {
        return {
          label: _team.teamName,
          value: _team._id,
        };
      });
    }
  }

  removePopUp = (seconds) => {
    setTimeout(() => {
      this.props.toggle();
      this.props.view();
    }, seconds);
  };

  componentDidMount = () => {
    this.getAllTeam();
  };

  /**GET TEAM LIST */
  getAllTeam = async () => {
    try {
      teamService
        .getAllTeamsList()
        .then((res) => {
          this.setState({
            listTeam: [res[0]]
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Handles the submit
   */
  handleSubmit = (values) => {
    console.log("values",values);
    if (
      (values.agentEmail && values.agentName) ||
      (values.submitType == "1" && !this.state.assignTeam == "")
    ) {
      let data = {
        agentEmail:
          values.agentEmail !== undefined
            ? values.agentEmail.toLowerCase()
            : "",
        agentName: values.agentName,
        agentRole: "sales",
        selectTeam: this.state.assignTeam,
        agentPassword: "1234567890",
        agentID: values.agentID,
        updateType: values.updateType,
        uniqueIdentifier: values.agentUniqueIdentifier,
        isAgentLoginAllowed : values.isAgentLoginAllowed ? values.isAgentLoginAllowed : false
      };
      try {
        this.props.toggle();
        if (values.submitType == "1") {
          this.setState({
            isProcessing: true,
          });
          servicePost("agent/editAgent", data, this.headers)
            .then((res) => {
              if (res.status == 1) {
                this.setState({
                  isProcessing: false,
                });
                // this.setState({
                //     isSuccess: true,
                //     errorMessage: 'Agent Updated.',
                //     error: true,
                //     errorColor: 'success'
                // })
                // this.props.tableRow= res.data
                toast.success("Updating Agent", { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });
                // this.removePopUp(2000);
                this.props.view();
              }
            })
            .catch((err) => {
              console.log(err);
              // this.setState({
              //     isSuccess: true,
              //     errorMessage: 'Internal server error. Please try again later',
              //     error: true,
              //     errorColor: 'danger'
              // })
              toast.error("Internal server error. Please try again later", { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });
              this.removePopUp(2000);
            });
        }
        if (values.submitType == "0") {
          this.setState({
            isProcessing: true,
          });
          servicePost("agent/addAgent", data, this.headers)
            .then((res) => {
              if (res.status == 1) {
                // this.setState({
                //     isSuccess: true,
                //     errorMessage: 'Agent created.',
                //     error: true,
                //     errorColor: 'success'
                // })
                // this.props.tableRow= res.data
                toast.success("Creating Agent", { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });

                this.setState({
                  isProcessing: false,
                });
                // this.removePopUp(2000);
                // this.props.toggle();
                this.props.view();
              }
              if (res.status == 2) {
                this.setState({
                  isSuccess: true,
                  errorMessage: res.error,
                  error: true,
                  errorColor: "danger",
                });
                toast.error( "Your plan allows addition of maximum " +
                  res.data.countAgents +
                  " agents. Please contact your account manager at tushar@enthu.ai to upgrade the limit.",{ position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });
                this.setState({
                  isProcessing: false,
                });
                //this.removePopUp(3000);
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                isSuccess: true,
                errorMessage: "Internal server error. Please try again later",
                error: true,
                errorColor: "danger",
                isProcessing: false,
              });
              toast.error("Internal server error. Please try again later", { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });
            });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        emptyTeam: true,
      });
    }
  };

  handleChange = (e) => {
    console.log(e);
    let data = {
      agentEmail: e.target.value,
    };
    servicePost("agent/checkEmail", data, this.headers).then((res) => {
      if (res.status === 1) {
        this.setState({
          agentStatus: res.status,
          errorMessage: res.error,
          isProcessing: true,
        });
      } else {
        this.setState({
          agentStatus: res.status,
          errorMessage: res.error,
          isProcessing: false,
        });
      }
    });
  };

  onChangeTeamList = async (selectedValue) => {
    this.setState({
      // selectedTeam: selectedValue.value,
      assignTeam: selectedValue,
      emptyTeam: false,
    });
  };

  render() {
    let agentData = {
      email: "",
      name: "",
      team: "",
      submitType: "0",
      agentID: "",
      uniqueIdentifier: "",
    };

    if (this.props.data) {
      agentData = {
        email: this.props.data.email,
        name: this.props.data.name,
        submitType: this.props.data._id ? "1" : "0",
        agentID: this.props.data._id,
        uniqueIdentifier: this.props.data.uniqueIdentifier,
        isAgentLoginAllowed : this.props.data.isAgentLoginAllowed
      };
    }

    this.props.user.isAgentUniqueIdentifier &&
      this.validation.push({
        name: "agentUniqueIdentifier",
        validations: [{ type: "required", message: "This field is required" }],
      });
    return (
      <React.Fragment>
        <Card className="addModal_card">
          <CardBody>
            <Form
              initialValues={{
                agentName: agentData.name,
                agentEmail: agentData.email,
                agentUniqueIdentifier: agentData.uniqueIdentifier,
                agentRole: "Sales Rep",
                updateType: "1",
                submitType: agentData.submitType,
                agentID: agentData.agentID,
                addedBy: this.props.user.id,
                isAgentLoginAllowed: agentData.isAgentLoginAllowed,
              }}
              validationSchema={getFormValidation(this.validation)}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <CustomForm noValidate onSubmit={handleSubmit}>
                  {(this.props.typeForm === "edit" ||
                    this.props.typeForm == "editEmail") && (
                    <React.Fragment>
                      <FormGroup row>
                        <Col sm={12}>
                          <Label for="example">
                            Full Name{" "}
                            <span style={{ color: "#5369f8" }}>*</span>
                          </Label>
                          <FormControls
                            type="text"
                            name="agentName"
                            // value={agentData.name}
                            id="agentName"
                            autoComplete="false"
                            placeholder="Enter Full Name"
                          />
                          <FormFeedback type="invalid">
                            {errors.agentName}
                          </FormFeedback>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col sm={12}>
                          <Label for="example">
                            Email <span style={{ color: "#5369f8" }}>*</span>
                          </Label>
                          {this.props.typeForm == "edit" && (
                            <FormControls
                              type="email"
                              // value={agentData.email}
                              onChange={this.handleChange}
                              name="agentEmail"
                              id="agentEmail"
                              autoComplete="false"
                              placeholder="Enter Email ID"
                            />
                          )}

                          {this.props.typeForm == "editEmail" && (
                            <FormControls
                              type="email"
                              // value={agentData.email}
                              onChange={this.handleChange}
                              name="agentEmail"
                              id="agentEmail"
                              autoComplete="false"
                              placeholder="Enter Email ID"
                              disabled
                            />
                          )}

                          {this.state.agentStatus === 1 && (
                            <React.Fragment>
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "normal",
                                }}
                              >
                                {" "}
                                {this.state.errorMessage}{" "}
                              </span>
                            </React.Fragment>
                          )}
                          <FormFeedback type="invalid">
                            {errors.agentEmail}
                          </FormFeedback>
                        </Col>
                      </FormGroup>

                      {this.props.user.isAgentUniqueIdentifier && (
                        <FormGroup row>
                          <Col sm={12}>
                            {this.props.typeForm == "edit" && (
                              <React.Fragment>
                                <Label for="example">
                                  Agent Unique Identifier{" "}
                                  {/* <span style={{ color: '#5369f8' }}>*</span> */}
                                </Label>

                                <FormControls
                                  type="text"
                                  // value={agentData.uniqueIdentifier}
                                  name="agentUniqueIdentifier"
                                  id="agentUniqueIdentifier"
                                  autoComplete="false"
                                  placeholder="Add agent unique identifier"
                                />
                              </React.Fragment>
                            )}

                            {this.props.typeForm == "editEmail" && (
                              <React.Fragment>
                                <Label
                                  style={{ marginTop: "15px" }}
                                  for="example"
                                >
                                  Agent Unique Identifier
                                </Label>
                                <FormControls
                                  type="text"
                                  // value={agentData.uniqueIdentifier}
                                  onChange={this.handleChange}
                                  name="agentUniqueIdentifier"
                                  id="agentUniqueIdentifier"
                                  autoComplete="false"
                                  placeholder="Add agent unique identifier"
                                />
                              </React.Fragment>
                            )}
                          </Col>
                        </FormGroup>
                      )}

                      <Row>
                        <Col sm={12}>
                          <Label for="example">
                            <div className="d-flex align-items-center">
                              Assign to an existing Team{" "}
                              <InfoIcon
                                text={"How to create a new Team?"}
                                link={
                                  "https://help.enthu.ai/support/solutions/articles/82000465123-how-to-create-a-new-team-in-enthu-ai-"
                                }
                                id={'createNewTeam'}
                              />
                            </div>
                          </Label>
                          <Select
                            onChange={this.onChangeTeamList}
                            value={this.state.assignTeam}
                            isMulti={this.props.user.isAgentCommon}
                            options={this.state.listTeam}
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder={"Select a Team"}
                            isDisabled={!this.state.listTeam}
                            isLoading={!this.state.listTeam}
                          />
                        </Col>
                      </Row>

                      <FormGroup row>
                        <Col sm={12}>
                          <Label style={{ marginTop: "15px" }} for="example">
                            Role
                          </Label>
                          <FormControls
                            type="text"
                            name="agentRole"
                            id="agentRole"
                            autoComplete="false"
                            value="Sales Rep"
                            readOnly
                            disabled
                          />
                          <FormControls
                            type="hidden"
                            name="updateType"
                            value="0"
                          />
                        </Col>
                      </FormGroup>
                      {!(this.props.user.id === '601bba49c89dd203cee971b4' || this.props.user.addedBy === '601bba49c89dd203cee971b4') &&
                        <FormGroup row>
                          <Col sm={12}>
                            <Label for="example">
                              Provide system access to an agent
                            </Label>
                            <FormGroup className="mb-1 d-flex">
                              <FormControls
                                type="radio"
                                name="isAgentLoginAllowed"
                                id="yes"
                                checked={values.isAgentLoginAllowed}
                                onChange={() => setFieldValue("isAgentLoginAllowed", true)}
                                value={true}
                                label="Yes"
                              />
                              <FormControls
                                type="radio"
                                name="isAgentLoginAllowed"
                                id="no"
                                checked={!values.isAgentLoginAllowed}
                                onChange={() => setFieldValue("isAgentLoginAllowed", false)}
                                value={false}
                                label="No"
                                style={{ paddingLeft: "3rem" }}
                              />
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      }
                    </React.Fragment>
                  )}

                  {this.props.typeForm === "team" && (
                    <React.Fragment>
                      <FormGroup row>
                        <Col sm={12}>
                          <Label
                            for="example"
                            style={{
                              color: !this.state.emptyTeam ? "" : "#ff5c75",
                            }}
                          >
                            Assign to Team
                          </Label>
                          <Select
                            onChange={this.onChangeTeamList}
                            value={this.state.assignTeam}
                            isMulti={this.props.isAgentCommon}
                            options={this.state.listTeam}
                            className="react-select"
                            classNamePrefix={
                              !this.state.emptyTeam
                                ? "react-select"
                                : "text-danger react-select"
                            }
                            placeholder={"Select a Team"}
                          />
                        </Col>
                      </FormGroup>
                      <FormControls type="hidden" name="updateType" />
                    </React.Fragment>
                  )}

                  <FormGroup className="row">
                    <Col sm={6}>
                      <Button
                        onClick={this.props.toggle}
                        style={{ float: "right" }}
                        className="btn btn-primary cancel-btn"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <FormControls type="hidden" name="submitType" />
                      <FormControls type="hidden" name="agentID" />
                      <FormControls type="hidden" name="addedBy" />
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.isProcessing}
                      >
                        {this.props.bName == "Update Agent" && (
                          <React.Fragment>Update Agent</React.Fragment>
                        )}
                        {this.props.bName != "Update Agent" && (
                          <React.Fragment>Create Agent</React.Fragment>
                        )}
                      </Button>
                    </Col>
                  </FormGroup>

                  {this.state.isSuccess && (
                    <React.Fragment>
                      <FormGroup row style={{ textAlign: "center" }}>
                        <Col sm={12}>
                          {this.state.message}
                          {this.state.error && (
                            <Alert
                              color={this.state.errorColor}
                              isOpen={this.state.error ? true : false}
                            >
                              <div>{this.state.errorMessage}</div>
                            </Alert>
                          )}
                        </Col>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </CustomForm>
              )}
            </Form>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // const { user, loading, error } = state.Auth;
  return state.Auth;
};
export default connect(mapStateToProps)(AddAgent);
