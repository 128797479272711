// import { Form as CustomForm } from '@availity/form';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import { Form as CustomForm } from 'react-bootstrap';

function Form(props) {
    return (
        <Formik {...props} />

    )
}


export default Form;