// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {

    const user = getLoggedInUser();
    
    if (user == null) {
        return false;
    }
    const decoded = jwtDecode(user.token);

    
    const currentTime = Date.now() / 1000;
    
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

//return the decoded token data from params
const getDecodedTokenFromParams = () => {
    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const token = urlSearchParams.get('token');
    
    if (!token) {
        return false;
    }
    try {
        const decoded = jwtDecode(token);
        return decoded;
    } catch (error) {
        console.error('Invalid token:', error);
        return false;
    }
};
const getDecodedToken = () => {
    const user = getLoggedInUser();
    
    if (user == null) {
        return false;
    }
    const decoded = jwtDecode(user.token);

    return decoded
}

export { isUserAuthenticated, getLoggedInUser,getDecodedTokenFromParams, getDecodedToken };
