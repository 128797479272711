import { Card, Dropdown, ButtonGroup } from 'react-bootstrap';

function DropdownToggle(props) {
    return (
        <Dropdown.Toggle style={{ border: "none", padding: '0' }} {...props} />
    )
}

function DropdownMenu(props) {
    return (
        <Dropdown.Menu {...props} />
    )
}

function DropdownItem(props) {
    return (
        <Dropdown.Item {...props} />
    )
}

function DropdownComponent(props) {
    return (
        <Dropdown as={ButtonGroup} {...props} />
    )
}

export { DropdownComponent, DropdownToggle, DropdownMenu, DropdownItem };












