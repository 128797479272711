import { FIRE_TOAST, TOGGLE_TOAST } from "./constant";


const fireToastPayload = {
    isOpen: true,
}
export const fireToast = (data = {}) => ({
    type: FIRE_TOAST,
    payload: { ...fireToastPayload, ...data },
});


const toggleToastPayload = {
    children: null,
}
export const toggleToast = (data = {}) => ({
    type: TOGGLE_TOAST,
    payload: { ...toggleToastPayload, ...data },
});