import React, { Component } from 'react';
import { Col, Button, Card, CardBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
// import { AvField } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import CustomForm from '../../../components/FormControls/CustomForm';
import Label from '../../../components/FormControls/Label';
import FormGroup from '../../../components/FormControls/FormGroup';
import FormFeedback from '../../../components/FormControls/FormFeedback';
import { getFormValidation } from '../../../components/FormControls/Validation';
import { servicePost } from './../../../helpers/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';

class EditCustomerDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerDetails: this.props.customerDetails,
            options: [
                { value: '', label: 'Deal Status' },
                { value: 'lost', label: 'Lost' },
                { value: 'won', label: 'Won' },
                { value: 'inProgress', label: 'In Progress' },
            ],
            isProcessing: false,
        };
        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }


    /**
     * Handles the submit
     */
    handleSubmit = async (customerDetails) => {
        if (customerDetails.fullName) {
            const data = {
                customerDetails,
                callId: this.props.callID,
            };
            const res = await servicePost('call', data, this.headers);
            toast(`Customer Details ${res.message} `, {
                bodyClassName: res.error === null ? 'success-toast' : 'error-toast',
            });
            this.props.toggle();
            this.props.getCallDetails();
        }
    };

    render() {
        const {
            customerDetails: { fullName, email, dealStatus, dealValue, notes },
        } = this.state;
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                fullName: fullName,
                                email: email,
                                dealStatus :dealStatus,
                                dealValue: dealValue,
                                notes: notes
                            }}
                            validationSchema={getFormValidation([
                                { "name": "fullName", "validations": [{ "type": "required", "message": "This field is invalid" }] },
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label for="example">Full Name *</Label>
                                                <FormControls
                                                    type="text"
                                                    name="fullName"
                                                    // value={fullName}
                                                    id="fullName"
                                                    autoComplete="false"
                                                // required
                                                />
                                                <FormFeedback type="invalid" >{errors.fullName}</FormFeedback>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label for="example">Email</Label>
                                                <FormControls
                                                    type="email"
                                                    // validate={{ required: false }}
                                                    // value={email}
                                                    onChange={this.handleChange}
                                                    name="email"
                                                    id="email"
                                                    autoComplete="false"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label for="example">Deal Status</Label>
                                                <FormControls type="select" value={dealStatus} id="dealStatus" name="dealStatus">
                                                    {this.state.options.map((option) => {
                                                        return (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        );
                                                    })}
                                                </FormControls>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label for="example">Deal Value (USD)</Label>
                                                <FormControls
                                                    type="number"
                                                    // validate={{ required: false }}
                                                    // value={dealValue}
                                                    onChange={this.handleChange}
                                                    name="dealValue"
                                                    id="dealValue"
                                                    autoComplete="false"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label for="example">Notes</Label>
                                                <FormControls
                                                    type="textarea"
                                                    // value={notes}
                                                    // validate={{ required: false }}
                                                    onChange={this.handleChange}
                                                    name="notes"
                                                    id="notes"
                                                    autoComplete="false"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </React.Fragment>
                                    <FormGroup className='row'>
                                        <Col sm={6}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit" disabled={this.state.isProcessing}>
                                                Save
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};
export default connect(mapStateToProps)(EditCustomerDetails);
