import React, { Component } from 'react';
import {  Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
/** IMPORT FORM */
import AddAgent from '../forms/settings/AddAgent';
import AddTeam from '../forms/settings/AddTeam';
import AddMoment from '../forms/settings/AddMoment';
import AddFeedback from '../forms/settings/AddFeedback';
import AddCustomer from '../forms/settings/AddCustomer';
import EditCustomer from '../forms/settings/EditCustomer';
import AddTag from './AddTag';
import AddLibrary from '../forms/AddLibrary';
import PlayList from '../forms/PlayList';
import AddQA from '../forms/settings/AddQA';
import AddClient from '../forms/settings/AddClient';
import InfoIcon from '../../components/InfoIcon';
import './truncte.css';
import { servicePost } from './../../helpers/api';
import { connect } from 'react-redux';
import Modals from './Modals';

class AddModal extends Component {
    constructor(props) {
        super(props);
        const modalTitle = this.props.modalTitle;

        this.state = {
            modal: false,
            buttonName: this.props.buttonName,
            modalType: this.props.modalType,
            modalTitle: modalTitle,
            isFooter: this.props.isFooter,
            showModal: false,
            showModalCustom: false,
            deleteCallUserDetails: false,
            disabled:this.props.disabled || false,
            // tableRowData:
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);

        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    handleShow() {
        // console.log(this.state)
        this.setState({ modal: true });
    }

    handleClose() {
        this.setState({ modal: false });
    }

    /**
     * Show/hide the modal
     */
    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    showModalCustomToggle = () => {
        this.setState({
            showModalCustom: false,
        });
    };

    /**
     * Opens large modal
     */
    openModalWithSize = (size) => {
        this.setState({ size: size, className: null });
        this.toggle();
    };

    /**
     * Opens modal with custom class
     */
    openModalWithClass = (className) => {
        this.setState({ className: className, size: null });
        this.toggle();
    };

    openModalWithClassw = () => {
        this.setState({ showModalCustom: true });
    };

    changeView = () => {
        this.props.modalView();
    };

    deleteUserDetails = async () => {
        this.setState({
            deleteCallUserDetails: true,
        });
    };

    deleteDetails = async () => {
        const res = await servicePost('deleteCustomerDetails', { callID: this.props.callID }, this.headers);

        window.location.reload();
    };

    toggleCallDetailsDelete = () => {
        this.setState({
            deleteCallUserDetails: false,
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.modalType == 'library' &&
                    this.state.modalType !== 'editCustomerDetails' &&
                    this.state.modalType !== 'customerDetails' && (
                        <React.Fragment>
                            <button
                                style={{ float: this.props.btnStyle, marginTop: '1px' }}
                                className="btn btn-primary btn-sm "
                                onClick={() => this.openModalWithClass('modal-dialog-scrollable')}
                                disabled = {this.state.disabled || false}
                                >
                                {this.props.buttonName}
                            </button>
                        </React.Fragment>
                    )}
                    {this.state.modalType == 'playlist'  && (
                        <React.Fragment>
                            <button
                                style={{ float: this.props.btnStyle, marginTop: '1px' }}
                                className="btn btn-primary btn-sm "
                                onClick={() => this.openModalWithClass('modal-dialog-scrollable')}
                                disabled = {this.state.disabled || false}
                                >
                                {this.props.buttonName}
                            </button>
                        </React.Fragment>
                    )}
                {this.state.modalType != 'library' && this.state.modalType != 'playlist' &&
                    this.state.modalType !== 'editCustomerDetails' &&
                    this.state.modalType !== 'customerDetails' && (
                        <div>
                            <Button
                                style={{ float: this.props.btnStyle, height: '42px' }}
                                className={
                                    this.state.modalType == 'moment'
                                        ? ' btn btn-info create-btn mb-2 btn-lr'
                                        : ' btn btn-info create-btn mb-2'
                                }
                                disabled = {this.state.disabled || false}
                                >
                                <div className="d-flex">
                                    <span onClick={() => this.openModalWithClass('modal-dialog-scrollable')}>
                                        {this.props.buttonName}
                                    </span>

                                    {this.state.modalType !== 'client' && this.state.modalType !== 'tag' && (
                                        <span>
                                            <InfoIcon text={this.props.infoText} link={this.props.infoLink} />
                                        </span>
                                    )}

                                    {this.state.modalType == 'client' && this.state.modalType !== 'tag' && (
                                        <span>
                                            <InfoIcon text={this.props.infoText} link={this.props.infoLink} />
                                        </span>
                                    )}
                                </div>
                            </Button>
                        </div>
                    )}

                {this.state.modalType === 'editCustomerDetails' && (
                    <React.Fragment>
                        <span className={this.state.showModalCustom ? "ps-3 prfileShowStaticDeatils" : "ps-3 prfileHideDeatils"}>
                            <Modals callID={this.props.callID} openModalWithClass={() => this.openModalWithClass('modal-dialog-scrollable')} />
                        </span>
                    </React.Fragment>
                )}

                {this.state.modalType === 'customerDetails' && (
                    <React.Fragment>
                        <a
                            className="ps-3 "
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.openModalWithClass('modal-dialog-scrollable')}>
                            Add Customer Detail
                        </a>
                    </React.Fragment>
                )}

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.state.className}
                    size={this.state.size}>
                    <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
                    <ModalBody className="addModal-body">
                        {this.state.className && this.state.className === 'modal-dialog-scrollable' && (
                            <React.Fragment></React.Fragment>
                        )}

                        {this.state.modalType == 'library' && (
                            <React.Fragment>
                                <AddLibrary
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    data={'0'}
                                    bName={this.props.buttonName}
                                    callId={this.props.callID}
                                    loadingBarRef={this.props.loadingBarRef}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'playlist' && (
                            <React.Fragment>
                                <PlayList
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    data={'0'}
                                    bName={this.props.buttonName}
                                    callId={this.props.callID}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'team' && (
                            <React.Fragment>
                                <AddTeam
                                    isAgentCommon={this.props.isAgentCommon}
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    data={'0'}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'agent' && (
                            <React.Fragment>
                                <AddAgent
                                    typeForm="edit"
                                    data={{ team: '' }}
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'client' && (
                            <React.Fragment>
                                <AddClient
                                    typeForm="edit"
                                    bName={this.props.bName}
                                    data={{ team: '' }}
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'qa' && (
                            <React.Fragment>
                                <AddQA
                                    typeForm="edit"
                                    data={{ team: '' }}
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'moment' && (
                            <React.Fragment>
                                <AddMoment
                                    themeId={this.props.themeId}
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    isAircallIntegrated={this.props.isAircallIntegrated}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'feedback' && (
                            <React.Fragment>
                                <AddFeedback
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'customerDetails' && (
                            <React.Fragment>
                                <AddCustomer
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    callID={this.props.callID}
                                    getCallDetails={this.props.getCallDetails}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'editCustomerDetails' && (
                            <React.Fragment>
                                <EditCustomer
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                    callID={this.props.callID}
                                    customerDetails={this.props.customerDetails}
                                    getCallDetails={this.props.getCallDetails}
                                />
                            </React.Fragment>
                        )}

                        {this.state.modalType == 'tag' && (
                            <React.Fragment>
                                <AddTag
                                    toggle={this.toggle}
                                    tableRow={this.props.recordData}
                                    view={this.changeView.bind(this)}
                                />
                            </React.Fragment>
                        )}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return state.Auth;
};

export default connect(mapStateToProps)(AddModal);