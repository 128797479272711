import { SET_DATE_RANGE, PAST_DATE_RANGE, DATE_SELECTION_FOR_REPORTS, MONTH_SELECTION } from './constants';

import * as optionsConstants from '../../constants/options';

// Initialize the local storage for date range
if (
    localStorage.getItem('dateRange') == localStorage.getItem('pastDateRange') &&
    localStorage.getItem('pastDateRange') == null
) { 
    localStorage.setItem('dateRange', JSON.stringify(optionsConstants.DATE_RANGE));
    localStorage.setItem('pastDateRange', JSON.stringify(optionsConstants.PAST_DATE_RANGE));
    // localStorage.setItem('month', JSON.stringify(optionsConstants.MONTH))
}

if( localStorage.getItem('month') === null || !localStorage.getItem('month') ){
    localStorage.setItem('month', JSON.stringify(optionsConstants.MONTH))
}

const INIT_STATE = {
    dateRange: JSON.parse(localStorage.getItem('dateRange')),
    pastDateRange: JSON.parse(localStorage.getItem('pastDateRange')),
    dateSelectionForReports: JSON.parse(localStorage.getItem('dateSelectionForReports')),
    month: JSON.parse(localStorage.getItem('month')),
};

const Layout = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_DATE_RANGE:
            localStorage.setItem('dateRange', JSON.stringify(action.payload));
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    ...action.payload,
                },
            };
        case PAST_DATE_RANGE:
            localStorage.setItem('pastDateRange', JSON.stringify(action.payload));
            return {
                ...state,
                pastDateRange: {
                    ...state.pastDateRange,
                    ...action.payload,
                },
            };
        case DATE_SELECTION_FOR_REPORTS:
            localStorage.setItem('dateSelectionForReports', JSON.stringify(action.payload));
            return {
                ...state,
                dateSelectionForReports: {
                    ...state.dateSelectionForReports,
                    ...action.payload,
                },
            };
        case MONTH_SELECTION:
          //  console.log("Payloadddddddddddddddddd",this.state.month,this.state.year,JSON.stringify(action.payload));
            localStorage.setItem('month', JSON.stringify({
                ...state.month,
                ...action.payload,
            }));
            // localStorage.setItem('year', JSON.stringify(action.payload.year));
            return {
                ...state,
                month: {
                    ...state.month,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default Layout;
