import React, { Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";

//Default loader
import Loader from "../components/Loader";
//Auth utility
import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";
import { authProtectedRoutes, publicRoutes } from "./index";
import withLayout from "../components/Layout";
import { includesWithCaseInsensitive } from "../helpers/common";
import AuthContext from "../providers/auth";
import { QueryClientProvider, QueryClient } from "react-query";
const VerticalLayout = React.lazy(() => import("../layouts/Vertical"));
const AuthLayout = React.lazy(() => import("../layouts/Auth"));
const CallSummary = React.lazy(() => import("../pages/settings/CallSummary"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));

export const queryClient = new QueryClient()

const makeRoutes = (authenticatedRoute,index) =>{
  return(
    <Route
      key={index + "p"}
      element={
        <RequireAuth roles={authenticatedRoute.roles} />
      }
    >
       <Route path={authenticatedRoute.path} element={<authenticatedRoute.component  isAuthorised={RoleAuthoriser(authenticatedRoute.roles)} />} key={index + "c"}>
            {authenticatedRoute.subRoutes? authenticatedRoute.subRoutes?.map( (childRoute,index)=>{
               return makeRoutes(childRoute,index);
            }):null }
       </Route>
    </Route>
  )
}

function RequireAuth() {
  const auth = isUserAuthenticated();
  if (!auth) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}

function RoleAuthoriser(roles) {
  const loggedInUser = getLoggedInUser();
  if (roles && loggedInUser) {
    const isAuthorised =
      roles &&
      roles.some((role) => {
        return loggedInUser.role.includes(role);
      });
    return isAuthorised;
  } else {
    return false;
  }
}

const RoutesV6 = (props) => {
  const { layout,user } = useSelector((state) => ({
    layout: state.Layout,
    user:state.Auth.user
  }));
  const isUserAuthenticate = isUserAuthenticated();
  const getLayout = () => {
    let layoutCls = VerticalLayout;
    if (!isUserAuthenticate || user?.signupInProgress) return AuthLayout;
    // if (!isUserAuthenticate ) return AuthLayout;
    return layoutCls;
  };
  let Layout = getLayout();
  return (
    <AuthContext.Provider value={getLoggedInUser()}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <BrowserRouter>
              <Layout {...props}>
                <Routes>
                  {publicRoutes.map((publicRoute, index) => {
                    if (includesWithCaseInsensitive(publicRoute?.roles, user?.role[0]) || !user) {
                      
                      if (user?.plan?.isBasePlan && includesWithCaseInsensitive(publicRoute?.plan, "basePlan")) {
                        return (
                          <Route
                            key={index}
                            path={publicRoute.path}
                            element={<publicRoute.component />}
                          />
                        );
                      }

                      if (!user?.plan?.isBasePlan && includesWithCaseInsensitive(publicRoute?.plan, "proPlan")) {
                        return (
                          <Route
                            key={index}
                            path={publicRoute.path}
                            element={<publicRoute.component />}
                          />
                        );
                      }
                    }
                  })}
                  {/* {!user?.signupInProgress &&authProtectedRoutes.map((authProtectedRoute, index) => { */}
                  {authProtectedRoutes.map((authProtectedRoute, index) => {
                    if (includesWithCaseInsensitive(authProtectedRoute?.roles, user?.role[0])) {

                      if (user?.plan?.isBasePlan && includesWithCaseInsensitive(authProtectedRoute?.plan, "basePlan")) {
                        return makeRoutes(authProtectedRoute, index);
                      }

                      if (!user?.plan?.isBasePlan && includesWithCaseInsensitive(authProtectedRoute?.plan, "proPlan")) {
                        return makeRoutes(authProtectedRoute, index);
                      }
                    }
                  })}
                  {/* {authProtectedRoutes.map((authProtectedRoute, index) => {
                    return (
                      <Route
                        key={index + "p"}
                        element={
                          <RequireAuth roles={authProtectedRoute.roles} />
                        }
                      >
                        <Route
                          key={index + "c"}
                          path={authProtectedRoute.path}
                          element={
                            <authProtectedRoute.component
                              isAuthorised={RoleAuthoriser(
                                authProtectedRoute.roles
                              )}
                            />
                          }
                        />
                      </Route>

                      // <Route
                      //     key={index}
                      //     path={authProtectedRoute.path}
                      //     roles={authProtectedRoute.roles}
                      //     element={
                      //         <RequireAuth  >
                      //             <authProtectedRoute.component isAuthorised={RoleAuthoriser(authProtectedRoute.roles)} />
                      //         </RequireAuth>
                      //     }

                      // />
                    );
                  })} */}
                </Routes>
              </Layout>
            </BrowserRouter>
          </ErrorBoundary>
        </Suspense>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};
export default RoutesV6;
