import { SET_DATE_RANGE, PAST_DATE_RANGE, DATE_SELECTION_FOR_REPORTS, MONTH_SELECTION } from './constants';

export const setDateRange = (from, to, type) => ({
    type: SET_DATE_RANGE,
    payload: { from: from, to: to, type: type },
});

export const setPastDateRange = (from, to, type) => ({
    type: PAST_DATE_RANGE,
    payload: { from: from, to: to, type: type },
});

export const setDateSelectionForReports = (type) => ({
    type: DATE_SELECTION_FOR_REPORTS,
    payload: { type: type },
});

export const setMonth = (month,year) => ({
    type: MONTH_SELECTION,
    payload: { month,year },
});
