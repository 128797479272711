// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Options from './options/reducers'
import CustomToastReducer from './customToast/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Options,
    CustomToastReducer,
});
