import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EnthuLogo from './../assets/images/enthu.png';
import { Container, Row, Col } from 'reactstrap';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    onReload = () => {
        window.location.reload(true);
    };

    render() {
        if (this.state.hasError) {
            return (
                <div className="account-pages my-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={4} lg={5} style={{marginTop: '30px'}}>
                                <div className="text-center">
                                    <div>
                                        <img src={EnthuLogo} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row style={{marginTop: '20px'}}>
                            <Col className="text-center">
                                <h3 className="mt-3">A new version of Enthu.AI is available.</h3>
                                <p className="text-muted mb-2">Please refresh to see the magic happening.</p>
                                <br />
                                <Link className="btn btn-lg btn-primary " onClick={this.onReload}>
                                    Click to refresh
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
