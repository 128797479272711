import React, { Component } from 'react';
import { configureFakeBackend } from './helpers';
// default
import './assets/scss/theme.scss';
import 'react-circular-progressbar/dist/styles.css';
import './assets/css/style.css';
import Routes from './routes/Routes';
import ToastContainer from './components/ToastContainer';
import CustomToast from './components/customToast/CustomToast';
// configure fake backend
configureFakeBackend();

class App extends Component {

    render() {
        return (
            <>
                <CustomToast />
                <ToastContainer />
                <Routes />
            </>
        );
    }
}
export default App;
