import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class InfoIcon extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }
    render() {
        return (
            <div>
                <span>
                    <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        className="text-grey-4"
                        height="1rem"
                        width="1rem"
                        style={{
                            marginRight: '6px',
                            marginLeft: '6px',
                        }}
                        id={'DisabledAutoHideExample' + this.props.id}>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                            fill="currentColor"></path>
                    </svg>
                </span>
                <Tooltip
                    isOpen={this.state.tooltipOpen}
                    autohide={false}
                    style={{ textAlign: 'left' }}
                    target={'DisabledAutoHideExample' + this.props.id}
                    toggle={this.toggle}>
                    {this.props.text}
                    <br />
                    <a href={this.props.link} target="_blank" style={{ color: 'lightskyblue' }}>
                        Learn here.
                    </a>
                </Tooltip>
            </div>
        );
    }
}

export default InfoIcon;
