// import { Field, Form, Checkbox, CheckboxGroup, Radio, RadioGroup, Feedback as FormFeedback, Input } from '@availity/form';
// import { SelectField } from '@availity/select';
import { FormControl, Form } from 'react-bootstrap';
import { useField } from 'formik';
import FormFeedback from './FormFeedback';

function FormControls(props) {
    const { type, ...otherprops } = props
    const [field, meta] = useField(props)


    if (type === "select") {
        function handleChange(event) {
            field.onChange(event)
            if (props.onChange)
                props.onChange(event)
        }
        return (
            <Form.Select {...props} onChange={handleChange}
                isInvalid={!!meta.error && meta.touched}
                value={field.value} />
        )
    }
    else if (type === "switch" || type === "radio" || type === "checkbox") {
        return (
            <Form.Check
                {...props}
            />
        )
    }
    else {
        function handleChange(event) {
            field.onChange(event)
            if (props.onChange)
                props.onChange(event)
        }
        return (

            <Form.Control
                {...props}
                onChange={handleChange}
                isInvalid={!!meta.error && meta.touched}
                value={field.value}
            />
        )
    }
}

export default FormControls;