import RegexEscape from "regex-escape";
import * as Yup from 'yup'
const { servicePost } = require('../helpers/api');
const { staticKeysForRole } = require("../../src/constants/role");

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

// Custom includes function with case-Insensitive
export const includesWithCaseInsensitive = (arr = [], searchTerm) => {
    return arr.some(item => item.toLowerCase().trim() === (searchTerm ? searchTerm?.toLowerCase().trim() : searchTerm));
}

 // ** Sync-Debounce Function ** Time in ms
 export const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  
  // ** Async-Debounce ** Time in ms
  export function asyncDebounce(func, wait, immediate = undefined) {
    let timeout;
    return function (...args) {
      return new Promise((resolve) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          timeout = null;
          if (!immediate) {
            Promise.resolve(func.apply(this, [...args])).then(resolve);
          }
        }, wait);
        if (immediate && !timeout) {
          Promise.resolve(func.apply(this, [...args])).then(resolve);
        }
      });
    };
  }
  
// CONVERTS THE OBJECT INTO QUERY_STRING
export const objectToQueryString = (obj) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join("&");
};

// CONVERTS THE QUERY_STRING INTO OBJECT
export const queryStringToObject = (queryString) => {
    const urlSearchParams = new URLSearchParams(queryString);

    let obj = {};
    for (const [key, value] of urlSearchParams.entries()) {
      obj[key] = value;
    }
    return obj;
};

export const dateFormat = (givenDate, time) => {
    if (givenDate.includes('Z') == true) {
        givenDate = givenDate.replace('Z', '');
    }
    var mydate = new Date(givenDate);
    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][
        mydate.getMonth()
    ];
    // return mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();
    if (time == true || time == 1) {
        return (
            mydate.getDate() +
            ' ' +
            month +
            ' ' +
            mydate.getFullYear() +
            ' ' +
            mydate.getHours() +
            ':' +
            mydate.getMinutes()
        );
    } else {
        return mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();
    }
};

export const caseInsensitiveSubStrMatch = (str, substr) => {
    var re = new RegExp(RegexEscape(substr), 'i');
    let match = str.match(re);
    if (match == null) {
        return false;
    }
    return true;
};

/** CONVERT SECONDS INTO MINTUES */
export const secondToMinutes = (seconds) => {
    return !isNaN(Number(seconds / 60)) ? Number(seconds / 60).toFixed(0) : 0;
};

// Seprate agent options into activated and deactivated

export const agentOptionsSepration = (agents) => {
    if (!agents.data) {
        var options = [];
    }

    var options = [];

    let activated = {
        label: 'Active',
        options: [],
    };

    let deactivated = {
        label: 'Deactivated',
        options: [],
    };

    if (agents.data && agents.data.length > 0) {
        for (var j = 0; j < agents.data.length; j++) {
            if (agents.data[j].isBlocked) {
                deactivated.options.push({ value: agents.data[j]._id, label: agents.data[j].name });
            } else {
                activated.options.push({ value: agents.data[j]._id, label: agents.data[j].name });
            }
        }
        options.push(activated);
        options.push(deactivated);
    } else {
        for (var j = 0; j < agents.length; j++) {
            if (agents[j].isBlocked) {
                deactivated.options.push({ value: agents[j]._id, label: agents[j].name });
            } else {
                activated.options.push({ value: agents[j]._id, label: agents[j].name });
            }
        }
        options.push(activated);
        options.push(deactivated);
    }
    return options;
};


export function validEmail(e) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search(filter) != -1;
}

export function getKeyValueSepratedData(data) {
    var keyValueArray = []
    if (data) {
        for (const [key, value] of Object.entries(data)) {
            keyValueArray.push(
                {
                    key: key,
                    value: value
                },
            )
        }
    }
    return keyValueArray;
}


export async function getRecordingBuffer(callId, headers) {
    try {
        const res = await servicePost(
            `call/${callId}/recording`,
            {},
            headers
        );
        console.log("res", res)
        return res;
        // const blob = new Blob([new Uint8Array(res.Body.data)], {type: 'audio/mp3' });
        // return blob;

    } catch (error) {
        console.log("error inside getRecordingBuffer in common", error)
    }
}

export const passwordPatternValidator = (password) => {
    const pattern =
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,24}$/;
    return pattern.test(password);
};

export const spiltStringOnCommas = (inputString) =>{
    // Split the input string based on commas
    const sentencesArray = inputString.split(',');

    // Trim whitespaces from each sentence
    return sentencesArray.map(sentence => sentence.trim());
}

// Create an inverse lookup object
const reverseStaticKeysForRole = {};
for (const role in staticKeysForRole) {
  const key = staticKeysForRole[role];
  reverseStaticKeysForRole[key] = role;
}

export const convertKeysToRoles = (keys) => {
    return keys.map(key => {
      const role = reverseStaticKeysForRole[key];
      if (role === undefined) {
        console.error(`Role for key '${key}' not found`);
        return null;
      }
      return role;
    });
  };


export const booleanFromString = (value) => {
    return value.toLowerCase() === 'true' ? true : false
}

export const formValidator = (schema) => {
    const fields = schema.fields;
    const newFields = {};
  
    Object.keys(fields).forEach((key) => {
      const field = fields[key];
      if (field?.type === 'string') {
        newFields[key] = field.trim();
      } else if (field?.type === 'object') {
        newFields[key] = formValidator(field);
      } else if (field?.type === 'array' && field?.innerType?.type === 'object') {
        newFields[key] = field.of(formValidator(field.innerType));
      } else {
        newFields[key] = field;
      }
    });
  
    return Yup.object().shape(newFields);
  };