import React, { Component } from 'react';
import { Col, Button, Card, CardBody } from 'reactstrap';
import { servicePost } from './../../helpers/api';
// import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Form from '../../components/FormControls/Form';
import FormControls from '../../components/FormControls/FormControls';
import CustomForm from '../../components/FormControls/CustomForm';
import Label from '../../components/FormControls/Label';
import FormGroup from '../../components/FormControls/FormGroup';
import { getFormValidation } from '../../components/FormControls/Validation';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class AddTag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            isShow: false,
            options: false,
            required: true,
            invalidFile: false,
            selectedFile: null,
            listTeam: '',
            selectedTeam: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();

        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    onChangeHandler = (event) => {
        console.log(event.target.files[0]);
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        });
    };

    /**
     * Handles the submit
     */
    handleSubmit = (values) => {
        let dataSend = {
            tagName: values.requestFormName.toLowerCase(),
        };

        try {
            servicePost('tags/add/tag', dataSend, this.headers)
                .then((res) => {
                    if (res.statusCode == 200) {
                        this.props.view();
                        this.props.toggle();
                        toast('Tag Added Sccessfully.', {
                            bodyClassName: 'success-toast',
                        });
                    }
                    if (res.statusCode == 2045) {
                        this.props.view();
                        this.props.toggle();
                        toast('Tag already exisit.', {
                            bodyClassName: 'error-toast',
                        });
                    }
                })
                .catch((err) => {
                    toast('Tag already exisit. Please add.', {
                        bodyClassName: 'error-toast',
                    });
                });
        } catch (err) {
            console.log(err);
        }
    };

    appendInput() {
        this.setState({
            isShow: true,
        });
    }

    removeInput() {
        this.setState({
            isShow: false,
        });
    }

    onKeyUpValue(event) {
        const groupValue = event.target.value;
        if (typeof groupValue !== 'undefined' && groupValue !== null) {
            this.setState({
                required: false,
            });
        }
    }

    handleFileChange = ({ target: { files } }) => {
        const cancel = !files.length;
        if (cancel) return;

        this.setState({ file: files[0] });
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeTeamList = async (selectedValue) => {
        this.setState({
            selectedTeam: selectedValue,
            assignTeam: selectedValue,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                requestFormName: ''
                            }}
                            validationSchema={getFormValidation([
                                { "name": "requestFormName", "validations": [{ "type": "required", "message": "This field is required" }] }
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="example">
                                                Tag Name <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                // required
                                                type="text"
                                                name="requestFormName"
                                                id="requestFormName"
                                                autoComplete="false"
                                                placeholder="Enter your tag name"
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className='row'>
                                        <Col sm={5}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit">
                                                Add tag
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return state.Auth;
};

export default connect(mapStateToProps)(AddTag);
