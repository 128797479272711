import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import InfoIcon from './InfoIcon';

const PageTitle = (props) => {
    const title = props.title || '';
    const items = props.breadCrumbItems || '';
    return (
        <React.Fragment>
            <Breadcrumb className="float-end mt-1 fs-14">
                <BreadcrumbItem>
                    <Link to="/">Enthu</Link>
                </BreadcrumbItem>
                {items.map((item, index) => {
                    return item.active ? (
                        <BreadcrumbItem active key={index}>
                            {item.label}
                        </BreadcrumbItem>
                    ) : (
                        <BreadcrumbItem key={index}>
                            <Link to={item.path}>{item.label}</Link>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>

            <h4 className="mb-1 mt-0 d-flex">
                {title}
                {props.infoText && <InfoIcon fromPageTitle="fromPageTitle" text={props.infoText} link={props.infoLink} id={props.infoId} />}
            </h4>
        </React.Fragment>
    );
};

export default PageTitle;
