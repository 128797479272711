import React, { Component } from 'react';
import { Row, Col, Spinner } from 'reactstrap';

class PreLoaderWidget extends Component {
    render() {
        if (this.props.spinner) {
            return (<div className="preloader">
                <div className="status">
                    <div className="spinner-border avatar-sm text-primary m-2" role="status"></div>
                </div>
            </div>)
        }
        return (
            <Row className="page-title mt-4">
                <Col md={12} xs={12} style={{ textAlign: "center" }}>
                    <div
                        style={{ marginTop: this.props.marginTop ? this.props.marginTop : "15%" }}
                    >
                        <Spinner className="m-2" type="grow" color={'primary'} />
                        <Spinner className="m-2" type="grow" color={'primary'} />
                        <Spinner className="m-2" type="grow" color={'primary'} />
                        <Spinner className="m-2" type="grow" color={'primary'} />
                        <Spinner className="m-2" type="grow" color={'primary'} />

                    </div>
                </Col>
            </Row>
        );
    }
}

export default PreLoaderWidget;
