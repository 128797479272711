import React, { Component } from 'react';
import { connect } from 'react-redux';
import { servicePost } from './../../helpers/api';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Col,
    Row,
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
// import { AvForm} from 'availity-reactstrap-validation';
import Form from '../../components/FormControls/Form';
import CustomForm from '../../components/FormControls/CustomForm';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { DropdownComponent, DropdownItem, DropdownMenu, DropdownToggle } from '../../components/Dropdown'
class Modals extends Component {
    constructor(props) {

        super(props);

        this.state = {
            modal: false,
            isLoading: false,
            isDelete: false,
        };

        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };

        this.toggle = this.toggle.bind(this);
    }

    openEditDetailsPopUp = () => {
        this.props.openModalWithClass()
    }

    toggle = (type = null) => {
        this.setState({
            modal: !this.state.modal,
            isDelete: type === 'delete' ? true : '',
        });
    };

    deleteDetails = async () => {
        try {
            this.setState({
                isLoading: true,
            });

            const res = await servicePost('deleteCustomerDetails', { callID: this.props.callID }, this.headers);

            window.location.reload();

            this.setState({
                isLoading: false,
                modal: false,
            });
        } catch (error) { }
    };

    render() {
        const { placement, ut_comment } = this.props;
        return (
            <div>
                <DropdownComponent
                    header={<>
                        <FeatherIcon.MoreVertical
                            stroke="#5369f8"
                            cursor="pointer"
                        />
                    </>}
                    items={[
                        {
                            "component": <><i className="uil uil-edit-alt me-2"></i>Edit Details</>,
                            onClick: this.openEditDetailsPopUp
                        },
                        {
                            "component": <><i className="uil uil-trash me-2"></i>Delete Details</>,
                            onClick: () => this.toggle('delete')
                        },
                    ]}
                    variant={'none'}
                />
                <DropdownComponent>
                    <DropdownToggle variant='none'>
                        <FeatherIcon.MoreVertical
                            stroke="#5369f8"
                            cursor="pointer"
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={this.openEditDetailsPopUp} >
                            <i className="uil uil-edit-alt me-2"></i>Edit Details
                        </DropdownItem>
                        <DropdownItem onClick={() => this.toggle('delete')}>
                            <i className="uil uil-trash me-2"></i>Delete Details
                        </DropdownItem>
                    </DropdownMenu>
                </DropdownComponent>




                {/* <Dropdown as={ButtonGroup} drop='down-centered'>
                    <Dropdown.Toggle tag="button" className="btn btn-link arrow-none p-0 text-muted">
                        <FeatherIcon.MoreVertical
                            stroke="#5369f8"
                            cursor="pointer"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        <DropdownItem onClick={this.openEditDetailsPopU}>
                            <i className="uil uil-edit-alt me-2"></i>Edit Details
                        </DropdownItem>
                        <DropdownItem onClick={() => this.toggle('delete')}>
                            <i className="uil uil-trash me-2"></i>Delete Details
                        </DropdownItem>
                    </Dropdown.Menu>
                </Dropdown> */}

                {/* <UncontrolledDropdown className='align-self-center profile-dropdown-menu'>
                    <DropdownToggle data-bs-toggle="dropdown"
                        tag="button"
                        className="btn btn-link p-0 dropdown-toggle mr-0">Dropdown</DropdownToggle>
                    <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items">
                        <DropdownItem header>Header</DropdownItem>
                        <DropdownItem>Some Action</DropdownItem>
                        <DropdownItem text>Dropdown Item Text</DropdownItem>
                        <DropdownItem disabled>Action (disabled)</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Foo Action</DropdownItem>
                        <DropdownItem>Bar Action</DropdownItem>
                        <DropdownItem>Quo Action</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> */}

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>
                        {this.state.isDelete ? 'Delete customer details' : ut_comment && ut_comment !== '' ? 'Update a comment'
                            : 'Add Comment'}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {!this.state.isDelete ? (
                                        <React.Fragment>

                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontSize: '16px', textAlign: 'left' }}>
                                                        Are you sure to delete customer details?
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col></Col>
                                                <Col style={{ width: 'auto' }}>
                                                    <Button
                                                        color="danger"
                                                        className="ms-3"
                                                        style={{ float: 'right' }}
                                                        onClick={this.deleteDetails}
                                                        disabled={this.state.isLoading}>
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        className="ms-3"
                                                        color="secondary"
                                                        style={{ float: 'right' }}
                                                        onClick={() => this.toggle('delete')}>
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.Auth;
    return { user };
};

export default connect(mapStateToProps)(Modals);