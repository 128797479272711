import React, { Component } from 'react';
import { Col, Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Form from '../../components/FormControls/Form';
import FormControls from '../../components/FormControls/FormControls';
import CustomForm from '../../components/FormControls/CustomForm';
import Label from '../../components/FormControls/Label';
import FormGroup from '../../components/FormControls/FormGroup';
import { serviceGet, servicePost } from './../../helpers/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import '../settings/truncte.css';
import _ from 'lodash';
import ReactTagsInput from '../miscellaneous/FormBuilder/InputWidgets/TagInput';
class PlayList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            isEmail: false,
            playListId: [this.props.playlistData?._id],
            message: '',
            librarayListOpt: '',
            selectLibrary: '',
            teamError: 0,
            libraryName: '',
            // tableRecords: this.props.tableRow
            playListError: false,
            bothVlaueSub: false,
            playListErrorCustom: false,
            libraryalreadyAdded: false,
            expiry: '1day',
            feebBackSelectedExpiryLink: { label: '', value: '' },
            rawInput: '',
            customEmails: [],
            sendCommentToCustomEmails: false,
            selectedAgentIds: [],
            isSendToCustomMail: (this.props.user.id === '601bba49c89dd203cee971b4' || this.props.user.addedBy === '601bba49c89dd203cee971b4') ? true : false,
            sharedAgentList: []

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    /** GET AGENTS LIST */
    librarayList = async () => {
        try {
            let librarayListOpt = [];
            await servicePost('api/v1/library/getLibraryList', { callId: this.props.callId }, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        var selectLibrary = [];
                        res.data.map((data) => {
                            if (data.callId.includes(this.props.callId)) {
                                selectLibrary.push({ value: data._id, label: data.libraryName });
                            }
                            librarayListOpt.push({ label: data.libraryName, value: data._id });
                        });
                    }
                    this.setState({
                        librarayListOpt,
                        selectLibrary,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the submit
     */
    handleSubmit = async (values) => {
        try {
            if (this.state.isSendToCustomMail && this.state.customEmails.length === 0) {
                this.setState({ isEmail: true, isSuccess: false })
                return
            }

            if (this.state.rawInput != "") {
                this.check_email(this.state.rawInput)
            }

            let data
            this.props.loadingBarRef.current.continuousStart()
            if (this.state.isSendToCustomMail) {
                let emails = _.toString(this.state.customEmails);
                let successMessage = '';

                data = {
                    playListId: [this.props.playlistData?._id],
                    endUserComment: values.callcomments,
                    expiry: this.state.feebBackSelectedExpiryLink.value,
                    emails: emails
                };
                await servicePost('api/v1/library/sharePlayList', data, this.headers)
                    .then((res) => {
                        this.props.loadingBarRef.current.complete()
                        if (res.status == 1) {
                            toast.success('Playlist Shared', { bodyClassName: 'custom-toast', pauseOnHover: true });
                            this.props.toggle();
                        } else if (res.status == 0) {
                            this.setState({
                                libraryalreadyAdded: true,
                            });
                        } else {
                            toast.error('Something went wrongg', {
                                bodyClassName: 'custom-toast',
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.loadingBarRef.current.complete()
                        toast.error('Something went wrong', {
                            bodyClassName: 'custom-toast',
                            pauseOnHover: true,
                        });
                    });
            }
            else {
                let newSelectedAgentsEmail = []

                for (let i = 0; i < this.state.selectedAgentIds.length; i++) {
                    let isAgentAlreadySelected = this.state.sharedAgentList.find(agent => agent.value === this.state.selectedAgentIds[i].value)
                    if (!isAgentAlreadySelected) {
                        newSelectedAgentsEmail.push(this.state.selectedAgentIds[i].email)
                    }
                }
                
                data = {
                    playListId: [this.props.playlistData?._id],
                    agentIds: this.state.selectedAgentIds.map(agentId => agentId.value),
                    endUserComment: values.callcomments,
                    playListName: this.props.playlistData.libraryName,
                    emails: _.toString(newSelectedAgentsEmail),
                    totalCalls: this.props.playlistData.callId.length,
                    totalChats: this.props.playlistData.chats?.length,
                    isChatActive: this.props.user.plan?.isChatActive
                }

                await servicePost('api/v1/library/sharePlayListWithAgents', data, this.headers)
                    .then((res) => {
                        this.props.loadingBarRef.current.complete()
                        if (res.status == 1) {
                            toast.success('Playlist Shared', { bodyClassName: 'custom-toast', pauseOnHover: true });
                            this.props.toggle();
                        } else if (res.status == 0) {
                            this.setState({
                                libraryalreadyAdded: true,
                            });
                        } else {
                            toast.error('Something went wrongg', {
                                bodyClassName: 'custom-toast',
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch((err) => {
                        this.props.loadingBarRef.current.complete()
                        console.log(err);
                        toast.error('Something went wrong', {
                            bodyClassName: 'custom-toast',
                            pauseOnHover: true,
                        });
                    });
            }



        } catch (err) {
            console.log(err);
            toast.error('Something went wrong', {
                bodyClassName: 'custom-toast',
                pauseOnHover: true,
            });
        }

    };

    check_email(text) {
        if (this.isEmail(text)) {
            const exits = _.find(this.state.customEmails, (a) => {
                if (a === text) {
                    return a;
                }
            });
            if (exits === undefined) {
                this.AddCustomEmail(text);
            }
        }
    }

    AddCustomEmail = (data) => {
        let { customEmails } = this.state;
        customEmails.push(data);
        this.setState({
            customEmails: customEmails,
            sendCommentToCustomEmails: true,
            rawInput: '',
            inputError: false,
        });

    };

    isEmail(email) {
        return /.+@.+\.[A-Za-z]+$/.test(email);
    }

    getSelectedAgents(agentOptions, agentIds) {
        const selectedAgentIds = []
        for (let i = 0; i < agentIds.length; i++) {
            const foundOption = agentOptions.find(option => option.value === agentIds[i]);
            if (foundOption !== undefined) {
                selectedAgentIds.push(foundOption);
            }
        }
        return selectedAgentIds

    }

    getSharedAgentList() {
        servicePost('api/v1/library/getSharedAgentList', { playListId: this.props.playlistData._id },this.headers)
            .then(res => {
                if (res.message === 'SUCCESS') {
                    
                    let selectedAgentIds = this.getSelectedAgents(this.props.agentsWithLoginAccessOptions, res.data.agentIds)
                    console.log("selectedAgentIds",selectedAgentIds)
                    this.setState({
                        selectedAgentIds: selectedAgentIds,
                        sharedAgentList: selectedAgentIds
                    })
                }
            })
            .catch(error => console.log(error))
    }

    componentDidMount = async () => {
        // this.librarayList();
        this.getSharedAgentList()
        this.setState({
            feebBackSelectedExpiryLink: {
                label: this.getExpiry(this.props.user.feebBackExpiryLink),
                value: this.getExpiry(this.props.user.feebBackExpiryLink),
            },
            expiry: this.props.user.feebBackExpiryLink
                ? this.props.user.feebBackExpiryLink
                : this.state.expiry,
        });
    };

    getExpiry = (expiry) => {
        switch (expiry) {
            case '1day':
                expiry = '1 Day';
                break;
            case '2day':
                expiry = '2 Day';
                break;
            case '3day':
                expiry = '3 Day';
                break;
            case '7day':
                expiry = '7 Day';
                break;
            case '15day':
                expiry = '15 Day';
                break;
            default:
                expiry = '1 Day';
                break;
        }
        return expiry;
    };

    onChangeLibraryList = async (selectedValue) => {
        if (selectedValue != null) {
            if (selectedValue.length > 0) {

                this.state.playListId = [];
                selectedValue.map((data) => {
                    this.state.playListId.push(data.value);
                });

                if (selectedValue) {
                    this.setState({
                        libraryName: selectedValue && selectedValue[0].label,
                        selectLibrary: selectedValue,
                        libraryNameCon: 'selected',
                        // playListId: playListId,
                        playListError: false,
                        isSuccess: false,
                    });
                } else {
                    this.setState({
                        libraryName: null,
                        isSuccess: true,
                    });
                }
            } else {
                this.setState({
                    selectLibrary: [],
                    libraryName: null,
                    isSuccess: true,
                });
            }
        } else {
            this.setState({
                selectLibrary: [],
                libraryName: null,
                isSuccess: true,
            });
        }
    };

    onChangeAgentsList = async (selectedValue) => {
        this.setState({ selectedAgentIds: selectedValue })

    };

    appendInput() {
        this.setState({
            isShow: true,
            playListError: false,
            libraryalreadyAdded: false,
        });
    }

    removeInput() {
        this.setState({
            isShow: false,
            // playListError: false,
            libraryalreadyAdded: false,
        });
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    newPlayList = () => {
        this.setState({
            playListErrorCustom: false,
            libraryalreadyAdded: false,
        });
    };

    onChangeExpiry = (event, values) => {
        if (event)
            this.setState({
                expiry: event.value,
                feebBackSelectedExpiryLink: event,
            });
    };

    handleAddCustomEmail = (data) => {
        let { customEmails } = this.state;
        customEmails.push(data.text);
        this.setState({
            customEmails: customEmails,
            sendCommentToCustomEmails: true,
            rawInput: '',
            inputError: false,
            isEmail: false
        });

    };

    handleDeleteCustomEmail = (email) => {
        var newCustomEmails = this.state.customEmails;
        _.remove(newCustomEmails, function (item) {
            return item === email.text;
        });
        this.setState({
            customEmails: newCustomEmails,
        });
        if (newCustomEmails.length == 0) {
            this.setState({ sendCommentToCustomEmails: false, isEmail: true });
        }
    };

    handleRawInput = (text) => {
        this.setState({ rawInput: text });
    };

    render() {
        const { feebBackSelectedExpiryLink } = this.state;
        let teamData = { teamName: '', teamLead: '', agents: '', teamId: '' };

        if (this.props.data) {
            teamData = {
                teamName: this.props.data.teamName,
                teamLead: this.props.data.teamLead,
                agents: this.props.data.assignedAgent,
                teamId: this.props.data._id,
            };
        }
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                callcomments: '',
                            }}
                            onSubmit={this.handleSubmit} >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    <>
                                        {!this.state.isSendToCustomMail ?
                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="m-1">
                                                            <Label for="exampleSelect2" className={this.state.isSuccess == true ? 'text-danger' : ''} >Select agents
                                                            </Label>
                                                        </div>

                                                        <div>
                                                            <button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isSendToCustomMail: true,
                                                                        selectedAgentIds: this.state.sharedAgentList,
                                                                    })
                                                                }}
                                                                class="btn btn-link p-0">
                                                                Send to custom email
                                                            </button>

                                                        </div>
                                                    </div>


                                                    <Select
                                                        onChange={this.onChangeAgentsList}
                                                        value={this.state.selectedAgentIds}
                                                        isMulti={true}
                                                        options={this.props.agentsWithLoginAccessOptions}
                                                        classNamePrefix="react-select"
                                                        required
                                                        placeholder={'Select agents with login access'}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            : <>

                                                {/* <FormGroup row>
                                            <Col sm={12}>

                                                <Label for="exampleSelect2" className={this.state.isSuccess == true ? 'text-danger' : ''} >Select an existing Playlist *
                                                </Label>
                                                <Select
                                                    onChange={this.onChangeLibraryList}
                                                    value={this.state.selectLibrary}
                                                    isMulti={true}
                                                    options={this.state.librarayListOpt}
                                                    className={
                                                        this.state.isShow
                                                            ? 'react-select reacttr_select'
                                                            : 'react-select'
                                                    }
                                                    classNamePrefix="react-select"
                                                    required
                                                    placeholder={'Select an existing Playlist'}
                                                    id={this.state.playListError == true ? 'reacttr' : ''}
                                                    isDisabled={this.state.isShow ? true : null}
                                                />
                                            </Col>
                                        </FormGroup> */}
                                                <FormGroup row>
                                                    <Col md={12}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="m-1">
                                                                <Label for="example" className={this.state.isEmail == true ? 'text-danger' : ''}>Send Email to *</Label>
                                                            </div>
                                                            {!(this.props.user.id === '601bba49c89dd203cee971b4' || this.props.user.addedBy === '601bba49c89dd203cee971b4') &&
                                                                <div>
                                                                    <button
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isSendToCustomMail: false,
                                                                                customEmails: []
                                                                            })
                                                                        }}
                                                                        class="btn btn-link p-0">
                                                                        Share with agents
                                                                    </button>

                                                                </div>
                                                            }
                                                        </div>
                                                        <ReactTagsInput
                                                            handleAddCustomEmail={(data) => this.handleAddCustomEmail(data)}
                                                            deleteCustomEmail={(eamil) =>
                                                                this.handleDeleteCustomEmail(eamil)
                                                            }
                                                            handleRawInput={(data) => this.handleRawInput(data)}
                                                        />

                                                    </Col>
                                                </FormGroup>

                                                <FormGroup row>
                                                    <Col md={12}>

                                                        <Label for="exampleSelect">Expiry for Playlist link.</Label>
                                                        <Select
                                                            onChange={this.onChangeExpiry}
                                                            value={feebBackSelectedExpiryLink}
                                                            options={[
                                                                { label: '1 Day', value: '1day' },
                                                                { label: '2 Days', value: '2day' },
                                                                { label: '3 Days', value: '3day' },
                                                                { label: '7 Days', value: '7day' },
                                                                { label: '15 Days', value: '15day' },
                                                            ]}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            placeholder=""
                                                        />

                                                    </Col>
                                                </FormGroup>
                                            </>
                                        }


                                        <FormGroup row>
                                            <Col sm={12}>

                                                <Label for="exampleSelect2" >Comment
                                                </Label>
                                                <FormControls
                                                    type="text"
                                                    name="callcomments"
                                                    autoComplete="false"
                                                    placeholder="Add comments"
                                                />
                                            </Col>
                                        </FormGroup>



                                    </>
                                    {/* } */}

                                    <FormGroup className="row">
                                        <Col sm={6}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit">
                                                {`${!this.state.isSendToCustomMail && this.state.sharedAgentList.length > 0 ? 'Update' : 'Share Playlist'}`}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps)(PlayList);