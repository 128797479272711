import React from 'react';
import { Navigate } from 'react-router-dom';
import withRouter from '../components/withRouter';
import * as FeatherIcon from 'react-feather';
import LibraryList from '../components/LibraryList';
//Non protected Component
//Auth Component
const Login = React.lazy(() => import('../pages/auth/Login'));
const Reset = React.lazy(() => import('../pages/auth/ResetPassword'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Signup = React.lazy(() => import('../pages/auth/Register'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ChangePassword = React.lazy(() => import('../pages/auth/ChangePassword'));
const AuthenticateOAuth = React.lazy(() => import('../pages/auth/AuthenticateOAuth'));
const RouteNotFoundRedirect = React.lazy(() => import('../pages/auth/RouteNotFoundRedirect'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Verification = React.lazy(() => import('../pages/auth/AccountVerification'));
const ReviewFeedback  = React.lazy(() => import('../pages/dashboard/callReview'));
//Protected App Component
//Menu Section
const CallSummary = React.lazy(() => import('../pages/settings/CallSummary'));
const CallAnalyse = React.lazy(() => import('../pages/callAnalysis'));
const Call = React.lazy(() => import('../pages/dashboard/Call'));
const FormBuilder = React.lazy(() => import('../pages/miscellaneous/FormBuilder/formBuilder'));
const SearchPhrases = React.lazy(() => import('../pages/search'));
const agentEvaluation = React.lazy(() => import('../pages/agent/evaluation/agentEvaluation'));
const evaluationSummary = React.lazy(() => import('../pages/agent/evaluation/evaluationSummary'));
const agentCoaching = React.lazy(() => import('../pages/agent/coaching/index.js'));
const Library = React.lazy(() => import("../pages/library/index"));
const LibrLibraryListary = React.lazy(() => import("../components/LibraryList"));
const Reports = React.lazy(() => import("../pages/reports/Reports"));
//Manage Section
const ThemeMoment = React.lazy(() => import('../pages/settings/ThemeMoment'));
const FeedBack = React.lazy(() => import('../pages/settings/FeedBack'));
const AgentList = React.lazy(() => import('../pages/settings/AgentList'));
const TeamList = React.lazy(() => import('../pages/settings/TeamList'));
const QAs = React.lazy(() => import('../pages/settings/QAs'));
const Client = React.lazy(() => import('../pages/manage/clients/Client'));
const Tags = React.lazy(() => import('../pages/settings/Tags'));
const TagsF = React.lazy(()=>import('../pages/manage/tags/Tags'))
//Profile Section
// const SuperAdmin = React.lazy(() => import('../pages/admin/AdminForm'));
const SuperAdmin = React.lazy(() => import('../pages/admin/AdminSettings.js'));

const Billing = React.lazy(() => import('../pages/settings/billing'));
const integrations = React.lazy(() => import('../pages/Integration/index'));
const pipedriveRoute = React.lazy(() => import('../pages/Integration/pipedrive'));
const integrationOAuth = React.lazy(() => import('../pages/dashboard/IntegrationOAuth'));
const CallUpload = React.lazy(() => import('../pages/dashboard/CallUpload'));
const Logs = React.lazy(() => import('../pages/admin/Logs'));
const Overall = React.lazy(() => import('../pages/dashboard/Overall'))
const QA = React.lazy(() => import('../pages/dashboard/Qa/Qa'))
const EmailNotification = React.lazy(() => import('../pages/manage/emailNotification/EmailNotification.js'))
const ChatAnalysis = React.lazy(() => import('../pages/callAnalysis/chatAnalysis'))
const ChatReview = React.lazy(() => import('../pages/dashboard/chatReview/ChatReview'))
const PreviewChat = React.lazy(() => import('../pages/dashboard/chatReview/PreviewChat'))
const QAQM = React.lazy(() => import('../pages/manage/qaqm/QAs'))
const TrialCallUpload = React.lazy(() => import('../pages/callUpload/TrialCallUpload'))
const AgentDashboard = React.lazy(() => import('../pages/dashboard/agentDashboard/AgentDashboard'))
const Performance = React.lazy(() => import('../pages/dashboard/agentDashboard/Performance'))
const Volume = React.lazy(() => import('../pages/dashboard/agentDashboard/Volume'))
const Evaluations = React.lazy(() => import('../pages/dashboard/agentDashboard/evaluations/Evaluations'))
const LeaderboardReport = React.lazy(() => import('../pages/reports/LeaderboardReport'))
const AISettings = React.lazy(() => import('../pages/settings/aiSettings'))
const RegistrationSteps = React.lazy(() => import('../pages/registration/RegistrationSteps.js'))
const SignUp = React.lazy(()=> import("../pages/auth/signup/Signup.js"));
const UploadCall = React.lazy(() => import('../pages/manage/callUpload/CallUpload.js'))
const CallReview = React.lazy(()=> import('../pages/callReview/CallReview.js'))
const Team = React.lazy(()=> import("../pages/manage/team/Team.js"));
const WordcloudReport = React.lazy(() => import("../pages/reports/wordCloudReport/index.js"))
const Home = React.lazy(()=> import("../pages/auth/Home.js"))
const Calibrations = React.lazy(() => import('../pages/calibrations/CalibrationDashboard.js'))
/**
 * Auth Route Start
 */

const EmailNotificationRoute = {
    path: '/manage/email-notification',
    name: 'Email Notifications',
    component: EmailNotification,
    roles: ['admin', 'QA', 'QM','TL'],
    plan:['proPlan']
}

const loginRoute = {
    path: '/login',
    name: 'Login',
    component: withRouter(Login),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const ResetPasswordRoute = {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: withRouter(Reset),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const LogoutRoute = {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']  
}
const SignupRoute = {
    path: '/signup',
    name: 'SignUp',
    // component: Signup,
    component: SignUp,
    roles: ['admin', 'QA', 'QM', 'Client','agent','TL'],
    plan:['basePlan','proPlan']
}
const ConfirmRoute = {
    path: '/confirm',
    name: 'Confirm',
    component: Confirm,
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const ForgetPasswordRoute = {
    path: '/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const ChangePasswordRoute = {
    path: '/update-password',
    name: 'Change Password',
    component: withRouter(ChangePassword),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const VerificationRoute = {
    path: '/verify/:email',
    name: 'Verification',
    component: withRouter(Verification),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const AuthenticateOAuthRoute = {
    path: '/authenticate/custom',
    name: 'Authenticate OAuth',
    component: withRouter(AuthenticateOAuth),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const PreviewFeedbackRoute = {
    path: '/preview/comment/:callId',
    name: 'Preview Comment',
    // component: withRouter(ReviewFeedback ),
    component: withRouter(CallReview),
    roles: ['admin', 'QA', 'QM','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const PreviewChatRoute = {
    path: '/preview/chat/:chatId',
    name: 'Preview Chat',
    component: PreviewChat,
    roles: ['admin', 'QA', 'QM','agent','TL'],
    plan:['basePlan','proPlan']
}
const TrialCallUploadRoute = {
    path: '/sneak-peek',
    name: 'Call Upload',
    component: TrialCallUpload,
    roles: ['admin', 'QA', 'QM', 'TL'],
    plan:['basePlan','proPlan']
}
// const ThemeMomentRoute = {
//     path: '/themes',
//     name: 'Theme Moment',
//     component: withRouter(ThemeMoment),
//     roles: ['admin', 'QA', 'QM', 'Client']
// }
const LibraryRoute = {
    path: '/playlist/:sharedPlaylistId',
    name: 'Theme Moment',
    component: withRouter(Library),
    roles: ['admin', 'QA', 'QM', 'Client','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const integrationPipedriveRoute = {
    path: '/integration/pipedrive',
    component: withRouter(pipedriveRoute),
    roles: ['admin', 'QM', 'QA', 'TL'],
    plan:['basePlan','proPlan']
};
const CallUploadRoute = {
    path: '/upload-calls',
    name:"Upload Calls",
    component: UploadCall,
    // component: withRouter(CallUpload),
    roles: ['admin','QA','QM','TL'],
    plan:['proPlan']
};

const UploadCallRoute = {
    path: '/call-upload',
    name:"Upload Calls",
    component: UploadCall,
    roles: ['admin','QA','QM', 'TL'],
    plan:['basePlan']
};

const noPageFound = {
    path: '/*',
    name: '404',
    component: withRouter(RouteNotFoundRedirect),
    plan:['basePlan','proPlan'],
    roles: ['admin', 'QM', 'QA', 'client','agent','TL']
};
const FormBuilderRoute = {
    path: '/form-generate',
    name: 'Form-Builder',
    component: withRouter(FormBuilder),
    roles: ['admin', 'QM', 'QA', 'TL'],
    plan:['basePlan','proPlan']
};
/**
 * Auth Route End
 */
/**
 * App Route Start
 */
const RootRoute = {
    path: '/',
    exact: true,
    component: Home,
    plan:['basePlan','proPlan'],
    roles: ['admin', 'QM', 'QA', 'client','agent','TL']
};



const DashboardRoute = {
    path: '/dashboard/overall',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: Overall,
    roles: ['admin', 'QA', 'QM','TL'],
    plan:['proPlan']
};
const QaDashboardRoute = {
    path: '/dashboard/qa',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: QA,
    roles: ['admin', 'QA', 'QM','TL'],
    plan:['basePlan','proPlan']
};
const AgentPerformanceRoute={
    path: '/dashboard/performance',
    name: 'Performance',
    component: Performance,
    roles: ['admin', 'QM', 'QA','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const AgentVolumeRoute={
    path: '/dashboard/volume',
    name: 'Volume',
    component: Volume,
    roles: ['admin', 'QM', 'QA','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const AgentEvaluationsRoute={
    path: '/dashboard/evaluations',
    name: 'Evaluations',
    component: Evaluations,
    roles: ['admin', 'QM', 'QA','agent', 'TL'],
    plan:['basePlan','proPlan']
}
const AgentDashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: AgentDashboard,
    roles: ['agent'],
    subRoutes: [
        AgentPerformanceRoute,
        AgentVolumeRoute,
        AgentEvaluationsRoute
    ],
    plan:['basePlan','proPlan']
};

const CallSummaryRoute = {
    path: '/call-summary',
    name: 'Call Summary',
    icon: FeatherIcon.PhoneCall,
    component: withRouter(CallSummary),
    roles: ['admin', 'QA', 'QM','TL'],
    plan:['proPlan'],
    communicationType : ['call']
};
const callRoute = {
    path: '/analyse/calls/:callId',
    name: 'Call',
    // component: withRouter(Call),
    component: withRouter(CallReview),
    roles: ['admin', 'QM', 'QA', 'TL','agent'],
    plan:['basePlan','proPlan']
};
const ChatReviewRoute = {
    path: '/analyse/chat/:chatId',
    name: 'ChatReview',
    component: ChatReview,
    roles: ['admin', 'QM', 'QA','TL','agent'],
    plan:['basePlan','proPlan']
};
const CallAnalyseRoute = {
    path: '/analyse/call/',
    name: 'Call Analysis',
    icon: FeatherIcon.BarChart2,
    component: withRouter(CallAnalyse),
    roles: ['admin', 'QA', 'QM','agent','TL'],
    plan:['basePlan','proPlan'],
    communicationType : ["call"]
};
const CallChatAnalyseRoute = {
    path: '/analyse/call/',
    name: 'Call/Chat Analysis',
    icon: FeatherIcon.BarChart2,
    component: withRouter(CallAnalyse),
    roles: ['admin', 'QA', 'QM','agent','TL'],
    plan:['basePlan','proPlan']
};
const ChatAnalysisRoute = {
    path: '/analyse/chat/',
    name: 'Chat Analysis',
    icon: FeatherIcon.BarChart2,
    roles: ['admin', 'QA', 'QM','agent','TL'],
    component: ChatAnalysis,
    plan:['basePlan','proPlan'],
    communicationType : ["chat"]
}
const integrationRoute = {
    path: '/integration',
    name: 'Integrations',
    icon: FeatherIcon.RefreshCw,
    component: withRouter(integrations),
    roles: ['admin', 'QM'],
    roles: ['admin', 'QM'],
    plan:['basePlan','proPlan']
};
const integrationRouteForBullhorn = {
    path: '/integration&state=bullhorn',
    name: 'Integrations',
    icon: FeatherIcon.RefreshCw,
    component: withRouter(integrations),
    roles: ['admin', 'QM', 'QA', 'TL'],
    plan:['basePlan','proPlan']
};
const LibraryListRoute = {
    path: '/library/',
    name: 'Library',
    component: withRouter(LibraryList),
    icon: FeatherIcon.Book,
    roles: ['admin', 'QM', 'QA','agent','TL'],
    plan:['basePlan','proPlan']
};
const ReportsRoute = {
    path: '/reports/report',
    name: 'Team/Agent Reports',
    // icon: FeatherIcon.FileText,
    component: withRouter(Reports),
    roles: ['admin', 'QM', 'QA', 'client','TL'],
    plan:['basePlan','proPlan'],
    communicationType : ['call', 'chat']
};
const ReportsRouteForClient = {
    path: '/report',
    name: 'Reports',
    icon: FeatherIcon.FileText,
    component: withRouter(Reports),
    roles: ['client'],
    plan:['basePlan','proPlan']
};
const LeaderboardReportRoute = {
    path: '/reports/leaderboard',
    name: 'Leaderboard',
    // icon: FeatherIcon.FileText,
    component: LeaderboardReport,
    roles: ['admin', 'QM', 'QA','TL'],
    plan:['basePlan','proPlan']
};

const WordcloudRoute = {
    path: '/reports/wordcloud',
    name: 'Wordcloud',
    // icon: FeatherIcon.FileText,
    component: WordcloudReport,
    roles: ['admin', 'QM', 'QA', 'TL'],
    plan:['proPlan'],
    communicationType : ['call']
};

const AgentEvaluationRoute = {
    path: '/evaluation',
    name: 'Evaluation/Coaching',
    icon: FeatherIcon.Activity,
    component: withRouter(agentEvaluation),
    roles: ['admin', 'QM', 'QA','TL'],
    plan:['basePlan','proPlan']
};
const AgentCoachingRoute = {
    path: '/coaching',
    name: 'Coaching',
    icon: FeatherIcon.Activity,
    component: withRouter(agentCoaching),
    roles: ['admin', 'QM', 'QA', 'client','TL'],
    plan:['basePlan','proPlan']
};
const searchPhrasesRoute = {
    path: '/search',
    name: 'Search Phrases',
    component: SearchPhrases,
    icon: FeatherIcon.Search,
    roles: ['admin', 'QM', 'QA','TL'],
    plan:['proPlan'],
    communicationType : ['call']
};
// Setting Route
const ThemeMomentRoute = {
    name: 'Themes',
    path: '/manage/themes',
    component: withRouter(ThemeMoment),
    roles: ['admin', 'QM'],
    plan:['proPlan']
}

const AISettingRoute = {
    name: 'GenAI Settings',
    path: '/manage/gen-ai-settings',
    component: AISettings,
    roles: ['admin', 'QM'],
    plan:['proPlan']
}
const FeedbackRoute = {
    name: 'Feedback Forms',
    path: '/manage/feedbacks',
    component: withRouter(FeedBack),
    roles: ['admin', 'QM', 'QA','TL'],
    route: "PrivateRoute",
    plan:['basePlan','proPlan']
}
const AgentListRoute = {
    name: 'Agents',
    path: '/manage/agents',
    component: withRouter(AgentList),
    roles: ['admin', 'QM'],
    plan:['basePlan','proPlan']
}
const TeamListRoute = {
    name: 'Teams',
    path: '/manage/teams',
    // component: withRouter(TeamList),
    component: withRouter(Team),
    roles: ['admin', 'QM'],
    plan:['basePlan','proPlan']
}
// const QARoute = {
//     name: 'QAs',
//     path: '/qa',
//     component: withRouter(QAs),
//     roles: ['admin', 'QM'],
// }
const QAQMRoute = {
    name: 'QAs',
    path: '/qa',
    component: QAQM,
    roles: ['admin', 'QM'],
    plan:['basePlan','proPlan']
}
const ClientRoute = {
    path: '/client',
    name: 'Clients',
    component: withRouter(Client),
    roles: ['admin', 'QM'],
    plan:['proPlan']
}
// const TagsRoute = {
//     path: '/tags',
//     name: 'Tags',
//     component: withRouter(Tags),
//     roles: ['admin', 'QM'],
// }
const TagsFRoute = {
    path: '/tags',
    name: 'Tags',
    component: TagsF,
    roles: ['admin', 'QM'],
    plan:['basePlan','proPlan']
}
const superAdminRoute = {
    path: '/profile_settings',
    name: 'Profile Settings',
    icon: FeatherIcon.Settings,
    component: withRouter(SuperAdmin),
    roles: ['admin','QA','QM','agent','TL','client'],
    plan:['basePlan','proPlan']
};
const billingRoute = {
    path: '/billing',
    name: 'Billing',
    icon: FeatherIcon.Settings,
    component: withRouter(Billing),
    plan:['basePlan','proPlan'],
    roles: ['admin']
};
const AgentEvaluationSummaryRoute = {
    path: '/agent-evaluation',
    name: 'Reports',
    icon: FeatherIcon.FileText,
    component: withRouter(evaluationSummary),
    roles:['agent'],
    plan:['basePlan','proPlan'],
    communicationType : ['call','chat']
};

const AgentEvaluationAuthRoute = {
    path: '/agent-evaluation',
    name: 'Reports',
    icon: FeatherIcon.FileText,
    component: withRouter(evaluationSummary),
    roles: ['agent','admin','QA','QM','TL'],
    plan: ['basePlan','proPlan'],
    communicationType : ['call']
};

const logsRoute = {
    path: '/logs',
    name: 'logs',
    component: withRouter(Logs),
    roles: ['admin', 'QM', 'QA'],
    plan:['basePlan','proPlan']
};
const integrationOAuthRoute = {
    path: '/integration/gotoconnect',
    name: '',
    component: withRouter(integrationOAuth),
    roles: ['admin'],
    plan:['basePlan','proPlan']
};
const RegistrationStepsRoute = {
    path: '/registration-step',
    name: '',
    component: RegistrationSteps,
    roles: ['admin'],
    plan:['basePlan','proPlan']
};
const reportsRoute = {
    path: '/report',
    name: 'Report',
    icon: FeatherIcon.FileText,
    roles:['admin', 'QM', 'QA', 'TL'],
    children: [
        ReportsRoute,
        LeaderboardReportRoute,
        WordcloudRoute
    ],
    plan:['basePlan','proPlan'],
    // communicationType : ["call"]
};
const settingRoute = {
    path: '/manage',
    name: 'Manage',
    icon: FeatherIcon.UserPlus,
    roles:['admin', 'QM', 'QA', 'TL'],
    children: [
        ThemeMomentRoute,
        AgentListRoute,
        TeamListRoute,
        // QARoute,
        QAQMRoute,
        EmailNotificationRoute,
        // TagsRoute,
        TagsFRoute,
        FeedbackRoute,
        ClientRoute,
        CallUploadRoute,
        UploadCallRoute,
        AISettingRoute,
    ],
    plan:['basePlan','proPlan']
};

const calibrationsRoute = {
    path: '/calibrations',
    name: 'Calibration',
    icon: FeatherIcon.GitCommit,
    component: withRouter(Calibrations),
    roles: ['admin', 'QM', 'QA', 'TL', 'agent'],
    plan: ['basePlan','proPlan']
};

/**
 * App Route End
 */
const commonRoutes=[
    QaDashboardRoute,
    AgentDashboardRoute,
    CallSummaryRoute,
    CallAnalyseRoute,
    ChatAnalysisRoute,
    // CallChatAnalyseRoute,
    searchPhrasesRoute,
    AgentEvaluationRoute,
    calibrationsRoute,
    LibraryListRoute,
    // ReportsRoute,
    AgentEvaluationSummaryRoute,
    ReportsRouteForClient
]
const leftSideMenuRoute = [
    ...commonRoutes,
    reportsRoute,
    settingRoute,
]

const authRoutes = [
    loginRoute,
    ResetPasswordRoute,
    LogoutRoute,
    SignupRoute,
    ConfirmRoute,
    ForgetPasswordRoute,
    ChangePasswordRoute,
    VerificationRoute,
    AuthenticateOAuthRoute,
    PreviewFeedbackRoute,
    LibraryRoute,
    integrationPipedriveRoute,
    CallUploadRoute,
    noPageFound,
    FormBuilderRoute,
    PreviewChatRoute,
    TrialCallUploadRoute,
]
const appRoutes = [
    RegistrationStepsRoute,
    ChatReviewRoute,
    ChatAnalysisRoute,
    searchPhrasesRoute,
    QaDashboardRoute,
    // AgentDashboardRoute,
    // AgentPerformanceRoute,
    // AgentVolumeRoute,
    // AgentEvaluationsRoute,
    EmailNotificationRoute,
    RootRoute,
    callRoute,
    DashboardRoute,
    ...commonRoutes,
    FeedbackRoute,
    integrationRoute,
    integrationRouteForBullhorn,
    AgentCoachingRoute,
    ThemeMomentRoute,
    AISettingRoute,
    AgentListRoute,
    TeamListRoute,
    // QARoute,
    QAQMRoute,
    ClientRoute,
    // TagsRoute,
    TagsFRoute,
    superAdminRoute,
    billingRoute,
    // AgentEvaluationSummaryRoute,
    AgentEvaluationAuthRoute,
    logsRoute,
    integrationOAuthRoute,
    ReportsRoute,
    LeaderboardReportRoute,
    WordcloudRoute,
    UploadCallRoute,
    calibrationsRoute
]
const authProtectedRoutes = [...appRoutes];
const publicRoutes = [...authRoutes];
export { authProtectedRoutes, publicRoutes, leftSideMenuRoute };
