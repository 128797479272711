import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as FeatherIcon from 'react-feather'
import { connect } from 'react-redux';

// const Action = ({ rowID, rowEdit }) => {
class AgentAction extends Component {
    constructor(props) {
        super(props);
        this.superheroElement = React.createRef();
        this.state = {
            isModalOpen: false,
            isUserAllowedToDeleteCalls: props.user.isTl ? props.selectedLibraryDetails.createdBy === props.user.id ? true : props.user.privilege?.teamLeader?.canTlDeleteCallFromAllPlaylists : true
        };
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    render() {
        var rowID = '';
        if (this.props.rowID) {
            rowID = this.props.rowID;
        }
        return (
            <table class="actionTbl">
                {this.props.type == 'library' && (
                    <React.Fragment>
                        <React.Fragment>
                            <Modal
                                isOpen={this.state.isModalOpen}
                                toggle={this.toggle}
                                className={'modal-dialog-centered'}
                                // size={this.state.size}
                                backdrop="static">
                                <ModalHeader
                                    cssModule={{ 'modal-title': '' }}
                                    toggle={this.toggle}>
                                    Alert
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col>
                                            <p style={{ fontSize: '16px', textAlign: 'left' }}>
                                                Are you sure to remove this {this.props.channelType} from playlist?
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col></Col>
                                        <Col style={{ width: 'auto' }}>
                                            <Button
                                                color="danger"
                                                className="ms-3"
                                                style={{ float: 'right' }}
                                                onClick={() => this.props.deleteRow(this.props)}>
                                                Delete
                                            </Button>
                                            <Button
                                                className="ms-3"
                                                color="secondary"
                                                style={{ float: 'right' }}
                                                onClick={this.toggle}>
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        </React.Fragment>
                        <div className='row'>
                            <div className='col'>
                                <a
                                    style={{ color: '#4B4B5A' }}
                                    href={this.props.channelType === 'call' ? this.props.isRoleAgent ? `/preview/comment/${this.props.rowID}` : `/analyse/calls/${this.props.rowID}` : this.props.isRoleAgent ? `/preview/chat/${this.props.rowID}` : `/analyse/chat/${this.props.rowID}`}
                                    // title="View"
                                    id={this.props.rowIndex + 'view'}
                                    target="_blank">
                                    <FeatherIcon.Eye size={18}></FeatherIcon.Eye>
                                    {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        // class="ms-2"
                                        style={{ cursor: 'pointer', float: 'right' }}>
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg> */}
                                </a>
                                <UncontrolledTooltip position="top" target={this.props.rowIndex + 'view'}>
                                    View
                                </UncontrolledTooltip>
                            </div>
                            {!this.props.isRoleAgent && this.state.isUserAllowedToDeleteCalls &&
                                <div className='col'>
                                    {' '}
                                    <span id={this.props.rowIndex + 'delete'}>
                                        {' '}
                                        <i onClick={this.toggle} title="Delete" id="delete" className="uil uil-trash-alt"></i>{' '}
                                    </span>
                                    <UncontrolledTooltip position="top" target={this.props.rowIndex + 'delete'}>
                                        Delete
                                    </UncontrolledTooltip>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )}
                {this.props.type != 'library' && (
                    <React.Fragment>
                        <tr>
                            <td>
                                <span onClick={() => this.props.rowEdit(this.props.rowID)}>
                                    <i className="uil uil-edit"></i>
                                </span>
                            </td>
                            <td>
                                {' '}
                                <span id={this.props.rowIndex}>
                                    {' '}
                                    <i className="uil uil-trash-alt"></i>{' '}
                                </span>
                            </td>
                        </tr>
                    </React.Fragment>
                )}
            </table>
        );
    }
}

const mapStateToProps = (state) => {
    return state.Auth;
};

export default connect(mapStateToProps)(AgentAction);
