// @flow
import { all, call, fork, takeEvery } from 'redux-saga/effects';

import {
    SET_DATE_RANGE, PAST_DATE_RANGE, DATE_SELECTION_FOR_REPORTS
} from './constants';



import {
    setDateRange as setDateRangeAction, setPastDateRange as setPastDateRangeAction, setDateSelectionForReports as setDateSelectionForReportsAction
} from './actions';


function* changeDateRange({ payload: { from, to } }) {
    // alert("HE")

    yield call(setDateRangeAction, (from, to))
}

function* changePastDateRange({ payload: { from, to } }) {
    // alert("HE")
    console.log(from, to);

    yield call(setPastDateRangeAction, (from, to))
}

function* changeDateSelectionForReports({ payload: { type } }) {
    // alert("HE")

    yield call(setDateSelectionForReportsAction, (type))
}


export function* watchChangeDateRange() {
    yield takeEvery(SET_DATE_RANGE, changeDateRange);
}

export function* watchChangePastDateRange() {
    yield takeEvery(PAST_DATE_RANGE, changePastDateRange);
}

export function* watchChangeDateSelectionForReports() {
    yield takeEvery(DATE_SELECTION_FOR_REPORTS, changeDateSelectionForReports);
}



function* optionsSaga() {
    yield all([fork(watchChangeDateRange, watchChangePastDateRange, watchChangeDateSelectionForReports)]);
}


export default optionsSaga;