import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import {Tooltip} from 'react-tippy'
import { connect } from 'react-redux';
import Loader from './Loader';
import _ from 'lodash';
import PageTitle from './PageTitle';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { toast } from 'react-toastify';
import { servicePost, serviceGet } from './../helpers/api';
import Moment from 'react-moment';
import AgentAction from './../pages/settings/agentAction';
import AddModal from './../pages/settings/AddModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingBar from 'react-top-loading-bar';
import InfoIcon from '../components/InfoIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import * as FeatherIcon from 'react-feather'
import PlayList from '../pages/forms/PlayList';
import 'react-tooltip/dist/react-tooltip.css';
import { capitalize } from '../helpers/common';
import { color } from 'd3';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="pagination-entries">
        <label className="d-inline me-1">Show</label>
        <Input
            type="select"
            name="select"
            id="no-entries"
            className="custom-select custom-select-sm d-inline col-2"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>;
            })}
        </Input>
        <label className="d-inline ms-1">entries</label>
    </div>
);

const TableWithSearch = (props) => {

    const records = props.records;

    const editDataRow = (rowId) => {
        // alert(rowId)
    };

    const deleteTableRow = async (data) => {
        props.loadingBarRef.current.continuousStart()
        await servicePost(
            'api/v1/library/removeCallLibrary',
            {
                callId: data.channelType === 'call' ? data.rowID : null,
                chatId: data.channelType === 'chat' ? data.rowID : null,
                libraryId: data.libID,
                playListId : data.selectedLibraryDetails._id
            },
            {
                'Content-Type': 'application/json',
                Authorization: 'JWT ' + props.tokenUser,
            }
        )
            .then((res) => {
                props.loadingBarRef.current.complete()
                if (res.status == 1) {
                    let libraryId = res.data._id;
                    let libraryName = res.data.libraryName;
                    toast.success(`${capitalize(data.channelType)} removed successfully`, { bodyClassName: 'custom-toast', pauseOnHover: true });
                    props.reloadTable({ libraryId, libraryName });
                }
            })
            .catch((err) => {
                props.loadingBarRef.current.complete()
                toast.error(err.response.data.error, { bodyClassName: 'custom-toast', pauseOnHover: true });
            });
    };

    const columns = [
        {
            style: { width: '20%' },
            dataField: 'time',
            text: 'Date/Time',
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <React.Fragment>
                    <div class="rex">
                        <div style={{ fontSize: '14px' }}>
                            <Moment tz={props.userTimeZone.timezone} format="LLL">
                                {row.time}
                            </Moment>
                        </div>
                    </div>
                </React.Fragment>
            ),
            sortFunc: (a, b, order, dataField) => {
                if (order === 'asc') {
                    return new Date(b) - new Date(a);
                }

                return new Date(a) - new Date(b); // desc
            },
        },
        {
            dataField: 'duration',
            text: 'Duration',
            sort: true,
            style: { width: '20%' },
            sortFunc: (a, b, order, dataField) => {
                var a = a.split(':');
                var b = b.split(':');

                var a = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
                var b = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];

                if (order === 'asc') {
                    return a - b;
                }
                return b - a;
            },
            formatter:(cell)=>{
                return <div>{cell?cell:'-'}</div>
            }
        },
        {
            dataField: 'name',
            text: 'Agent',
            sort: true,
            style: { width: '20%' },
        },
        {
            dataField: 'channelType',
            text: 'Type',
            style: { width: '10%' },
            formatter:(cell)=>{
                return <div>{capitalize(cell)}</div>
            }
        },
        {
            dataField: 'comment',
            text: 'Comments',
            style: { width: '20%' },
            formatter: (cell, row, index) => {
                return (
                    <React.Fragment>
                        <div class={!row.comment ? 'ms-5' : ''}>
                            <span data-tooltip-id={`comments-${index}`} data-tooltip-html={`<div style="max-width:150px">${row.comment}</div>`}>
                                {row.comment ?
                                    _.truncate(row.comment, {
                                        length: 47,
                                        separator: '...',
                                    })
                                    : '-'}
                            </span>
                            {row.comment && (
                                <ReactTooltip
                                    className="evaluation-tooltipLibrary"
                                    id={`comments-${index}`}
                                    place="top"
                                    type="dark"
                                    effect="float"
                                />
                            )}
                        </div>
                    </React.Fragment>
                );
            },
        },
        {
            dataField: 'libraryId',
            text: 'Action',
            sort: false,
            style: { width: '10%' },
            formatter: (cell, row, rowIndex) => (
                <React.Fragment>
                    <AgentAction
                        rowIndex={'btn-' + rowIndex}
                        rowID={row.channelId}
                        libID={row.libraryId}
                        rowEdit={editDataRow}
                        deleteMesg={`Remove this from library`}
                        deleteRow={deleteTableRow}
                        type={'library'}
                        isRoleAgent={props.isRoleAgent}
                        channelType={row.channelType}
                        selectedLibraryDetails={props.selectedLibraryDetails}
                    />
                </React.Fragment>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'asc',
        },
    ];
    return (
        <Card>
            <CardBody className="pt-0 px-0">
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={records}
                    columns={columns}
                >
                    {(props) => (
                        <React.Fragment>
                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageRenderer: sizePerPageRenderer,
                                    sizePerPageList: [
                                        { text: '5', value: 5 },
                                        { text: '10', value: 10 },
                                        { text: '25', value: 25 },
                                    ],
                                })}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

class LibraryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            libraryData: [],
            callRecords: [],
            loadTable: true,
            selectedLibrary: '',
            selectedLibraryDetails: {},
            totalCalls: 0,
            activeRow: '',
            deletePopover: false,
            targetBtn: '',
            isModalOpen: false,
            modalMessage: '',
            selected: '',
            isRoleAgent: this.props.user.role.includes('agent'),
            isSharePlaylistModalOpen: false,
            playlistToShareData: {},
            agentsWithLoginAccessOptions: []
        };

        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
        this.superheroElement = React.createRef();

        this.getCallDetail = this.getCallDetail.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closePopover = this.closePopover.bind(this);
        this.ref = React.createRef();
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    sharePlaylistModalToggle = () => {
        this.setState({
            isSharePlaylistModalOpen: !this.state.isSharePlaylistModalOpen
        })
    }

    playlistToShare = (data) => {
        this.setState({ playlistToShareData: data })
        this.sharePlaylistModalToggle()
    }

    getAgentsWithLoginAccess = async () => {
        try {
            let agentsWithLoginAccess = await serviceGet('agent/getAgentsWithLoginAccess',{headers:this.headers})
            const agentsWithLoginAccessOptions = []
            for (let i = 0; i < agentsWithLoginAccess.data.length; i++) {
                let agentWithLoginAccess = agentsWithLoginAccess.data[i]
                agentsWithLoginAccessOptions.push({
                    label: agentWithLoginAccess.name,
                    value: agentWithLoginAccess._id,
                    email: agentWithLoginAccess.email
                })
            }
            this.setState({ agentsWithLoginAccessOptions })
        }
        catch (error) {
            console.log(error);
        }
    }

    getAllLibrary = async () => {
        // this.ref.current.continuousStart();
        await servicePost('api/v1/library/getLibrary', { agentId: this.state.isRoleAgent && this.props.user.id }, this.headers)
            .then((res) => {
                // this.ref.current.complete();
                if (res.status == 1) {
                    if (res.data.length != 0) {
                        this.setState({
                            libraryData: res.data,
                            loading: false,
                        });

                        if (res.data[0].callId.length != 0 || res.data[0].chats.length) {
                            this.getCallDetail(res.data[0]);
                        } else {
                            this.setState({
                                callRecords: [],
                                selectedLibrary: res.data[0].libraryName,
                                loadTable: false,
                            });
                        }
                    } else {
                        this.setState({
                            loading: false,
                            loadTable: false,
                            selectedLibrary: null,
                            libraryData: [],
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    componentDidMount() {
        // try {
        //     this.getAllLibrary();
        // } catch (err) {
        //     console.log(err);
        // }
        Promise.all([this.getAllLibrary(), this.getAgentsWithLoginAccess()]).then(res => {

        }).catch(error => console.log(error))
    }


    getCallDetail = async (data) => {
        if (data) {
            if (data.length != 0) {
                this.setState({
                    loadTable: true,
                    selectedLibrary: data.libraryName,
                    selectedLibraryDetails: data
                });
                await servicePost(
                    'api/v1/library/getLibraryCall',
                    {
                        callId: data.callId,
                        libraryId: data._id ? data._id : data.libraryId,
                    },
                    this.headers
                ).then((res) => {
                    if (res.status == 1) {
                        this.setState({
                            callRecords: res.data.length != 0 ? res.data : [],
                            loadTable: false,
                            totalCalls: res.data.length != 0 ? res.data.length : 0,
                            selectedLibrary: data.libraryName,
                        });
                    }
                });
            }
        } else {
            this.setState({
                callRecords: [],
            });
        }
    };

    reloadData = (data) => {
        this.getCallDetail(data);
    };

    isModal = () => {
        this.getAllLibrary();
    };

    closePopover = (targetVal) => {
        alert(targetVal);
        this.setState({
            deletePopover: true,
            targetBtn: targetVal,
        });
    };

    deleteRow = async (rowId) => {
        this.ref.current.continuousStart()
        await servicePost(
            'api/v1/library/deleteLibrary',
            {
                libraryId: rowId,
            },
            this.headers
        ).then((res) => {
            this.ref.current.complete()
            if (res.status == 1) {
                toast.success('Playlist deleted successfully', { bodyClassName: 'custom-toast', pauseOnHover: false });
                this.toggle();
                this.getAllLibrary();
                this.getCallDetail();
            }
        }).catch((error)=>{
            this.ref.current.complete()
        });
    };

   
    render() {
        let records = this.state.libraryData;
        if (this.state.loading) {
            return (
                <React.Fragment>
                    {/* Browser top loader start */}
                    <LoadingBar color="#5369f8" ref={this.ref} shadow={true} transitionTime={500} />
                    {/* Browser top loader end */}
                    <Row>
                        <Col md={12} className="page-title mt-2">
                            <Loader />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        const tooltipContentInShareModal = (
            <div style={{ maxWidth: '350px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              {this.state.playlistToShareData?.libraryName}
            </div>
          );

        
        return (
            <React.Fragment>
                {/* Browser top loader start */}
                <LoadingBar color="#5369f8" ref={this.ref} shadow={true} transitionTime={500} />
                {/* Browser top loader end */}
                <>
                    <React.Fragment>
                        <Modal
                            isOpen={this.state.isModalOpen}
                            toggle={this.toggle}
                            className={'modal-dialog-centered'}
                            // size={this.state.size}
                            backdrop="static">
                            <ModalHeader
                                cssModule={{ 'modal-title': '' }}
                                toggle={this.toggle}>
                                Alert
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: '16px', textAlign: 'left' }}>{this.state.modalMessage}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col style={{ width: 'auto' }}>
                                        <Button
                                            color="danger"
                                            className="ms-3"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                this.deleteRow(this.state.selected);
                                            }}>
                                            Delete
                                        </Button>
                                        <Button
                                            className="ms-3"
                                            color="secondary"
                                            style={{ float: 'right' }}
                                            onClick={this.toggle}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </React.Fragment>
                </>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[{ label: 'Library', path: '/Library', active: true }]}
                            title={'Library'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <div className="email-container bg-transparent">
                            <Card
                                className="inbox-leftbar"
                                style={{
                                    paddingBottom: '56px',
                                    margin: 'auto',
                                    paddingLeft: '8px',
                                    paddingTop: '8px',
                                    paddingRight: '8px',
                                }}>
                                {records.length != 0 && (
                                    <React.Fragment>
                                        <PerfectScrollbar
                                            style={{ paddingTop: '15px', paddingLeft: '15px', paddingRight: '5px' }}
                                            options={{ suppressScrollX: 'false' }}>
                                            <ul className="libraryList" style={{ height: 'auto' }}>
                                                {records.map((val, index, obj) => {
                                                    let isUserAllowedToDeleteCalls = this.props.user.isTl ? val.createdBy === this.props.user.id ? true : this.props.user.privilege?.teamLeader?.canTlDeleteCallFromAllPlaylists : true
                                                    const tooltipContentText = (
                                                        <div style={{ maxWidth: '350px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                            {val.libraryName}
                                                        </div>
                                                    );
                                                    return (
                                                        <li
                                                            className={`${this.state.selectedLibrary == val.libraryName
                                                                ? 'active'
                                                                : ''
                                                                }`}>
                                                            <Row>
                                                                <Col md={'8'}>
                                                                <a
                                                                        href="javascript:void(0)"
                                                                        className={`list-group-item border-0 `}
                                                                        onClick={this.getCallDetail.bind(this, val)}
                                                                        style={{ float: 'left'}}>
                                                                            <div style={{ width:'fit-content'}}>
                                                                            <Tooltip
                                                                              html={tooltipContentText}
                                                                              position="top-end"
                                                                              arrow={true}
                                                                              style={{ padding: 0 }}
                                                                              >
                                                                              {val.libraryName.length > 20 ? val.libraryName.substring(0, 15) + "..." : val.libraryName}
                                                                            </Tooltip>
                                                                            </div>
                                                                    </a>
                                                                </Col>
                                                                {!this.state.isRoleAgent &&
                                                                    <Col md={4} className='pe-4'>
                                                                        <div
                                                                            style={{ float: 'right' }}
                                                                            id={`btn` + index}
                                                                            className="libraryDelete">
                                                                            <FeatherIcon.Share2 onClick={() => this.playlistToShare(val)} size={16} className='me-2 cursor-pointer'></FeatherIcon.Share2>
                                                                            {isUserAllowedToDeleteCalls &&
                                                                                <FeatherIcon.Trash2 size={16} className='cursor-pointer' onClick={() => {
                                                                                    var message =
                                                                                        val.callId.length > 1
                                                                                            ? `This playlist have
                                                                                                    ${val.callId.length}
                                                                                                    calls. Are your sure
                                                                                                    to delete this
                                                                                                    playlist ?`
                                                                                            : val.callId.length == 1
                                                                                                ? `This playlist have
                                                                                                    ${val.callId.length}
                                                                                                    calls. Are your sure
                                                                                                    to delete this
                                                                                                    playlist ?`
                                                                                                : `Are you sure to delete this playlist`;
                                                                                    this.setState({
                                                                                        modalMessage: message,
                                                                                        selected: val._id,
                                                                                    });
                                                                                    this.toggle(message, val._id);
                                                                                }} />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </PerfectScrollbar>
                                    </React.Fragment>
                                )}
                                {!this.state.isRoleAgent &&
                                    <div
                                        style={{
                                            width: this.state.libraryData.length > 0 ? '124px' : '',
                                            paddingLeft: this.state.libraryData.length > 0 ? '7px' : '',
                                        }}
                                        className={`${this.state.libraryData.length != 0 ? 'btnBtm' : 'noDataBtn m-1'}`}>

                                        <AddModal
                                            btnStyle={'center'}
                                            buttonName={'Create new playlist'}
                                            modalType={'library'}
                                            modalTitle={'Create new Playlist'}
                                            isFooter={'false'}
                                            callID={''}
                                            modalView={this.isModal.bind(this)}
                                            loadingBarRef={this.ref}
                                        />

                                        <Modal
                                            isOpen={this.state.isSharePlaylistModalOpen}
                                            toggle={this.sharePlaylistModalToggle}
                                        >
                                            <ModalHeader toggle={this.sharePlaylistModalToggle}>
                                                Share <Tooltip
                                                    html={tooltipContentInShareModal}
                                                    position="bottom"
                                                    arrow={true}
                                                    style={{ padding: '0px' }}
                                                >
                                                    {this.state.playlistToShareData?.libraryName?.length > 20 ? this.state.playlistToShareData?.libraryName.substring(0, 15) + "..." : this.state.playlistToShareData?.libraryName}
                                                </Tooltip>
                                            </ModalHeader>
                                            <ModalBody className="addModal-body">
                                                <PlayList
                                                    toggle={this.sharePlaylistModalToggle}
                                                    data={'0'}
                                                    bName='Share Playlist'
                                                    playlistData={this.state.playlistToShareData}
                                                    agentsWithLoginAccessOptions={this.state.agentsWithLoginAccessOptions}
                                                    loadingBarRef={this.ref}
                                                />
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                }
                            </Card>
                            <div className="inbox-rightbar">
                                <Card>
                                    <CardBody
                                        style={{
                                            height:
                                                this.state.callRecords.length >= 0 && this.state.callRecords.length <= 2
                                                    ? '450px'
                                                    : '',
                                        }}>
                                        {this.state.loadTable && (
                                            <React.Fragment>
                                                <Row>
                                                    <Col md={12} className="page-title mt-2">
                                                        <Loader />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                        {!this.state.loadTable && (
                                            <React.Fragment>
                                                <Col className="col-12" style={{ 'text-align': 'left' }}>
                                                    <h5 style={{ color: 'rgb(83, 105, 248)', fontWeight: '400' }}>
                                                        {this.state.callRecords.length != 0 && (
                                                            <React.Fragment>
                                                                Showing{' '}
                                                                {this.state.totalCalls > 1 && (
                                                                    <React.Fragment>
                                                                        {this.state.totalCalls} calls&nbsp;
                                                                    </React.Fragment>
                                                                )}
                                                                {this.state.totalCalls == 1 && (
                                                                    <React.Fragment>
                                                                        {this.state.totalCalls} call&nbsp;
                                                                    </React.Fragment>
                                                                )}
                                                                of {this.state.selectedLibrary}
                                                            </React.Fragment>
                                                        )}
                                                    </h5>
                                                </Col>
                                                {this.state.callRecords.length != 0 && (
                                                    <React.Fragment>
                                                        <TableWithSearch
                                                            records={this.state.callRecords}
                                                            tokenUser={this.props.user.token}
                                                            reloadTable={this.reloadData.bind(this)}
                                                            userTimeZone={this.props.user}
                                                            isRoleAgent={this.state.isRoleAgent}
                                                            selectedLibraryDetails={this.state.selectedLibraryDetails}
                                                            loadingBarRef={this.ref}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                {this.state.callRecords.length == 0 && (
                                                    <React.Fragment>
                                                        <div className="emtpyColumn">
                                                            <div className="d-inline-flex align-items-center ">
                                                                {this.state.isRoleAgent && this.state.libraryData.length === 0 ?
                                                                    'No playlist has been shared with you by the QA.'
                                                                    :
                                                                    <div>
                                                                        Start by adding {this.props.user.plan?.isChatActive?'calls / chats':'calls'} to this Playlist.
                                                                        <InfoIcon
                                                                            text={
                                                                                'How to add calls to a playlist in Enthu.AI ?'
                                                                            }
                                                                            link={
                                                                                'https://help.enthu.ai/support/solutions/articles/82000878345-how-to-add-calls-to-a-playlist-in-enthu-ai-'
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return state.Auth;
};

export default connect(mapStateToProps)(LibraryList);