import React, { Component } from 'react';
import { Col, Alert, Button, Card, CardBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import FormFeedback from '../../../components/FormControls/FormFeedback';
import CustomForm from '../../../components/FormControls/CustomForm';
import Label from '../../../components/FormControls/Label';
import FormGroup from '../../../components/FormControls/FormGroup';
import { getFormValidation } from '../../../components/FormControls/Validation';
import { servicePost } from './../../../helpers/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import '../../settings/truncte.css';
import InfoIcon from '../../../components/InfoIcon';

class AddQA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            error: false,
            errorMessage: '',
            errorColor: '',
            listTeam: '',
            // listRoles: [
            // { label: 'Quality Manager', value: 'QM' },
            // { label: 'Quality Analyst', value: 'QA' },
            // ],
            listRoles: [
                { label: 'Quality Manager (QM) - Gets add/edit access to Manage section', value: 'QM' },
                { label: 'Quality Analyst (QA) - Gets view only access to Manage section', value: 'QA' },
            ],
            selectedTeam: '',
            selectedRole: '',
            assignTeam:
                this.props.data.team.length != 0
                    ? { label: this.props.data.team[0].teamName, value: this.props.data.team[0]._id }
                    : '',
            assignRole: '',
            // tableRecords: this.props.tableRow
            isProcessing: false,
            roleError: false,
            agentStatus: 0,
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    removePopUp = (seconds) => {
        setTimeout(() => {
            this.props.toggle();
            this.props.view();
        }, seconds);
    };

    componentDidMount = () => {
        this.getAllTeam();
    };

    /**GET TEAM LIST */
    getAllTeam = async () => {
        try {
            let listTeamOpt = [];
            servicePost('team/getTeams', {}, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        for (var l = 0; l < res.data.length; l++) {
                            listTeamOpt.push({ label: res.data[l].teamName, value: res.data[l]._id });
                        }
                    }
                    this.setState({
                        listTeam: listTeamOpt,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the submit
     */
    handleSubmit = (values) => {
        if (!this.state.isValidEmail) {
            if (this.state.assignRole !== '') {
                this.props.toggle();
                let data = {
                    agentEmail: values.agentEmail.toLowerCase(),
                    agentName: values.agentName,
                    userRole: this.state.selectedRole,
                    selectTeam: this.state.selectedTeam,
                    agentPassword: '1234567890',
                    agentID: values.agentID,
                    updateType: values.updateType,
                };

                try {
                    this.setState({
                        isProcessing: true,
                        roleError: false,
                    });
                    if (values.submitType == '1') {
                        servicePost('qa/updateQa', { ...data, updateType: '0' }, this.headers)
                            .then((res) => {
                                if (res.status == 1) {
                                    this.setState({
                                        isProcessing: false,
                                    });
                                    toast(
                                        'Updating ' +
                                        (res.data.role[0] == 'agent' ? res.data.role[1] : res.data.role[0]),
                                        {
                                            pauseOnHover: false,
                                            bodyClassName: 'success-toast',
                                        }
                                    );
                                    this.props.view();
                                }
                            })
                            .catch((err) => {
                                toast('Internal server error. Please try again later', {
                                    pauseOnHover: false,
                                    bodyClassName: 'error-toast',
                                    hideProgressBar: true,
                                });
                            });
                    }
                    if (values.submitType == '0') {
                        servicePost('qa/addQa', data, this.headers)
                            .then((res) => {
                                // this.props.toggle();
                                if (res.status == 1) {
                                    this.setState({
                                        isProcessing: false,
                                    });
                                    if (res.message === 'SUCCESS') {
                                        toast(
                                            'Creating ' +
                                            (res.data.role[0] == 'agent' ? res.data.role[1] : res.data.role[0]),
                                            {
                                                pauseOnHover: false,
                                                bodyClassName: 'success-toast',
                                            }
                                        );
                                    } else {
                                        this.setState({
                                            isSuccess: true,
                                            errorMessage: res.error,
                                            error: true,
                                            errorColor: 'danger',
                                            isProcessing: false,
                                        });
                                        toast(res.error, {
                                            pauseOnHover: false,
                                            bodyClassName: 'error-toast',
                                            hideProgressBar: true,
                                        });
                                    }
                                    this.props.view();
                                }
                                if (res.status == 2) {
                                    this.setState({
                                        isProcessing: false,
                                    });
                                    toast(
                                        'Your plan allows addition of maximum ' +
                                        res.data.qaAllowed +
                                        ' QA/QM. Please contact your account manager at tushar@enthu.ai to upgrade the limit.',
                                        { pauseOnHover: false, bodyClassName: 'error-toast', hideProgressBar: true }
                                    );
                                }
                            })
                            .catch((err) => {
                                if (err.response && err.response.data.error == 'User is an admin ') {
                                    this.setState({
                                        isSuccess: true,
                                        errorMessage: 'Admin ID already has QA/QM rights',
                                        error: true,
                                        errorColor: 'danger',
                                        isProcessing: false,
                                    });
                                    toast('Admin ID already has QA/QM rights', {
                                        pauseOnHover: false,
                                        bodyClassName: 'error-toast',
                                        hideProgressBar: true,
                                    });
                                } else if (
                                    err.response &&
                                    err.response.data.error == 'Plan exhaust, Please upgrade your plan'
                                ) {
                                    this.setState({
                                        isSuccess: true,
                                        errorMessage: 'Plan exhaust, Please upgrade your plan.',
                                        error: true,
                                        errorColor: 'danger',
                                        isProcessing: false,
                                    });
                                    toast('Plan exhaust, Please upgrade your plan', {
                                        pauseOnHover: false,
                                        bodyClassName: 'error-toast',
                                        hideProgressBar: true,
                                    });
                                } else {
                                    this.setState({
                                        isSuccess: true,
                                        errorMessage: 'Internal server error. Please try again later',
                                        error: true,
                                        errorColor: 'danger',
                                        isProcessing: false,
                                    });
                                    toast('Internal server error. Please try again later', {
                                        pauseOnHover: false,
                                        bodyClassName: 'error-toast',
                                        hideProgressBar: true,
                                    });
                                }
                            });
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                this.setState({
                    roleError: true,
                });
            }
        }
    };

    onChangeTeamList = async (selectedValue) => {
        this.setState({
            selectedTeam: selectedValue.value,
            assignTeam: selectedValue,
        });
    };

    onChangeRole = async (selectedValue) => {
        this.setState({
            selectedRole: selectedValue.value,
            assignRole: selectedValue,
            roleError: false,
        });
    };

    onChangeAvInput = () => {
        this.setState({
            isSuccess: false,
        });
    };

    handleRoleValidation = () => {
        if (this.state.assignRole == '') {
            this.setState({
                roleError: true,
            });
        }
    };

    checkExisitingEmail = (e) => {
        let data = {
            agentEmail: e.target.value,
        };
        servicePost('qa/checkExisitingEmail', data, this.headers).then((res) => {
            if (res.status === 1) {
                this.setState({
                    agentStatus: res.status,
                    errorMessage: res.data,
                    isValidEmail: true,
                });
            } else {
                this.setState({
                    agentStatus: res.status,
                    errorMessage: [],
                    isValidEmail: false,
                });
            }
        });
    };

    render() {
        let agentData = { email: '', name: '', team: '', submitType: '0', agentID: '' };

        if (this.props.data) {
            agentData = {
                email: this.props.data.email,
                name: this.props.data.name,
                submitType: this.props.data._id ? '1' : '0',
                agentID: this.props.data._id,
            };
        }

        if (!this.state.selectedRole) {
            this.state.assignRole = !this.props.data.role
                ? ''
                : this.props.data.role && this.props.data.role.indexOf('QA') !== -1
                    ? { label: 'Quality Analyst', value: 'QA' }
                    : { label: 'Quality Manager', value: 'QM' };
        }

        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                error: 'User is already exits',
                                agentName: agentData.name,
                                agentEmail: agentData.email,
                                submitType: agentData.submitType,
                                agentID: agentData.agentID,
                                addedBy: this.props.user.id
                            }}
                            validationSchema={getFormValidation([
                                { "name": "agentName", "validations": [{ "type": "required", "message": "This field is invalid" }] },
                                { "name": "agentEmail", "validations": [{ "type": "required", "message": "This field is required" }] },
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {(this.props.typeForm == 'edit' || this.props.typeForm == 'editEmail') && (
                                        <React.Fragment>
                                            {this.state.resError && (
                                                <FormGroup row>
                                                    <Col sm={12}>
                                                        <FormControls
                                                            type="text"
                                                            name="error"
                                                            // value={'User is already exits'}
                                                            id="error"
                                                            style={{ backgroundColor: 'rgba(255,92,117,.9)', color: '#fff' }}
                                                            disabled
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            )}

                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="example">
                                                        Full Name <span style={{ color: '#5369f8' }}>*</span>
                                                    </Label>
                                                    <FormControls
                                                        type="text"
                                                        name="agentName"
                                                        // value={agentData.name}
                                                        id="agentName"
                                                        autoComplete="false"
                                                        placeholder="Enter Full Name"
                                                        onChange={this.onChangeAvInput}
                                                    // required
                                                    />
                                                    <FormFeedback type="invalid" >{errors.agentName}</FormFeedback>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="example">
                                                        Email&nbsp; (An email invite will go to this ID){' '}
                                                        <span style={{ color: '#5369f8' }}>*</span>
                                                    </Label>
                                                    {this.props.typeForm == 'edit' && (
                                                        <FormControls
                                                            type="email"
                                                            // required
                                                            // value={agentData.email}
                                                            name="agentEmail"
                                                            id="agentEmail"
                                                            // onChange={this.handleChange}
                                                            autoComplete="false"
                                                            placeholder="Enter Email ID"
                                                            onChange={this.checkExisitingEmail}
                                                        />
                                                    )}

                                                    {this.props.typeForm == 'editEmail' && (
                                                        <FormControls
                                                            type="email"
                                                            // required
                                                            // value={agentData.email}
                                                            name="agentEmail"
                                                            id="agentEmail"
                                                            // onChange={this.handleChange}
                                                            autoComplete="false"
                                                            placeholder="Enter Email ID"
                                                            onChange={this.checkExisitingEmail}
                                                            style={{ backgroundColor: '#dddddd' }}
                                                            disabled
                                                        />
                                                    )}

                                                    {this.state.agentStatus === 1 && (
                                                        <React.Fragment>
                                                            <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                                {' '}
                                                                {this.state.errorMessage}{' '}
                                                            </span>
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            </FormGroup>

                                            <div class="row form-group">
                                                <Col sm={12}>
                                                    <Label
                                                        for="example"
                                                        className={this.state.roleError == true ? 'text-danger' : ''}>
                                                        <div className="d-flex align-items-center">
                                                            Assign role *
                                                            <InfoIcon
                                                                text={'What is the difference between QA and QM? '}
                                                                link={
                                                                    'https://help.enthu.ai/support/solutions/articles/82000469710-what-is-the-difference-between-qa-and-qm-'
                                                                }
                                                            />
                                                        </div>
                                                    </Label>
                                                    <Select
                                                        onChange={this.onChangeRole}
                                                        value={this.state.assignRole}
                                                        required
                                                        options={this.state.listRoles}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder={'Select a role'}
                                                        // id={this.state.roleError == true ? 'reacttr' : ''}
                                                        id={this.state.roleError == true ? 'reacttr' : ''}
                                                    // id={this.state.roleError == true ? 'reacttr' : (this.state.assignRole !=='' ? 'reacttr' : '')}
                                                    //(c ? d : e)
                                                    // id={}
                                                    />
                                                </Col>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    <div style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                        <FormGroup className='row'>
                                            <Col sm={6}>
                                                <Button
                                                    onClick={this.props.toggle}
                                                    style={{ float: 'right' }}
                                                    className="btn btn-primary cancel-btn"
                                                    type="button">
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControls type="hidden" name="submitType" value={agentData.submitType} />
                                                <FormControls type="hidden" name="agentID" value={agentData.agentID} />
                                                <FormControls type="hidden" name="addedBy" value={this.props.user.id} />
                                                <Button color="primary" type="submit" onClick={this.handleRoleValidation}>
                                                    {this.props.bName ? 'Update QA/QM' : 'Create QA'}
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </div>

                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row style={{ textAlign: 'center' }}>
                                                <Col sm={12}>
                                                    {this.state.message}
                                                    {this.state.error && (
                                                        <Alert
                                                            color={this.state.errorColor}
                                                            isOpen={this.state.error ? true : false}>
                                                            <div>{this.state.errorMessage}</div>
                                                        </Alert>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};

export default connect(mapStateToProps)(AddQA);
