import React, { Component } from 'react';
import { Col, Button, Card, CardBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Form from '../../components/FormControls/Form';
import FormControls from '../../components/FormControls/FormControls';
import CustomForm from '../../components/FormControls/CustomForm';
import FormGroup from '../../components/FormControls/FormGroup';
import Label from '../../components/FormControls/Label';
import { serviceGet, servicePost } from './../../helpers/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import '../settings/truncte.css';
import { getFormValidation } from '../../components/FormControls/Validation';
import FormFeedback from '../../components/FormControls/FormFeedback';
class AddTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            librarayListOpt: '',
            selectLibrary: '',
            teamError: 0,
            libraryName: '',
            // tableRecords: this.props.tableRow
            playListError: false,
            bothVlaueSub: false,
            playListErrorCustom: false,
            libraryalreadyAdded: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
        this.validation = [
            {
              name: "libraryName",
              validations: [{ type: "required", message: "Playlist name is required" }],
            },
          ];
    }

    /** GET AGENTS LIST */
    librarayList = async () => {
        try {
            let librarayListOpt = [];
            await serviceGet('api/v1/library/getPlaylists', { headers: this.headers })
                .then((res) => {
                    if (res.status == 1) {
                        var selectLibrary = [];
                        var playListId=[]
                        res.data.data.map((data) => {
                            if (data.callId.includes(this.props.callId)) {
                                selectLibrary.push({ value: data._id, label: data.libraryName });
                                playListId.push(data._id)
                            }
                            librarayListOpt.push({ label: data.libraryName, value: data._id });
                        });
                    }
                    this.setState({
                        librarayListOpt,
                        selectLibrary,
                        playListId
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the submit
     */
    handleSubmit = async (values) => {
        if (this.state.playListId.length>0) {
            try {
                let successMessage = '';
                let data = {
                    // libraryName: this.state.libraryName
                    //     ? this.capitalize(this.state.libraryName)
                    //     : this.capitalize(values.libraryName),
                    callId: values.callId,
                    libraryComment: values.libraryComments,
                    // playListIds: this.state.playListId.length>0 && this.state.playListId,
                };

                if(this.state.playListId.length>0){
                    data.playListIds=this.state.playListId
                }

                this.props.loadingBarRef.current.continuousStart()
                await servicePost('api/v1/library/addCallLibrary', data, this.headers)
                    .then((res) => {
                        this.props.loadingBarRef.current.complete()
                        if (res.status == 1) {
                            toast.success('Added to Library', { bodyClassName: 'custom-toast', pauseOnHover: true });
                            this.props.toggle();
                            if (values.callId == '') {
                                this.props.view();
                            }
                        } else if (res.status == 0) {
                            this.setState({
                                libraryalreadyAdded: true,
                            });
                        } else {
                            toast.error('Something went wrongg', {
                                bodyClassName: 'custom-toast',
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch((err) => {
                        this.props.loadingBarRef.current.complete()
                        console.log(err);
                        toast.error('Something went wrong', {
                            bodyClassName: 'custom-toast',
                            pauseOnHover: true,
                        });
                    });
            } catch (err) {
                console.log(err);
                toast.error('Something went wrong', {
                    bodyClassName: 'custom-toast',
                    pauseOnHover: true,
                });
            }
        } else if (values.libraryName) {
            try {
                let successMessage = '';
                let data = {
                    libraryName: this.state.libraryName
                        ? this.capitalize(this.state.libraryName)
                        : this.capitalize(values.libraryName),
                    callId: values.callId,
                    libraryComment: values.libraryComments,
                    createdBy: this.props.user.id
                };
                this.props.loadingBarRef.current.continuousStart()
                await servicePost('api/v1/library/addCallLibrary', data, this.headers)
                    .then((res) => {
                        this.props.loadingBarRef.current.complete()
                        if (res.status == 1) {
                            toast.success('Added to Library', { bodyClassName: 'custom-toast', pauseOnHover: true });
                            this.props.toggle();
                            if (values.callId == '') {
                                this.props.view();
                            }
                        } else if (res.status == 0) {
                            this.setState({
                                libraryalreadyAdded: true,
                            });
                        } else {
                            toast.error('Something went wrongg', {
                                bodyClassName: 'custom-toast',
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch((err) => {
                        this.props.loadingBarRef.current.complete()
                        console.log(err);
                        toast.error('Something went wrong', {
                            bodyClassName: 'custom-toast',
                            pauseOnHover: true,
                        });
                    });
            } catch (err) {
                console.log(err);
                toast.error('Something went wrong', {
                    bodyClassName: 'custom-toast',
                    pauseOnHover: true,
                });
            }
        } else {
            if (this.state.isShow) {
                this.setState({
                    playListErrorCustom: true,
                });
            } else {
                this.setState({
                    playListError: true,
                });
            }
        }
    };

    componentDidMount = async () => {
        this.librarayList();
    };

    onChangeLibraryList = async (selectedValue) => {
        if (selectedValue != null) {
            if (selectedValue.length > 0) {
                const playListId = [];
                selectedValue.map((data) => {
                    playListId.push(data.value);
                });

                if (selectedValue) {
                    this.setState({
                        libraryName: selectedValue && selectedValue[0].label,
                        selectLibrary: selectedValue,
                        libraryNameCon: 'selected',
                        playListId: playListId,
                        playListError: false,
                    });
                } else {
                    this.setState({
                        libraryName: null,
                    });
                }
            } else {
                this.setState({
                    selectLibrary: [],
                    libraryName: null,
                    playListId:[]
                });
            }
        } else {
            this.setState({
                selectLibrary: [],
                libraryName: null,
                playListId:[]
            });
        }
    };

    appendInput() {
        this.setState({
            isShow: true,
            playListError: false,
            libraryalreadyAdded: false,
            selectLibrary:'',
            playListId:[],
            libraryName:''
        });
    }

    removeInput() {
        this.setState({
            isShow: false,
            // playListError: false,
            libraryalreadyAdded: false,
        });
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    newPlayList = () => {
        this.setState({
            playListErrorCustom: false,
            libraryalreadyAdded: false,
        });
    };

    render() {
        let teamData = { teamName: '', teamLead: '', agents: '', teamId: '' };

        if (this.props.data) {
            teamData = {
                teamName: this.props.data.teamName,
                teamLead: this.props.data.teamLead,
                agents: this.props.data.assignedAgent,
                teamId: this.props.data._id,
            };
        }
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                libraryName: teamData.teamName,
                                libraryComments: teamData.teamName,
                                callId: this.props.callId
                            }}
                            validationSchema={getFormValidation(this.validation)}
                            onSubmit={this.handleSubmit} >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {this.props.callId !== '' &&
                                        <>
                                            <FormGroup row>

                                                {!this.state.isShow ? (
                                                    <Col sm={12}>
                                                        <div className='d-flex justify-content-between'>
                                                            <Label
                                                                for=""
                                                                className={this.state.playListError == true ? 'libraray-selection-label text-danger' : 'libraray-selection-label'}
                                                            >
                                                                <span >Select an existing Playlist *</span>
                                                            </Label>
                                                            <span onClick={() => this.appendInput()}
                                                                class="btn btn-link"
                                                                type="button">
                                                                Add a new Playlist
                                                            </span>
                                                        </div>

                                                        <Select
                                                            onChange={this.onChangeLibraryList}
                                                            value={this.state.selectLibrary}
                                                            isMulti={true}
                                                            options={this.state.librarayListOpt}
                                                            className={
                                                                this.state.isShow
                                                                    ? 'react-select reacttr_select'
                                                                    : 'react-select'
                                                            }
                                                            classNamePrefix="react-select"
                                                            placeholder={'Select an existing Playlist'}
                                                            id={this.state.playListError == true ? 'reacttr' : ''}
                                                            isDisabled={this.state.isShow ? true : null}
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col sm={12}>
                                                        <div className='d-flex justify-content-between'>
                                                            <Label
                                                                for=""
                                                                className={this.state.playListErrorCustom ? 'libraray-selection-label text-danger' : 'libraray-selection-label'}
                                                            >
                                                                <span >Create a new Playlist *</span>
                                                            </Label>
                                                            <span
                                                                onClick={() => this.removeInput()}
                                                                class="btn btn-link"
                                                            >
                                                                Back
                                                            </span>
                                                        </div>
                                                        <FormControls
                                                            type="text"
                                                            name="libraryName"
                                                            // value={teamData.teamName}
                                                            autoComplete="off"
                                                            placeholder="Create a new Playlist"
                                                            id={this.state.playListErrorCustom ? 'reacttr' : ''}
                                                            onChange={this.newPlayList}
                                                        />
                                                        {this.state.libraryalreadyAdded && (
                                                            <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                                {' '}
                                                                Playlist with this name already exists{' '}
                                                            </span>
                                                        )}
                                                    </Col>
                                                )}
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="example">Call comments</Label>
                                                    <FormControls
                                                        type="text"
                                                        name="libraryComments"
                                                        id="libraryComments"
                                                        // value={teamData.teamName}
                                                        autoComplete="off"
                                                        placeholder="Add comments for easy identification"
                                                    />
                                                </Col>
                                            </FormGroup>


                                        </>
                                    }

                                    <FormControls
                                        type="hidden"
                                        name="callId"
                                        id="callId"
                                        autoComplete="false"
                                        value={this.props.callId}
                                    />

                                    {this.props.callId == '' && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="example">Create a new Playlist</Label>
                                                    <FormControls
                                                        type="text"
                                                        name="libraryName"
                                                        id="libraryName"
                                                        // value={teamData.teamName}
                                                        autoComplete="false"
                                                        placeholder="Playlist name"
                                                        onChange={() => this.setState({ libraryalreadyAdded: false })}
                                                    />
                                                    <FormFeedback type="invalid">
                                                        {errors.libraryName}
                                                    </FormFeedback>
                                                    {this.state.libraryalreadyAdded && (
                                                        <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                            {' '}
                                                            Playlist with this name already exists{' '}
                                                        </span>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}
                                    <FormGroup className='row'>
                                        <Col sm={6}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit">
                                                {`${this.props.bName ? this.props.bName : 'Save'}`}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};

export default connect(mapStateToProps)(AddTeam);
