import React, { Component } from 'react';
import {
    Row,
    Col,
    Input,
    FormText,
    Button,
    Card,
    CardBody,
    UncontrolledTooltip,
} from 'reactstrap';
import { servicePost } from './../../../helpers/api';
// import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import CustomForm from '../../../components/FormControls/CustomForm';
import FormGroup from '../../../components/FormControls/FormGroup';
import Label from '../../../components/FormControls/Label';
import { getFormValidation } from '../../../components/FormControls/Validation';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as FeatherIcon from 'react-feather';
import TextInputBadge from '../../../components/TextInputBadge';


const InfoBtn = (props) => {
    const id = props.id;
    return (
        <svg viewBox="0 0 24 24" fill="none" className="text-grey-4" height="16" width="16" id={id}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                fill="currentColor"></path>
        </svg>
    );
};

class AddMoment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            isShow: false,
            options: false,
            tags: [],
            required: true,
            isProcessing: false,
            scriptComplianceTag: this.props.data && this.props.data.momentTag ? true : false,
            scriptCompliance: this.props.data ? this.props.data.scriptCompliance : true,
            momentCommunicationType: this.props.data && this.props.data.momentCommunicationType ? this.props.data.momentCommunicationType : 'call',
            length: 0,
            primaryMomentCondition: this.props.data ? this.props.data.primaryCloud.matchCondition : 'or',
            primaryMomentConditionKeywordCount: this.props.data ? this.props.data.primaryCloud.keywordCountCondition : 2,
            isAddKeywordsButtonClicked: this.props.data?.secondaryCloud,
            // tableRecords: this.props.tableRow
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMomentConditionChange = this.handleMomentConditionChange.bind(this);
        this.countBadge = this.countBadge.bind(this);
    }
    /**
     * Handles the submit
     */
    handleChange = () => {
        this.setState({ scriptCompliance: !this.state.scriptCompliance });
    };

    handleMomentTypeChange = (event) => {
        this.setState({ momentCommunicationType: event.target.value })
    }

    handleChangeTag = () => {
        this.setState({ scriptComplianceTag: !this.state.scriptComplianceTag });
    };

    handleMomentConditionChange = (value) => {
        this.setState({ primaryMomentCondition: value });
    };

    // handleMomentTagChange = (value) => {
    //     this.setState({ momentTagId: value });
    // };

    handleMomentConditionCountChange = (value) => {
        this.setState({ primaryMomentConditionKeywordCount: value });
    };

    handleSubmit = (values) => {
        // this.props.toggle();

        this.setState({
            isProcessing: true,
        });

        if (this.state.scriptComplianceTag && this.state.momentTagId != "") {

        }
        let data = {
            momentName: values.momentName,
            momentDesc: values.momentDesc,
            momentGroupId: values.momentGroupId,
            momentGroup: values.momentGroup,
            themeId: values.themeID,
            // primaryCloudKeywords: values.primaryCloudKeywords,
            momentID: values.momentID,
            userId: this.props.user.id,
            scriptCompliance: this.state.scriptCompliance,
            // primaryMomentCondition: this.state.primaryMomentCondition,
            // primaryMomentConditionKeywordCount: this.state.primaryMomentConditionKeywordCount,
            momentTag: this.state.scriptComplianceTag,
            momentCommunicationType: this.state.momentCommunicationType,
            primaryCloud: {
                keywords: values.primaryCloudKeywords,
                matchCondition: this.state.primaryMomentCondition,
                keywordCountCondition: this.state.primaryMomentConditionKeywordCount
            },
            secondaryCloud: this.state.isAddKeywordsButtonClicked ? {
                keywords: values.secondaryCloudKeywords,
                matchCondition: values.secondaryMomentCondition,
                keywordCountCondition: values.secondaryMomentConditionKeywordCount
            } : null,
            cloudAnalysesCondition: this.state.isAddKeywordsButtonClicked && values.cloudAnalysesCondition,
            analysesBySpeaker:values.analysesBySpeaker
        };

        if (this.state.scriptComplianceTag) {
            data['momentTagId'] = values.momentTagId
        }

        // return false;

        let headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };

        try {
            if (values.submitType == '1') {
                servicePost('moments/edit', data, headers)
                    .then((res) => {
                        if (res.status == 1) {
                            toast.success('Updating the Moment...', { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });                           
                            // this.props.tableRow= res.data
                            this.props.toggle();
                            this.props.view();

                            this.setState({
                                isSuccess: true,
                                message: 'Moment Updated.',
                                isProcessing: false,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            isProcessing: false,
                        });
                        console.log(err);
                        toast.error('Something went wrong', { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });

                    });
            }
            if (values.submitType == '0') {
                servicePost('moments/add', data, headers)
                    .then((res) => {
                        if (res.SUCCESS === true) {
                            toast.success('Moment added successfully', { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });                                                      
                            // this.props.tableRow= res.data
                            // this.props.toggle();
                            this.props.view();
                            
                            this.setState({
                                isSuccess: true,
                                message: 'Moment created.',
                                isProcessing: false,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            isProcessing: false,
                            isSuccess: false,
                            // message: 'Moment name already exist.',
                        });
                        console.log(err);
                        if (err?.response?.data?.error === 'Moment already exists') {
                            toast.error('Moment name already exists.', { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });

                            
                        }
                        else if(err?.response?.data?.error === 'This MomentGroup already exists.'){
                            toast.error(err?.response?.data?.error, { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });

                            
                        }
                        else {
                            toast.error('Something went wrong', { position: 'top-right', pauseOnHover: true, bodyClassName: 'custom-toast' });

                        }
                    });
            }
        } catch (err) {
            console.log(err);
        }
    };

    appendInput() {
        this.setState({
            isShow: true,
        });
    }

    removeInput() {
        this.setState({
            isShow: false,
        });
    }

    onKeyUpValue(event) {
        const groupValue = event.target.value;
        if (typeof groupValue !== 'undefined' && groupValue !== null) {
            this.setState({
                required: false,
            });
        }
    }

    getMomentGroupByUser = () => {
        try {
            let headers = {
                'Content-Type': 'application/json',
                Authorization: 'JWT ' + this.props.user.token,
            };
            servicePost('moments/group/user', { id: this.props.user.id, themeId: this.props.themeId }, headers)
                .then((res) => {
                    if (res.data.length > 0) {
                        this.setState({
                            options: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };


    componentDidMount() {
        this._isMounted = true;
        try {
            // let stringDataKeywordG = this.getDataKeyword();
            this.getMomentGroupByUser();
            this.getAllTag();
            // this.countWord(stringDataKeywordG);
        } catch (err) {
            console.log(err);
        }
    }


    getAllTag = () => {
        try {
            let headers = {
                'Content-Type': 'application/json',
                Authorization: 'JWT ' + this.props.user.token,
            };
            servicePost('tags/get/tags', {}, headers)
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.setState({
                            tags: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    // getDataKeyword() {
    //     if (this.props.data) {
    //         let stringDataKeyword = this.props.data.primaryCloud.keywords.length;
    //         this.setState({ length: stringDataKeyword });
    //         return false;
    //     }
    // }

    // countWord(e) {
    //     if (e) {
    //         const re = /, ?|\n/gm;
    //         this.setState({ length: e.target.value.split(re).length });

    //         if (e.target.value == '') {
    //             this.setState({ length: 0 });
    //         }
    //     }
    // }

    countBadge(count){
        this.setState({length:count});
    }

    render() {
        let momentData = {
            momentName: '',
            momentDesc: '',
            primaryCloudKeywords: '',
            submitType: '0',
            momentID: '',
            primaryMomentCondition: 'or',
            primaryMomentConditionKeywordCount: 2,
            momentTagId: '',
            secondaryCloudKeywords: '',
            secondaryMomentCondition: 'or',
            cloudAnalysesCondition: '',
            secondaryMomentConditionKeywordCount: 2,
            analysesBySpeaker:"all"
        };


        if (this.props.data) {
            momentData = {
                momentName: this.props.data.momentName,
                momentDesc: this.props.data.momentDesc,
                primaryCloudKeywords: this.props.data.primaryCloud.keywords,
                submitType: '1',
                momentID: this.props.data._id,
                momentGroupId: this.props.data.momentGroupId,
                primaryMomentCondition: this.props.data.primaryCloud.matchCondition,
                primaryMomentConditionKeywordCount: this.props.data.primaryCloud.keywordCountCondition,
                momentTagId: this.props.data.momentTagId,
                secondaryCloudKeywords: '',
                secondaryMomentCondition: 'or',
                cloudAnalysesCondition: '',
                secondaryMomentConditionKeywordCount: 2,
                analysesBySpeaker:this.props.data.analysesBySpeaker?this.props.data.analysesBySpeaker :"all",

            };
            if (this.props.data.secondaryCloud) {
                momentData.secondaryCloudKeywords = this.props.data.secondaryCloud.keywords
                momentData.secondaryMomentCondition = this.props.data.secondaryCloud.matchCondition
                momentData.cloudAnalysesCondition = this.props.data.cloudAnalysesCondition
                momentData.secondaryMomentConditionKeywordCount = this.props.data.secondaryCloud.keywordCountCondition
            }

        }

        let validation = [
          {
            name: "momentName",
            validations: [
              { type: "required", message: "This field is required" }
            ]
          },
          {
            name: "primaryCloudKeywords",
            validations: [
              { type: "required", message: "This field is required" }
            ]
          }
        ];

        this.state.options && this.state.isShow ? validation.push({ "name": "momentGroup", "validations": [{ "type": "required", "message": "This field is required" }] }) : !this.state.options ? validation.push({ "name": "momentGroup", "validations": [{ "type": "required", "message": "This field is required" }] }) : validation.push({ "name": "momentGroupId", "validations": [{ "type": "required", "message": "This field is required" }] })

        this.state.scriptComplianceTag && validation.push({ "name": "momentTagId", "validations": [{ "type": "required", "message": "This field is required" }] })
        // this.state.scriptComplianceTag2 && validation.push({ "name": "momentTagId2", "validations": [{ "type": "required", "message": "This field is required" }] })
        this.state.isAddKeywordsButtonClicked && validation.push({ "name": "cloudAnalysesCondition", "validations": [{ "type": "required", "message": "This field is required" }] }, { "name": "secondaryCloudKeywords", "validations": [{ "type": "required", "message": "This field is required" }] })


        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                momentGroupId: momentData.momentGroupId,
                                momentGroup: '',
                                momentName: momentData.momentName,
                                submitType: momentData.submitType,
                                momentID: momentData.momentID,
                                themeID: this.props.themeId,
                                primaryCloudKeywords: momentData.primaryCloudKeywords.toString(),
                                momentTagId: momentData.momentTagId,
                                secondaryCloudKeywords: momentData.secondaryCloudKeywords.toString(),
                                secondaryMomentCondition: momentData.secondaryMomentCondition,
                                cloudAnalysesCondition: momentData.cloudAnalysesCondition,
                                secondaryMomentConditionKeywordCount: momentData.secondaryMomentConditionKeywordCount,
                                analysesBySpeaker:momentData.analysesBySpeaker
                            }}
                            validationSchema={getFormValidation(validation)}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                                setFieldValue,
                                initialValues
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {this.state.options && !this.state.isShow && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={11}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="m-1">
                                                            <Label for="example">
                                                                Assign to an existing Group{' '}
                                                                <span style={{ color: '#5369f8' }}>*</span>
                                                            </Label>
                                                            <span style={{ paddingLeft: '5px' }}>
                                                                <InfoBtn id="UncontrolledTooltipExample1" />
                                                            </span>
                                                        </div>

                                                        <div>
                                                            {!this.state.isShow && this.props.bName !== 'Update Moment' && (
                                                                <span
                                                                    onClick={() => {
                                                                        setFieldValue("momentGroupId", initialValues.momentGroupId);
                                                                        setFieldValue("momentGroup", initialValues.momentGroup);
                                                                        this.appendInput();
                                                                    }}
                                                                    class="btn btn-link p-0"
                                                                >
                                                                    Add a new Group
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={11}>
                                                    <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                                                        Moment Group refers to the broad category under which you can place
                                                        multiple subgroups. E.g. Call Opening
                                                    </UncontrolledTooltip>
                                                    {this.state.required && (
                                                        <React.Fragment>
                                                            <Input
                                                                type="select"
                                                                name="momentGroupId"
                                                                class="mb-0"
                                                                onChange={handleChange}
                                                                value={values.momentGroupId}
                                                                invalid={!!errors.momentGroupId && touched.momentGroupId}
                                                                disabled={this.props.bName == 'Update Moment' ? true : false}
                                                            >
                                                                <option value="">Assign to an existing Group</option>
                                                                {this.state.options.map((data, index) => {
                                                                    return <option value={data._id}>{data.groupName}</option>;
                                                                })}
                                                            </Input>
                                                        </React.Fragment>
                                                    )}
                                                    {!this.state.required && (
                                                        <React.Fragment>
                                                            <Input
                                                                type="select"
                                                                name="momentGroupId"
                                                                class="mb-0"
                                                                onChange={handleChange}
                                                                value={values.momentGroupId}
                                                                invalid={!!errors.momentGroupId && touched.momentGroupId}
                                                            // value={momentData.momentGroupId}
                                                            >
                                                                <option value="0">Assign to an existing Group</option>
                                                                {this.state.options.map((data, index) => {
                                                                    return <option value={data._id}>{data.groupName}</option>;
                                                                })}
                                                            </Input>
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}

                                    {this.state.options && this.state.isShow && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={'11'}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="m-1">
                                                            <Label for="example">
                                                                Moment Group <span style={{ color: '#5369f8' }}>*</span>
                                                            </Label>
                                                            <span style={{ paddingLeft: '5px' }}>
                                                                <InfoBtn id="UncontrolledTooltipMomentGroup" />
                                                            </span>
                                                        </div>

                                                        <div>
                                                            <span
                                                                onClick={() => {
                                                                    setFieldValue("momentGroupId", initialValues.momentGroupId);
                                                                    setFieldValue("momentGroup", initialValues.momentGroup);
                                                                    this.removeInput()
                                                                }}
                                                                class="btn btn-link p-0"
                                                            >
                                                                Back
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="UncontrolledTooltipMomentGroup">
                                                        Moment Group refers to the broad category under which you can place
                                                        multiple subgroups. E.g. Call Opening
                                                    </UncontrolledTooltip>
                                                    <FormControls
                                                        type="text"
                                                        name="momentGroup"
                                                        onKeyUp={this.onKeyUpValue.bind(this)}
                                                        // value=""
                                                        autoComplete="false"
                                                        placeholder="Add Moment Group"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}

                                    {!this.state.options && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={'11'}>
                                                    <Label for="example">
                                                        Moment Group <span style={{ color: '#5369f8' }}>*</span>
                                                    </Label>
                                                    <FormControls
                                                        type="text"
                                                        name="momentGroup"
                                                        onKeyUp={this.onKeyUpValue.bind(this)}
                                                        // value=""
                                                        autoComplete="false"
                                                        placeholder="Add Moment Group"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}

                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="example">
                                                Moment Name <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <span style={{ paddingLeft: '5px' }}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="text-grey-4"
                                                    height="16"
                                                    width="16"
                                                    id="UncontrolledTooltipExample2">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample2">
                                                Moment Name refers to a sub category within a Moment Group.E.g. “Salutation”,
                                                “Introduction” & “Validation” can be 3 Moment Names with a Group “Call Opening”
                                            </UncontrolledTooltip>
                                            <FormControls
                                                type="text"
                                                name="momentName"
                                                id="momentName"
                                                // value={momentData.momentName}
                                                autoComplete="off"
                                                placeholder="Moment Name"
                                            // required
                                            />
                                            <FormControls type="hidden" name="submitType" />
                                            <FormControls type="hidden" name="momentID" />
                                            <FormControls type="hidden" name="themeID" />
                                        </Col>
                                        {!this.state.isSuccess && this.state.message !== '' && (
                                            <React.Fragment>
                                                <Col sm={'11 ps-3 error'}>{this.state.message}</Col>
                                            </React.Fragment>
                                        )}
                                        {/* <AvFeedback>This field is invalid</AvFeedback> */}
                                    </FormGroup>

                                    {/* <AvGroup row>
                                <Col sm={11}>
                                    <Label for="example">
                                        Moment Desc <small>(Optional)</small>
                                    </Label>
                                    <AvInput
                                        type="text"
                                        name="momentDesc"
                                        value={momentData.momentDesc}
                                        id="momentDesc"
                                        autoComplete="false"
                                        placeholder="Moment Desc"
                                    />
                                </Col>
                            </AvGroup> */}

                                    <div class="row form-group">
                                        <Col sm={11}>
                                            <Label for="example">Consider for script compliance score?</Label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="radio1"
                                                        id="radio1"
                                                        // value=
                                                        onChange={this.handleChange}
                                                        defaultChecked={this.state.scriptCompliance}
                                                    />{' '}
                                                    Yes
                                                </Label>
                                                <Label check className="ms-4">
                                                    <Input
                                                        type="radio"
                                                        name="radio1"
                                                        id="radio1"
                                                        // value={!this.state.scriptCompliance}
                                                        onChange={this.handleChange}
                                                        defaultChecked={!this.state.scriptCompliance}
                                                    />{' '}
                                                    No
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                    {this.props.user.plan?.isChatActive &&
                                        <div class="row form-group">
                                            <Col sm={11}>
                                                <Label for="example">Type of Moment</Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="momentType"
                                                            value="call"
                                                            onChange={this.handleMomentTypeChange}
                                                            checked={this.state.momentCommunicationType === 'call'}
                                                        />{' '}
                                                        Call
                                                    </Label>
                                                    <Label check className="ms-4">
                                                        <Input
                                                            type="radio"
                                                            name="momentType"
                                                            value="chat"
                                                            onChange={this.handleMomentTypeChange}
                                                            checked={this.state.momentCommunicationType === 'chat'}
                                                        />{' '}
                                                        Chat
                                                    </Label>
                                                    <Label check className="ms-4">
                                                        <Input
                                                            type="radio"
                                                            name="momentType"
                                                            value="all"
                                                            onChange={this.handleMomentTypeChange}
                                                            checked={this.state.momentCommunicationType === 'all'}
                                                        />{' '}
                                                        All
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                    }
                                    
                                    <div class="row form-group">
                                        <Col sm={11}>
                                            <Label for="example">Analyses By Speaker</Label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="analysesBySpeaker"
                                                        value="client"
                                                        onChange={handleChange}
                                                        checked={values.analysesBySpeaker === 'client'}
                                                    />{' '}
                                                    Client
                                                </Label>
                                                <Label check className="ms-4">
                                                    <Input
                                                        type="radio"
                                                        name="analysesBySpeaker"
                                                        value="agent"
                                                        onChange={handleChange}
                                                        checked={values.analysesBySpeaker === 'agent'}
                                                    />{' '}
                                                    Agent
                                                </Label>
                                                <Label check className="ms-4">
                                                    <Input
                                                        type="radio"
                                                        name="analysesBySpeaker"
                                                        value="all"
                                                        onChange={handleChange}
                                                        checked={values.analysesBySpeaker === 'all'}
                                                    />{' '}
                                                    All
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </div>

                                    {this.props.isAircallIntegrated &&
                                        <div class="row form-group" id="addTag">
                                            <Col sm={11}>
                                                <Label for="example"> Add Tag ?  <span style={{ paddingLeft: '5px' }}>
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        className="text-grey-4"
                                                        height="16"
                                                        width="16"
                                                        id="UncontrolledTooltipExamplewithTag">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                    <UncontrolledTooltip placement="right" target="UncontrolledTooltipExamplewithTag">
                                                        Add Tag
                                                    </UncontrolledTooltip></Label>
                                                <FormGroup className="mb-1">
                                                    <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="radio3"
                                                            id="radio3"
                                                            // value=
                                                            onChange={this.handleChangeTag}
                                                            defaultChecked={this.state.scriptComplianceTag}
                                                        />{' '}
                                                        Yes
                                                    </Label>
                                                    <Label check className="ms-4">
                                                        <Input
                                                            type="radio"
                                                            name="radio3"
                                                            id="radio3"
                                                            // value={!this.state.scriptCompliance}
                                                            onChange={this.handleChangeTag}
                                                            defaultChecked={!this.state.scriptComplianceTag}
                                                        />{' '}
                                                        No
                                                    </Label>
                                                </FormGroup>

                                                {this.state.scriptComplianceTag && (
                                                    <Input
                                                        type="select"
                                                        name="momentTagId"
                                                        id="momentTagId"
                                                        className="mb-2"
                                                        value={values.momentTagId}
                                                        invalid={!!errors.momentTagId && touched.momentTagId}
                                                        onChange={handleChange}>
                                                        <option value="">Assign to an existing Tag</option>
                                                        {this.state.tags.map((data, index) => {
                                                            return <option value={data._id}>{data.keyword}</option>;
                                                        })}
                                                    </Input>
                                                )}


                                            </Col>
                                        </div>
                                    }

                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="example">
                                                Keywords cloud 1 <span style={{ color: '#5369f8' }}>*</span>{' '}
                                            </Label>
                                            <div style={{ display: 'inline-block', float: 'right' }}>
                                                <Label for="example">
                                                    <span style={{ color: '#b0b7ec', fontWeight: 'bold' }}>
                                                        {this.state.length}
                                                    </span>
                                                    <span style={{ color: '#b0b7ec', fontWeight: 'bold' }}> keywords</span>
                                                </Label>
                                            </div>
                                            <span style={{ paddingLeft: '5px' }}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="text-grey-4"
                                                    height="16"
                                                    width="16"
                                                    id="UncontrolledTooltipExample3">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample3">
                                                Keywords tagged against a Moment Name E.g. “hi”, “hello”, “My name is John” can
                                                be the keywords under Moment “Salutation”
                                            </UncontrolledTooltip>
                                            <TextInputBadge 
                                                as='textarea'
                                                type="textarea"
                                                name="primaryCloudKeywords"
                                                values = {values.primaryCloudKeywords}
                                                handleChange = {handleChange}
                                                countBadges={this.countBadge}
                                                id="primaryCloudKeywords"
                                                autoComplete="off"
                                                placeholder="Add one keywords/phrase/sentence per line"
                                                invalid = {errors.primaryCloudKeywords && touched.primaryCloudKeywords}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <div class="row form-group">
                                        <Col sm={11}>
                                            <Label for="example">Mark 'Achieved' only when</Label>
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="primaryMomentCondition"
                                                    id="primaryMomentCondition"
                                                    defaultValue={momentData.primaryMomentCondition}
                                                    onChange={(e) => {
                                                        this.handleMomentConditionChange(e.target.value);
                                                    }}>
                                                    <option value="and">All keywords found</option>
                                                    <option value="or">Any keyword found</option>
                                                    <option value="other">Other</option>
                                                </Input>

                                                {this.state.primaryMomentCondition === 'other' && (
                                                    <div
                                                        className="mt-2"
                                                        style={{ display: 'flex', alignItems: 'center', fontSize: '.8rem' }}>
                                                        <span>- Min</span>
                                                        <Input
                                                            className="ms-1 me-1"
                                                            type="number"
                                                            style={{ width: '5rem', height: 'calc(1em + 1rem + 2px)' }}
                                                            name="primaryMomentConditionKeywordCount"
                                                            id="primaryMomentConditionKeywordCount"
                                                            // value={!this.state.scriptCompliance}
                                                            onChange={(e) => {
                                                                this.handleMomentConditionCountChange(Number(e.target.value));
                                                            }}
                                                            min={2}
                                                            defaultValue={momentData.primaryMomentConditionKeywordCount}
                                                        />
                                                        <span>keywords found</span>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </div>
                                    {!this.state.isAddKeywordsButtonClicked &&
                                        <div>
                                            <Button
                                                id='addKeywordCloud'
                                                className="btn cancel-btn d-block m-auto mb-3"
                                                onClick={() => {
                                                    this.setState({
                                                        isAddKeywordsButtonClicked: !this.state.isAddKeywordsButtonClicked
                                                    })
                                                }}
                                                type="button">
                                                {this.state.isAddKeywordsButtonClicked ? '-' : '+'}
                                            </Button>
                                            <UncontrolledTooltip placement="top" target="addKeywordCloud">
                                                Add a new keywords Cloud
                                            </UncontrolledTooltip>
                                        </div>
                                    }
                                    {this.state.isAddKeywordsButtonClicked &&
                                        <div className='p-3 m-2 mb-3' style={{ border: '1px solid', borderRadius: '5px' }}>
                                            <div className='float-end d-flex justify-content-center align-items-center position-relative bg-danger cursor-pointer' style={{ width: '22px', height: '22px', borderRadius: '50%', top: '-22px', left: '20px', border: '1px solid' }}>
                                                <Tooltip
                                                    title={`Delete secondary keywords cloud`}
                                                    position="top-end"
                                                    arrow={true}
                                                    trigger="mouseenter">
                                                    <FeatherIcon.Trash2
                                                        onClick={() => {
                                                            this.setState({
                                                                isAddKeywordsButtonClicked: !this.state.isAddKeywordsButtonClicked
                                                            })
                                                        }}
                                                        className='text-light' size={14}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <FormGroup row>
                                                <Col sm={11}>
                                                    <div className="m-1">
                                                        <Label>
                                                            Moment achieved logic
                                                            <span style={{ color: '#5369f8' }}> *</span>
                                                        </Label>
                                                        <span style={{ paddingLeft: '5px' }}>
                                                            <InfoBtn id="UncontrolledTooltipExample1" />
                                                            <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                                                                Moment Group refers to the broad category under which you can place
                                                                multiple subgroups. E.g. Call Opening
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col sm={11}>
                                                    <Input
                                                        type="select"
                                                        name="cloudAnalysesCondition"
                                                        class="mb-0"
                                                        onChange={handleChange}
                                                        value={values.cloudAnalysesCondition}
                                                        invalid={!!errors.cloudAnalysesCondition && touched.cloudAnalysesCondition}
                                                    >
                                                        <option value="">Moment achieved logic</option>
                                                        <option value="and">AND (both keyword clouds should be present)</option>
                                                        <option value="or">OR (either of  keyword clouds should be present)</option>
                                                        <option value="xor">XOR (cloud 1 should be present and cloud 2 should not be present)</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={11}>
                                                    <Label for="example">
                                                        Keywords cloud 2 <span style={{ color: '#5369f8' }}>*</span>{' '}
                                                    </Label>
                                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                                        <Label for="example">
                                                            <span style={{ color: '#b0b7ec', fontWeight: 'bold' }}>
                                                                {values.secondaryCloudKeywords !== '' ? values.secondaryCloudKeywords.split(/, ?|\n/gmy).length : 0}
                                                            </span>
                                                            <span style={{ color: '#b0b7ec', fontWeight: 'bold' }}> keywords</span>
                                                        </Label>
                                                    </div>
                                                    <span style={{ paddingLeft: '5px' }}>
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            className="text-grey-4"
                                                            height="16"
                                                            width="16"
                                                            id="UncontrolledTooltipExample3">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm10-1v5.2h-2V11h2zm-1-3.7a1.5 1.5 0 000 3h.01a1.5 1.5 0 100-3H12z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample3">
                                                        Keywords tagged against a Moment Name E.g. “hi”, “hello”, “My name is John” can
                                                        be the keywords under Moment “Salutation”
                                                    </UncontrolledTooltip>
                                                    <TextInputBadge 
                                                        as='textarea'
                                                        type="textarea"
                                                        name="secondaryCloudKeywords"
                                                        values = {values.secondaryCloudKeywords}
                                                        handleChange = {handleChange}
                                                        id="secondaryCloudKeywords"
                                                        autoComplete="false"
                                                        placeholder="Add one keywords/phrase/sentence per line"
                                                        invalid = {errors.secondaryCloudKeywords && touched.secondaryCloudKeywords}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <div class="row form-group">
                                                <Col sm={11}>
                                                    <Label for="example">Mark 'Achieved' only when</Label>
                                                    <FormGroup>
                                                        <Input
                                                            type="select"
                                                            name="secondaryMomentCondition"
                                                            id="secondaryMomentCondition"
                                                            value={values.secondaryMomentCondition}
                                                            onChange={handleChange}>
                                                            <option value="and">All keywords found</option>
                                                            <option value="or">Any keyword found</option>
                                                            <option value="other">Other</option>
                                                        </Input>

                                                        {values.secondaryMomentCondition === 'other' && (
                                                            <div
                                                                className="mt-2"
                                                                style={{ display: 'flex', alignItems: 'center', fontSize: '.8rem' }}>
                                                                <span>- Min</span>
                                                                <Input
                                                                    className="ms-1 me-1"
                                                                    type="number"
                                                                    style={{ width: '5rem', height: 'calc(1em + 1rem + 2px)' }}
                                                                    name="secondaryMomentConditionKeywordCount"
                                                                    value={values.secondaryMomentConditionKeywordCount}
                                                                    onChange={handleChange}
                                                                    min={2}
                                                                />
                                                                <span>keywords found</span>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </div>
                                        </div>}


                                    <FormGroup className="row ">
                                        <Col sm={5}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button disabled={this.state.isProcessing} color="primary" type="submit">
                                                {this.props.bName == 'Update Moment' && (
                                                    <React.Fragment>Update Moment</React.Fragment>
                                                )}
                                                {this.props.bName != 'Update Moment' && (
                                                    <React.Fragment>Create Moment</React.Fragment>
                                                )}
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};
export default connect(mapStateToProps)(AddMoment);
