import { axios } from "../../api";
import BaseCRUDService from "../../dataservice";

class teamService_ extends BaseCRUDService {
  constructor() {
    super("team");
  }

  async getAllTeamsList() {
    const res = await axios.post(`${this.base}/getTeams`);
    if (res.status === 200) {
      
      const activeTeams =
        res.data.data
          .filter((team) => team.isActive === true)
          ?.map((team) => ({
            value: team._id,
            label: team.teamName,
            SQLTeamId: team.SQLTeamId
          })) || [];

      const deactiveTeams =
        res.data.data
          .filter((team) => team.isActive === false)
          ?.map((team) => ({
            value: team._id,
            label: team.teamName,
            SQLTeamId: team.SQLTeamId
          })) || [];

      let listTeamOpt = [
        { label: "Active Teams", options: activeTeams },
        { label: "DeActive Teams", options: deactiveTeams }
      ];

      return listTeamOpt;
    }

    return [];
  }

  // GET ALL Teams
  async getTeams() {
    const res = await axios.post(`${this.base}/getTeams`);
    return res.data;
  }

  async getAllTeams() {
    const res = await axios.post(`${this.base}/all`);

    const activeTeams = []
    const deActiveTeams = []

    for (let i = 0; i < res.data.data.length; i++) {
      let team = res.data.data[i]
      if (team.isActive) {
        activeTeams.push({ label: team.teamName, value: team._id, SQLTeamId: team.SQLTeamId })
      }
      else {
        deActiveTeams.push({ label: team.teamName, value: team._id, SQLTeamId: team.SQLTeamId })
      }
    }

    let listTeamOpt = [
      { label: "Active Teams", options: activeTeams },
      { label: "Deactivated Teams", options: deActiveTeams }
    ];
    return listTeamOpt;
  }

  // UPDATE TEAM STATUS (Active/Deactive)
  async updateTeamStatus(payload) {
    const res = await axios.post(`${this.base}/updateTeamStatus`, payload);
    return res.data;
  }

  // GET Team By ID
  async getTeamByID(id) {
    const res = await axios.post(`${this.base}/getTeamByID`, { id: id });
    return res.data;
  }

  // Check if a Team Exist by this name
  async isExistTeamByName(name) {
    const res = await axios.post(`${this.base}/checkTeam`, { teamName: name });
    return res.data;
  }

  // Create a New Team
  async createTeam(payload) {
    const res = await axios.post(`${this.base}/addTeam`, payload);
    return res.data;
  }

  // Update a Team
  async updateTeam({ id, payload }) {
    const res = await axios.post(`${this.base}/update/${id}`, payload);
    return res.data;
  }
  // async updateTeam(payload) {
  //   const res = await axios.post(`${this.base}/updateTeam`, payload);
  //   return res.data;
  // }

  async getActiveTeams(payload){
    const res = await axios.get(`${this.base}/getActiveTeams`, payload);
    return res.data;
  }
}

export const teamService = new teamService_();
