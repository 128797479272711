import React, { Component } from 'react';
import { Row, Col, Input, FormText, Button, Card, CardBody } from 'reactstrap';
// import CustomInput from '../../../components/CustomInput';
import { servicePost } from '../../../helpers/api';
// import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
// import { AvField } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import CustomForm from '../../../components/FormControls/CustomForm';
import Label from '../../../components/FormControls/Label';
import FormGroup from '../../../components/FormControls/FormGroup';
import { getFormValidation } from '../../../components/FormControls/Validation';
import {teamService} from "../../../services/network/manage/teamServices";

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AlertCircle } from 'react-feather';
import Select from 'react-select';

class AddFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            isShow: false,
            options: false,
            required: true,
            invalidFile: false,
            selectedFile: null,
            listTeam: '',
            selectedTeam: '',
            // tableRecords: this.props.tableRow
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();

        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    onChangeHandler = (event) => {
        console.log(event.target.files[0]);
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        });
    };

    /**
     * Handles the submit
     */
    handleSubmit = (values) => {
        // const data = new FormData()
        // data.append('file', )

        let dataSend = {
            formEmail: values.requestFormEmail,
            formName: values.requestFormName,
            requestFormFile: this.state.selectedFile,
            selectTeam: this.state.selectedTeam,
        };

        try {
            // servicePost('api/v1/feedback/form/request/add',dataSend, headers)
            servicePost('api/v1/feedback/form/add', dataSend, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        this.props.view();
                        this.props.toggle();
                        // this.props.tableRow= res.data
                        toast('Thank you for your valuble feedback.', {
                            bodyClassName: 'success-toast',
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.setState({
                    //     isSuccess: true,
                    //     errorMessage: 'Internal server error. Please try again later',
                    //     error: true,
                    //     errorColor: 'danger'
                    // })
                    toast('Please provide your valuble feedback.', {
                        bodyClassName: 'error-toast',
                    });
                });
        } catch (err) {
            console.log(err);
        }
    };

    appendInput() {
        this.setState({
            isShow: true,
        });
    }

    removeInput() {
        this.setState({
            isShow: false,
        });
    }

    onKeyUpValue(event) {
        const groupValue = event.target.value;
        if (typeof groupValue !== 'undefined' && groupValue !== null) {
            this.setState({
                required: false,
            });
        }
    }

    getMomentGroupByUser = () => {
        try {
            // let headers= {
            //     "Content-Type": 'application/json',
            //     "Authorization": 'JWT '+this.props.user.token,
            // };
            servicePost('moments/group/user', { id: this.props.user.id }, this.headers)
                .then((res) => {
                    if (res.data.length > 0) {
                        this.setState({
                            options: res.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };
    handleFileChange = ({ target: { files } }) => {
        const cancel = !files.length;
        if (cancel) return;

        this.setState({ file: files[0] });
    };

    validateFile(value, ctx, input, cb) {
        console.log('Validating file');
        // if (!value) {
        //   this.setState({ invalidFile: true });
        //   cb("Required field");
        //   return;
        // }

        // this.setState({ invalidFile: false });
        // cb(true);
    }
    componentDidMount() {
        this._isMounted = true;
        try {
            this.getMomentGroupByUser();
            this.getAllTeam();
        } catch (err) {
            console.log(err);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**GET TEAM LIST */
    getAllTeam = async () => {
        try {
            teamService
                .getAllTeamsList()
                .then((res) => {
                  this.setState({
                    listTeam:[res[0]]
                  });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    onChangeTeamList = async (selectedValue) => {
        this.setState({
            selectedTeam: selectedValue,
            assignTeam: selectedValue,
        });
    };

    render() {
        let momentData = { momentName: '', momentDesc: '', momentKeywords: '', submitType: '0', momentID: '' };

        if (this.props.data) {
            momentData = {
                momentName: this.props.data.momentName,
                momentDesc: this.props.data.momentDesc,
                momentKeywords: this.props.data.momentKeywords,
                submitType: '1',
                momentID: this.props.data._id,
                momentGroupId: this.props.data.momentGroupId,
            };
        }
        const { invalidFile } = this.state;
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                requestFormName: momentData.momentName,
                                requestFormEmail: momentData.momentDesc,
                                file: ''

                            }}
                            validationSchema={getFormValidation([
                                { "name": "requestFormName", "validations": [{ "type": "required", "message": "This field is required" }] },
                                { "name": "requestFormEmail", "validations": [{ "type": "required", "message": "This field is required" }] },
                                { "name": "file", "validations": [{ "type": "required", "message": "This field is required" }] },
                                // { "name": "assignTeam", "validations": [{ "type": "required", "message": "This field is required" }] }
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {/* <AvForm enctype='multipart/form-data'> */}
                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="example">
                                                Form Name <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                type="text"
                                                name="requestFormName"
                                                id="requestFormName"
                                                // value={momentData.momentName}
                                                autoComplete="false"
                                                placeholder="Enter form name"
                                            />
                                        </Col>
                                        {/* {!this.state.isSuccess && <React.Fragment>

                                    <Col sm={'11 ps-3 error'}>{this.state.message}</Col>

                                </React.Fragment>} */}

                                    </FormGroup>

                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="example">
                                                Email <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                type="text"
                                                name="requestFormEmail"
                                                // value={momentData.momentDesc}
                                                id="requestFormEmail"
                                                autoComplete="false"
                                                placeholder="Your official email ID"
                                                helpMessage="We will reach out on this email ID in case of a question"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <Row>
                                        <FormGroup>

                                            <Col sm={11} >
                                                <Label for="example">Assign To Team</Label>
                                                <Select
                                                    name="assignTeam"
                                                    onChange={this.onChangeTeamList}
                                                    // value={this.state.assignTeam}
                                                    isMulti={true}
                                                    options={this.state.listTeam}
                                                    // className="react-select"
                                                    classNamePrefix="react-select"
                                                    placeholder={'Select a Team'}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="file">File</Label>
                                            <FormControls
                                                // tag={CustomInput}
                                                name="file"
                                                id="file"
                                                type="file"
                                                onChange={this.onChangeHandler}
                                                ref={this.fileInput}
                                                // validate={{ custom: this.validateFile }}
                                                invalid={invalidFile}
                                                helpMessage="(only .doc, .xls, .csv, pdf - Max 5 MB)"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Col sm={5}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit">
                                                {this.props.bName == 'Update Request' && (
                                                    <React.Fragment>Update Request</React.Fragment>
                                                )}
                                                {this.props.bName != 'Update Request' && (
                                                    <React.Fragment>Send Request</React.Fragment>
                                                )}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={'12 text-center'}>
                                            <b>It takes 24 to 36 working hours to turn around requests for new form creation</b>
                                        </Col>
                                    </FormGroup>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};
export default connect(mapStateToProps)(AddFeedback);