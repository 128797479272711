import React, { useState, useCallback, useMemo, useEffect } from "react";
import { spiltStringOnCommas } from "../helpers/common";

import InputBadgeLayout from "./InputBadgeLayout";
import "../assets/css/inputBadgeLayout.css";

import { Input } from "reactstrap";

const TextInputBadge = ({
  maxBadges = 1000,
  name,
  autoComplete = "off",
  placeholder = "",
  boxheight = "145px",
  countBadges,
  values = "",
  handleChange,
  invalid = false
}) => {
  const [badges, setBadges] = useState([]);
  const [badgeText, setBadgeText] = useState("");
  const [isContainDuplicate,setContainDuplicate]=useState(false)

  const updateBadgeCount = () => {
    if (countBadges && typeof countBadges === "function") {
      countBadges(badges.length);
    }
    if (handleChange && typeof handleChange === "function") {
      handleChange(name)(badges.join(","));
    }
  };

   // Return an array of strings
   const splitStringToKeyword = (str) => {

     return Array.from(new Set(str.split(",")
       .filter(item => item.trim() !== "")
       .map(item => item.trim())));

   }

  function findDuplicateKeywords(keywordsArray) {

    if (badges.length === 0) {
      return { isContainDuplicate: false, commonElements: [] }
    }
    let badgesObj = {}
    for (let i = 0; i < badges.length; i++) {
      let badge = badges[i]
      badgesObj[badge.toLowerCase()] = 1
    }

    let commonElements = keywordsArray.filter(keyword => {
      if (badgesObj[keyword.toLowerCase()]) {
        return true
      }
      return false
    });
    return { isContainDuplicate: !!commonElements.length, commonElements }
  }

  function findUniqueKeywordsFromString(keywords) {
    keywords = keywords.split(',')
    const uniqueKeywords = new Set();
    const lowercaseKeywords = new Set();
    keywords.forEach(keyword => {
      const lowercaseKeyword = keyword.trim().toLowerCase();
      if (lowercaseKeyword && !lowercaseKeywords.has(lowercaseKeyword)) {
        lowercaseKeywords.add(lowercaseKeyword);
        uniqueKeywords.add(keyword.trim());
      }
    });
    return Array.from(uniqueKeywords);
  }

  function findDifferentKeywordsFromArray(keywordsArray, commonElements) {
    let commonElemObj = {}
    for (let i = 0; i < commonElements.length; i++) {
      commonElemObj[commonElements[i]] = 1
    }

    let uniqueKeywords = keywordsArray.filter(keyword => {
      if (!commonElemObj[keyword]) {
        return true
      }
      return false
    })
    return uniqueKeywords;
  }

  const onHitEnter = useCallback((keywordArray, commonKeywords = '') => {
    if (
      badges.length + 1 <= maxBadges &&
      badges.length > -1 &&
      keywordArray.length > 0
    ) {
      // const keywordArray = splitStringToKeyword(text);
      setBadges((prevBadges) => [...keywordArray, ...prevBadges]);
      setBadgeText(commonKeywords);
    }
  }, [badges, badgeText, maxBadges, setBadgeText, setBadges, splitStringToKeyword]);

  const removeBadge = useCallback(
    (badgeIndex) => {
      setBadges((prevBadges) =>
        prevBadges.filter((_, index) => index !== badgeIndex)
      );
    },
    [setBadges]
  );

  const editBadge = useCallback(
    (badgeIndex) => {
      setContainDuplicate(false)
      // if already having text then create a new badge
      let keywordsArray = findUniqueKeywordsFromString(badgeText)
      let { isContainDuplicate, commonElements } = findDuplicateKeywords(keywordsArray)
      let differentKeywords = findDifferentKeywordsFromArray(keywordsArray, commonElements)

      if (!isContainDuplicate) {
        if (badgeText.length !== 0) {
          onHitEnter(keywordsArray);
        }
        // copy badge text and set it
        setBadgeText(badges[badgeIndex]);
        // remove this badge
        removeBadge(badgeIndex + keywordsArray.length);
      }
      else {
        onHitEnter(differentKeywords);
        setBadgeText(badges[badgeIndex]);
        removeBadge(badgeIndex + differentKeywords.length);
      }
    },
    [badges, removeBadge, badgeText]
  );

  const badgeLayouts = useMemo(
    () =>
      badges.map((value, index) => (
        <InputBadgeLayout
          key={index}
          textValue={value}
          index={index}
          removeBadge={() => removeBadge(index)}
          editBadge={() => editBadge(index)}
        />
      )),
    [badges, removeBadge, editBadge]
  );

  const addInitialBadges = () => {
    const initialBadgesArray = spiltStringOnCommas(values);
    for (const item of initialBadgesArray) {
      if (item.length !== 0) {
        setBadges((prevBadges) => [...prevBadges, item]);
      }
    }
  };

  useEffect(() => {
    updateBadgeCount();
  }, [badges]);

  useEffect(() => {
    if (values.length !== 0) {
      addInitialBadges();
    }
  }, []);

  return (
    <>
      <div
        className="badge-input-box"
        style={{
          gap: "10px",
          height: `${boxheight}`,
          overflow: "auto",
          borderColor: `${(invalid || isContainDuplicate) ? "red" : "revert-layer"}`
        }}
      >
        <Input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();

              let keywordsArray = findUniqueKeywordsFromString(badgeText)
              let { isContainDuplicate, commonElements } = findDuplicateKeywords(keywordsArray)
              let differentKeywords = findDifferentKeywordsFromArray(keywordsArray, commonElements)

              if (!isContainDuplicate) {
                setContainDuplicate(false)
                onHitEnter(keywordsArray);
              }
              else {
                onHitEnter(differentKeywords, commonElements.join(','));
                setContainDuplicate(true)
              }
            }
          }}
          onChange={(e) => setBadgeText(e.target.value)}
          value={badgeText}
          type="text"
          className="input-for-badge"
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          invalid={invalid || isContainDuplicate}
        />

        <ul className="badge-list">{badgeLayouts}</ul>
      </div>
      {isContainDuplicate && <div className="text-danger fs-12 mt-2">The keyword(s) already exist(s) in the keyword cloud</div>}
    </>
  );
};

export default TextInputBadge;
