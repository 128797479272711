import moment from 'moment';

// Calculating the to & From date initially
const dateTo = moment(new Date()).endOf('day').format('MM/DD/YYYY');
const dateFrom = moment(new Date()).subtract(7, 'days').startOf('day').format('MM/DD/YYYY');

export const DATE_RANGE = {
    from: dateFrom,
    to: dateTo,
};

const pastDateTo = moment(dateFrom).subtract(1, 'days').endOf('day').format('MM/DD/YYYY');
const pastDateFrom = moment(dateFrom).subtract(8, 'days').startOf('day').format('MM/DD/YYYY');

export const PAST_DATE_RANGE = {
    from: pastDateFrom,
    to: pastDateTo,
};

export const MONTH = {
    month: moment(new Date()).endOf('day').format('M'),
    year: moment(new Date()).endOf('day').format('Y')
}

export const DEFAULT_COMMUNICATION_TYPE = 'call'