// /* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup'
function YupValidationItem(validationVal, validationInfo) {
    switch (validationInfo.type) {
        case "required":
            return validationVal.required(validationInfo.message);
        case "max":
            return validationVal.max(validationInfo.max, validationInfo.message);
        case "min":
            return validationVal.min(validationInfo.min, validationInfo.message);
        case "email":
            return validationVal.email(validationInfo.message);
        case "city":
            return validationVal.test('city', validationInfo.message,
                (value, context) => checkCity(value, context),
            );
        default:
            break;
    }
}
function checkCity(value, context) {
    return value === 'abu';
}
function YupValidationField(validationVal, validationData) {
    validationData.validations.forEach(validationInfo => {
        validationVal = YupValidationItem(validationVal, validationInfo);

    });
    return validationVal;
}
function YupValidation(data) {
    const value = {}

    data.forEach(validationData => {
        var validationVal = Yup.string().trim();
        validationVal = YupValidationField(validationVal, validationData);
        value[validationData.name] = validationVal;
    });

    return value;
}

function getFormValidation(data) {
    const value = YupValidation(data)
    return Yup.object().shape(value);
}

export { YupValidation, getFormValidation };