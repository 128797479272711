import { FIRE_TOAST, TOGGLE_TOAST } from "./constant";

const INIT_STATE = {
    isOpen: false,
    children: null,
    autoClose: false,
    timer: 5000,
    success: true,
};

const CustomToastReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FIRE_TOAST:
            return { ...state, ...action.payload };

        case TOGGLE_TOAST:
            return { ...state, ...action.payload };

        default:
            return { ...state };
    }
};

export default CustomToastReducer;
