import React, { Component } from 'react';
import { Row, Col, Alert, Button, Card, CardBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import FormFeedback from '../../../components/FormControls/FormFeedback';
import Label from '../../../components/FormControls/Label';
import FormGroup from '../../../components/FormControls/FormGroup';
import CustomForm from '../../../components/FormControls/CustomForm';
import { getFormValidation } from '../../../components/FormControls/Validation';
import { servicePost } from './../../../helpers/api';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralFileUploader from '././../../../components/GeneralFileUploader';
import Select from 'react-select';
import '../../settings/truncte.css';

class AddClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccess: false,
            message: '',
            error: false,
            errorMessage: '',
            errorColor: '',
            listTeam: '',
            selectedTeam: '',
            assignTeam: '',
            emptyTeam: false,
            companyLogo: '',
            // tableRecords: this.props.tableRow
            isProcessing: false,
            isEmptyLogo: false,
            isAssign: '',
            agentStatus: 0,
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            // 'Content-Type':"multipart/form-data",
            Authorization: 'JWT ' + this.props.user.token,
        };

        if (this.props.data.team.length > 0) {
            this.state.assignTeam = this.props.data.team.map((_team) => {
                return {
                    label: _team.teamName,
                    value: _team._id,
                };
            });
            this.state.selectedTeam = this.props.data.team.map((_team) => {
                return {
                    label: _team.teamName,
                    value: _team._id,
                };
            });
        }
    }

    removePopUp = (seconds) => {
        setTimeout(() => {
            this.props.toggle();
            this.props.view();
        }, seconds);
    };

    componentDidMount = () => {
        this.getAllTeam();
    };

    /**GET TEAM LIST */
    getAllTeam = async () => {
        try {
            let listTeamOpt = [];
            servicePost('team/getTeams', {}, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        for (var l = 0; l < res.data.length; l++) {
                            listTeamOpt.push({ label: res.data[l].teamName, value: res.data[l]._id });
                        }
                    }
                    this.setState({
                        listTeam: listTeamOpt,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the submit
     */
    handleSubmit = async (values) => {
        // this.props.toggle();

        // if (this.state.companyLogo === '') {
        //     this.setState({
        //         isEmptyLogo: true,
        //     });
        //     return;
        // }
        if (!this.state.isValidEmail) {
            if (this.props.isAssign) {
                if (this.state.assignTeam == '') {
                    this.setState({
                        emptyTeam: true,
                    });
                    return false;
                }
            }

            const { submitType } = values;
            if (submitType == '0') {
                this.props.toggle();
                values.clientEmail = values.clientEmail.toLowerCase();
                const data = {
                    ...values,
                    selectTeam: this.state.assignTeam,
                    companyLogo: this.state.companyLogo,
                    clientPassword: '1234567890',
                };

                let formData = new FormData();
                let keys = Object.keys(data);
                for (let index = 0; index < keys.length; index++) {
                    formData.append(
                        [keys[index]],
                        typeof data[[keys[index]]] === 'object' && keys[index] !== 'companyLogo'
                            ? JSON.stringify(data[[keys[index]]])
                            : data[[keys[index]]]
                    );
                }
                try {
                    const res = await servicePost('client/addClient', formData, this.headers);
                    toast(res.message === 'SUCCESS' ? 'Client created successfully' : res.error, {
                        bodyClassName: res.message === 'SUCCESS' ? 'success-toast' : 'error-toast',
                        pauseOnHover: false,
                    });
                    this.props.view();
                } catch (err) {
                    toast('Error creating client', { bodyClassName: 'error-toast' });
                }
            } else if (submitType == '1' || (values.agentEmail && values.agentName)) {
                this.props.toggle();
                const { assignTeam } = this.state;
                let teams = [];
                if (assignTeam) {
                    assignTeam.map((o) => {
                        teams.push(o.value);
                    });
                }
                let data = {
                    clientName: values.clientName !== undefined ? values.clientName : '',
                    clientEmail: values.clientEmail !== undefined ? values.clientEmail.toLowerCase() : '',
                    companyName: values.companyName !== undefined ? values.companyName : '',
                    companyLogo: this.state.companyLogo,
                    addedBy: values.addedBy,
                    clientId: values.agentID,
                    selectTeam: teams,
                };
                let formData = new FormData();
                let keys = Object.keys(data);
                for (let index = 0; index < keys.length; index++) {
                    formData.append(
                        [keys[index]],
                        typeof data[[keys[index]]] === 'object' && keys[index] !== 'companyLogo'
                            ? JSON.stringify(data[[keys[index]]])
                            : data[[keys[index]]]
                    );
                }
                try {
                    this.headers['Content-Type']='multipart/from-data'
                    const res = await servicePost('client/updateClient', formData, this.headers);
                    toast('Client Updated successfully', { bodyClassName: 'success-toast', pauseOnHover: false });
                    this.props.view();
                    this.props.isAssignFun();
                } catch (err) {
                    toast('Error updating client', { bodyClassName: 'error-toast', pauseOnHover: false });
                    console.log(err);
                }
            } else {
                this.setState({
                    emptyTeam: true,
                });
            }
        }
    };

    onChangeTeamList = async (selectedValue) => {
        this.setState({
            assignTeam: selectedValue,
            emptyTeam: false,
            isSuccess: false,
        });
    };

    checkExisitingEmail = (e) => {
        let data = {
            agentEmail: e.target.value,
        };
        servicePost('client/checkExisitingEmail', data, this.headers).then((res) => {
            if (res.status === 1) {
                this.setState({
                    agentStatus: res.status,
                    errorMessage: res.data,
                    isValidEmail: true,
                });
            } else {
                this.setState({
                    agentStatus: res.status,
                    errorMessage: res.error,
                    isValidEmail: false,
                });
            }
        });
    };

    render() {
        let agentData = {
            companyName: '',
            companyLogo: [],
            email: '',
            name: '',
            team: '',
            submitType: '0',
            agentID: '',
        };

        if (this.props.data) {
            let companyLogo = [];
            if (this.props.data.companyLogo) {
                companyLogo.push({
                    preview: this.props.data.companyLogo,
                });
            }
            agentData = {
                email: this.props.data.email,
                name: this.props.data.name,
                companyLogo,
                companyName: this.props.data.companyName,
                // team: { label:  this.props.data.team[0].teamName,  value:  this.props.data.team[0]._id,},
                submitType: this.props.data._id ? '1' : '0',
                agentID: this.props.data._id,
            };
        }

        const { isEmptyLogo } = this.state;
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                clientName: agentData.name,
                                clientEmail: agentData.email,
                                companyName: agentData.companyName,
                                updateType: "1",
                                submitType: agentData.submitType,
                                agentID: agentData.agentID,
                                addedBy: this.props.user.id
                            }}
                            validationSchema={getFormValidation([
                                { "name": "clientName", "validations": [{ "type": "required", "message": "This field is invalid" }] },
                                { "name": "clientEmail", "validations": [{ "type": "required", "message": "This field is invalid" }] },
                                { "name": "companyName", "validations": [{ "type": "required", "message": "This field is invalid" }] }
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    {(this.props.typeForm == 'edit' || this.props.typeForm == 'editEmail') && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="clientName">Full Name *</Label>
                                                    <FormControls
                                                        type="text"
                                                        name="clientName"
                                                        // value={agentData.name}
                                                        id="clientName"
                                                        autoComplete="false"
                                                        placeholder="Enter Full Name"
                                                    />
                                                    <FormFeedback type="invalid" >{errors.clientName}</FormFeedback>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="clientEmail">
                                                        Email&nbsp; (An email invite will go to this ID) *
                                                    </Label>

                                                    {!(this.props.bName == 'Update Client') ? (
                                                        <FormControls
                                                            type="email"
                                                            // value={agentData.email}
                                                            name="clientEmail"
                                                            onChange={this.checkExisitingEmail}
                                                            id="clientEmail"
                                                            autoComplete="false"
                                                            placeholder="Enter Email ID"
                                                        />
                                                    )

                                                        : (
                                                            <FormControls
                                                                type="email"
                                                                // value={agentData.email}
                                                                name="clientEmail"
                                                                id="clientEmail"
                                                                onChange={this.checkExisitingEmail}
                                                                autoComplete="false"
                                                                placeholder="Enter Email ID"
                                                                style={{ backgroundColor: '#dddddd' }}
                                                                disabled
                                                            />
                                                        )}


                                                    {this.state.agentStatus === 1 && (
                                                        <React.Fragment>
                                                            <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                                {' '}
                                                                {this.state.errorMessage}{' '}
                                                            </span>
                                                        </React.Fragment>
                                                    )}
                                                    <FormFeedback type="invalid" >{errors.clientEmail}</FormFeedback>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label for="companyName">Company Name *</Label>
                                                    <FormControls
                                                        type="text"
                                                        // value={agentData.companyName}
                                                        name="companyName"
                                                        id="companyName"
                                                        autoComplete="false"
                                                        placeholder="Enter Company Name"
                                                    />
                                                    <FormFeedback type="invalid" >{errors.companyName}</FormFeedback>
                                                </Col>
                                            </FormGroup>

                                            {/* <AvGroup row> */}
                                            <div class={isEmptyLogo ? 'text-danger row form-group' : ' row form-group'}>
                                                <Col sm={12}>
                                                    <label for="">Company </label>
                                                    <GeneralFileUploader
                                                        accept="image/*"
                                                        onFileUpload={(files) => {
                                                            this.setState({
                                                                companyLogo: files.length > 0 ? files[0] : null,
                                                                isEmptyLogo: false,
                                                            });
                                                        }}
                                                        files={agentData.companyLogo}
                                                        showPreview
                                                        title={'Drag & drop image file'}
                                                        subHead={'Only JPG & PNG supported with max. file size 5MB'}
                                                    />
                                                </Col>
                                            </div>
                                            {/* </AvGroup> */}

                                            <Row>
                                                <Col sm={12}>
                                                    <Label for="example">Assign to Team</Label>
                                                    <Select
                                                        onChange={this.onChangeTeamList}
                                                        value={this.state.assignTeam}
                                                        isMulti={true}
                                                        options={this.state.listTeam}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder={'Select a Team'}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                        </React.Fragment>
                                    )}

                                    {this.props.typeForm == 'team' && (
                                        <React.Fragment>
                                            <FormGroup row>
                                                <Col sm={12}>
                                                    <Label
                                                        for="example"
                                                        className={this.state.isSuccess == true ? 'text-danger' : ''}
                                                        style={{ color: !this.state.emptyTeam ? '' : '#ff5c75' }}>
                                                        Assign to Team *
                                                    </Label>

                                                    <Select
                                                        onChange={this.onChangeTeamList}
                                                        value={this.state.assignTeam}
                                                        isMulti={true}
                                                        options={this.state.listTeam}
                                                        className="react-select"
                                                        classNamePrefix={
                                                            !this.state.emptyTeam ? 'react-select' : 'text-danger react-select'
                                                        }
                                                        placeholder={'Select a Team'}
                                                        id={this.state.isSuccess == true ? 'reacttr' : ''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormControls type="hidden" name="updateType" />
                                        </React.Fragment>
                                    )}

                                    <FormGroup className='row'>
                                        <Col sm={6}>
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <FormControls type="hidden" name="submitType" />
                                            <FormControls type="hidden" name="agentID" />
                                            <FormControls type="hidden" name="addedBy" />
                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={this.checkIsAssign}
                                                disabled={this.state.isProcessing}>
                                                {this.props.bName == 'Create Client' && (
                                                    <React.Fragment>Create Client</React.Fragment>
                                                )}
                                                {this.props.bName == 'Update Client' && (
                                                    <React.Fragment>Update Client</React.Fragment>
                                                )}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row style={{ textAlign: 'center' }}>
                                                <Col sm={12}>
                                                    {this.state.message}
                                                    {this.state.error && (
                                                        <Alert
                                                            color={this.state.errorColor}
                                                            isOpen={this.state.error ? true : false}>
                                                            <div>{this.state.errorMessage}</div>
                                                        </Alert>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};

export default connect(mapStateToProps)(AddClient);
