import React, { useState } from "react";
import "../assets/css/inputBadgeLayout.css";
import { X, Edit3 } from "react-feather";

const InputBadgeLayout = ({ textValue, index, removeBadge, editBadge }) => {
  const [isHover, setIsHover] = useState(false);

  const handleDoubleClick = (event) => {
    // if double click
    if (event.detail === 2) {
      editBadge(index);
    }
  };

  return (
    <>
      <li
        className="input-badge"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          color: `${isHover ? "black" : "black"}`,
          backgroundColor: `${isHover ? "#f7f7ff" : "#f7f7ff"}`,
          border: `1px solid ${isHover ? "#f7f7ff" : "#f7f7ff"}`,
          cursor: "pointer"
        }}
        onClick={(e) => handleDoubleClick(e)}
      >
        <div
          className="badge-top-container"
          style={{ display: `${isHover ? "flex" : "none"}` }}
        >
          <div className="badge-top-inner-left">
            <span className="badge-top-icon" style={{ display: "none" }}>
              M
            </span>
          </div>
          <div className="badge-top-inner-right">
            <span title="Edit">
              <Edit3
                className="badge-top-icon"
                onClick={() => editBadge(index)}
              />
            </span>
            <span title="Remove">
              <X
                onClick={() => removeBadge(index)}
                className="badge-top-icon"
                style={{ color: "red" }}
              />
            </span>
          </div>
        </div>
        <div
          className="badge-bottom-container"
          style={{ top: `${isHover ? "-13px" : "2px"}` }}
        >
          <span>{textValue}</span>
        </div>
      </li>
    </>
  );
};

export default InputBadgeLayout;
