import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
// import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
import Form from '../../../components/FormControls/Form';
import FormControls from '../../../components/FormControls/FormControls';
import CustomForm from '../../../components/FormControls/CustomForm';
import Label from '../../../components/FormControls/Label';
import FormGroup from '../../../components/FormControls/FormGroup';
import { getFormValidation } from '../../../components/FormControls/Validation';
import { servicePost } from './../../../helpers/api';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';

class AddTeam extends Component {
    constructor(props) {
        super(props);
        let assignedAgent = [];
        if (this.props.data.assignedAgent) {
            let values = this.props.data.teamData;
            for (let i = 0; i < values.length; i++) {
                const element = values[i];
                assignedAgent.push({ value: element._id, label: element.name });
            }
        }

        this.state = {
            isSuccess: false,
            message: '',
            agentListOpt: '',
            teamStatus: '',
            selectedAgentList: assignedAgent,
            selecctedPhoneNumbers: this.props.data.phoneNumberAssigned
                ? this.props.data.phoneNumberAssigned.join('\n')
                : '',
            teamError: 0,
            errorMesg: '',
            isProcessing: false,
            // tableRecords: this.props.tableRow
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        // HEADERS
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + this.props.user.token,
        };
    }

    /** GET AGENTS LIST */
    agentList = async () => {
        try {
            let agentListOpt = [];
            await servicePost('agent/getAgentForTeam', {}, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        for (var l = 0; l < res.data.length; l++) {
                            agentListOpt.push({ label: res.data[l].name, value: res.data[l]._id });
                        }
                    }

                    this.setState({
                        agentListOpt: agentListOpt,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    /**
     * Handles the submit
     */
    handleSubmit = (values) => {
        this.props.toggle();
        try {
            this.setState({
                isProcessing: true,
            });

            let successMessage = '';
            if (values.teamId != '') {
                successMessage = 'Updating Team';
            } else {
                successMessage = 'Creating Team';
            }

            let data = {
                teamName: this.capitalize(values.teamName),
                teamLead: this.capitalize(values.teamLead),
                supervisorEmail: values.supervisorEmail.toLowerCase(),
                selectAgent: this.state.selectedAgentList,
                teamId: values.teamId,
            };

            if (this.props.isAgentCommon) {
                data.phoneNumberAssigned = values.phoneNumberAssigned.split(/[,^\r\n]+/g).map((number) => {
                    return number.trim();
                });
            }

            this.setState({
                teamError: 0,
            });

            servicePost('team/addTeam', data, this.headers)
                .then((res) => {
                    if (res.status == 1) {
                        toast(successMessage, { pauseOnHover: false, bodyClassName: 'success-toast' });
                        // this.props.toggle();
                        this.props.view();
                    } else if (res.status == 2) {
                        this.setState({
                            teamError: 2,
                            errorMesg: res.error,
                        });
                        // toast(res.error,{pauseOnHover: false,bodyClassName:'error-toast'});
                    } else if (res.status == 0) {
                        this.setState({
                            teamError: 1,
                        });
                        // toast(res.error,{pauseOnHover: false,bodyClassName:'error-toast'});
                    } else {
                        toast('Something went wrong', {
                            pauseOnHover: false,
                            bodyClassName: 'error-toast',
                            hideProgressBar: true,
                        });
                    }
                    this.setState({
                        isProcessing: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    toast('Something went wrong', {
                        pauseOnHover: false,
                        bodyClassName: 'error-toast',
                        hideProgressBar: true,
                    });
                });
        } catch (err) {
            console.log(err);
            toast('Something went wrong', { pauseOnHover: false, bodyClassName: 'error-toast', hideProgressBar: true });
        }
    };

    componentDidMount = async () => {
        this.agentList();
    };

    onChangeAgentList = async (selectedAgent) => {
        // console.log('selectedList',selectedAgent);
        this.setState({
            selectedAgentList: selectedAgent,
        });
    };

    handleChange = (e) => {
        let data = {
            teamName: e.target.value,
        };
        servicePost('team/checkTeam', data, this.headers).then((res) => {
            if (res.status === 1) {
                this.setState({
                    teamStatus: res.status,
                    errorMesg: res.error,
                    isProcessing: true,
                });
            } else {
                this.setState({
                    teamStatus: res.status,
                    errorMesg: res.error,
                    isProcessing: false,
                });
            }
        });
    };

    render() {
        let teamData = { teamName: '', teamLead: '', agents: '', teamId: '' };
        if (this.props.data) {
            teamData = {
                teamName: this.props.data.teamName,
                teamLead: this.props.data.teamLead,
                supervisorEmail: this.props.data.supervisorEmail,
                agents: this.props.data.assignedAgent,
                teamId: this.props.data._id,
            };
            if (!teamData.teamId)
                teamData.teamId = ''
        }
        return (
            <React.Fragment>
                <Card className="addModal_card">
                    <CardBody>
                        <Form
                            initialValues={{
                                teamName: teamData.teamName,
                                teamLead: teamData.teamLead,
                                supervisorEmail: teamData.supervisorEmail,
                                phoneNumberAssigned: this.state.selecctedPhoneNumbers,
                                teamId: teamData.teamId
                            }}
                            validationSchema={getFormValidation([
                                { "name": "teamName", "validations": [{ "type": "required", "message": "This field is required" }] },
                                { "name": "teamLead", "validations": [{ "type": "required", "message": "This field is required" }] },
                                { "name": "supervisorEmail", "validations": [{ "type": "required", "message": "This field is required" }] },
                                // { "name": "phoneNumberAssigned", "validations": [{ "type": "required", "message": "This field is required" }] },
                            ])}
                            onSubmit={this.handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <CustomForm onSubmit={handleSubmit}>
                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Label for="example">
                                                Team Name <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                type="text"
                                                name="teamName"
                                                id="teamName"
                                                onChange={this.handleChange}
                                                // value={teamData.teamName}
                                                autoComplete="false"
                                                placeholder="Enter Team Name"
                                            // required
                                            />
                                            {this.state.teamStatus == 1 && (
                                                <React.Fragment>
                                                    <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                        {' '}
                                                        {this.state.errorMesg}{' '}
                                                    </span>
                                                </React.Fragment>
                                            )}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Label for="example">
                                                Team Supervisor <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                type="text"
                                                name="teamLead"
                                                id="teamLead"
                                                // value={teamData.teamLead}
                                                autoComplete="false"
                                                placeholder="Enter Team Lead"
                                            // required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Label for="example">
                                                Supervisor Email <span style={{ color: '#5369f8' }}>*</span>
                                            </Label>
                                            <FormControls
                                                type="email"
                                                name="supervisorEmail"
                                                id="supervisorEmail"
                                                // value={teamData.supervisorEmail}
                                                autoComplete="false"
                                                placeholder="Enter Supervisor Email"
                                            // required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <div class="row form-group">
                                        <Col sm={12}>
                                            <label for="example">Assign Agents</label>
                                            <Select
                                                onChange={this.onChangeAgentList}
                                                value={this.state.selectedAgentList}
                                                isMulti={true}
                                                options={this.state.agentListOpt}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                placeholder={'Assign Agents'}
                                            />

                                            {this.state.teamError == 2 && (
                                                <React.Fragment>
                                                    <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                        {' '}
                                                        {this.state.errorMesg}{' '}
                                                    </span>
                                                </React.Fragment>
                                            )}
                                        </Col>
                                    </div>
                                    {this.props.isAgentCommon && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <label for="phoneNumberAssigned">Assign Phone numbers</label>

                                                <FormControls
                                                    type="textarea"
                                                    id="phoneNumberAssigned"
                                                    name="phoneNumberAssigned"
                                                    // value={this.state.selecctedPhoneNumbers}
                                                    autoComplete="false"
                                                    placeholder="Add one phone-number sentence per line"
                                                // required
                                                />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    <br />
                                    <Row>
                                        <Col sm={6}>
                                            <FormControls
                                                type="hidden"
                                                name="teamId"
                                                id="teamId"
                                                autoComplete="false"
                                            // value={teamData.teamId}
                                            />
                                            <Button
                                                onClick={this.props.toggle}
                                                style={{ float: 'right' }}
                                                className="btn btn-primary cancel-btn"
                                                type="button">
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={6}>
                                            <Button color="primary" type="submit" disabled={this.state.isProcessing}>
                                                {`${this.props.bName ? this.props.bName : 'Create Team'}`}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {this.state.isSuccess && (
                                        <React.Fragment>
                                            <FormGroup row>{this.state.message}</FormGroup>
                                        </React.Fragment>
                                    )}
                                </CustomForm>)}
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    return state.Auth;
};
export default connect(mapStateToProps)(AddTeam);
